export const styles = {
  servicesBox: {
    padding: {
      lg: "80px 0 40px",
      xs: "20px 0",
    },
    position: "relative",
  },
  servicesContainer: {
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  servicesTitle: {
    fontSize: {
      lg: "40px",
      xs: "28px",
    },
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    textAlign: "center",
    paddingBottom: "14px",
  },
  servicesSubtitle: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#8989A3",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: "20px",
  },
  servicesContentBox: {
    flexDirection: "column",
    display: "flex",
    padding: {
      lg: "30px 0 0",
      xs: "10px 0 0",
    },
  },
  stepsBox: {
    display: "flex",
    padding: "0 0 50px 0",
  },
  stepBox: {
    justifyContent: "center",
    width: "25%",
    display: "flex",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      width: "50%",
      position: "absolute",
      left: "50%",
      border: "2px dashed #0A003259",
      top: "50%",
    },
  },
  stepBoxFull: {
    justifyContent: "center",
    width: "25%",
    display: "flex",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      width: "100%",
      position: "absolute",
      border: "2px dashed #0A003259",
      top: "50%",
    },
  },
  stepBoxRight: {
    justifyContent: "center",
    width: "25%",
    display: "flex",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      width: "50%",
      position: "absolute",
      right: "50%",
      border: "2px dashed #0A003259",
      top: "50%",
    },
  },
  stepCircle: {
    border: "2px solid #0D0639",
    background: "#fff",
    padding: "10px",
    borderRadius: "50%",
    height: "35px",
    zIndex: 9,
    position: "relative",
    listStyle: "none",
    width: "35px",
  },
  stepNumber: {
    background: "#06038D",
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontSize: "18px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepArrow: {
    width: "0",
    height: "0",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: "14px solid #06038D",
    position: "absolute",
    bottom: "-30px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  cardGridItem: {
    width: "100%",
  },
  offersCardBox: {
    border: "1.86px solid #ACA3D44D",
    borderRadius: "7px",
    padding: "20px 20px",
  },
  cardImageBox: {
    textAlign: "center",
    marginBottom: "10px",
  },
  offersCardTitle: {
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    color: "#0D0639",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    textAlign: "center",
    paddingBottom: "14px",
  },
  offersCardDescription: {
    fontSize: "14px",
    color: "#0D0639",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "300",
    textAlign: "center",
  },
  missionBox: {
    background: "#F3F4FC",
    boxShadow: "0px 2px 4px 0px #00000014",
    margin: {
      lg: "80px 0 40px",
      xs: "30px 0 20px",
    },
    borderRadius: "24px",
    zIndex: "9",
    position: "relative",
    padding: {
      lg: "40px 20px 40px 60px",
      xs: "16px",
    },
    overflow: "hidden",
  },
  missionGridContainer: {
    flexDirection: {
      md: "row",
      xs: "column-reverse",
    },
  },
  missionTextGridItem: {
    display: "flex",
    textAlign: "left",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  missionTitle: {
    fontSize: {
      lg: "40px",
      xs: "22px",
    },
    paddingTop: "14px",
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    paddingBottom: "20px",
  },
  missionDescription: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#64748B",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
    width: {
      lg: "70%",
      md: "90%",
      xs: "100%",
    },
  },
  learnMoreButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      sm: "13px 30px",
    },
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans-serif",
    boxShadow: "4px 8px 24px 0px #1CB46940",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "20px 0 0 0",
      sm: "20px 0 0 0",
      lg: "30px 0 0 0",
    },
  },
  missionImageGridItem: {
    position: "relative",
  },
  missionImage: {
    borderRadius: "20px",
    width: "100%",
    position: "relative",
    zIndex: "9",
  },
  missionImageLoadingBox: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "99",
  },
  missionVectorImage: {
    position: "absolute",
    right: "0",
    top: "-20px",
  },
  servicesVectorImage: {
    position: "absolute",
    bottom: "0",
    left: "-30px",
  },
};
