import { DayServiceTiming } from "./interfaces";

export const handleEncrypt = (value: string): string => {
  const encoded = btoa(value);
  return encoded;
};

export const handleDecrypt = (value: string): string => {
  const decoded = atob(value);
  return decoded;
};

export const formatPhoneNumber = (value: string): string => {
  const digits = value.replace(/\D/g, "");

  let formattedNumber = digits
    .slice(0, 6)
    .replace(/(\d{3})(?=\d)/g, "$1-")
    .trim();

  if (digits.length > 6) {
    formattedNumber += "-" + digits.slice(6, 10);
  }

  return `+1 ${formattedNumber.trim()}`;
};

export const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const sortDays = (serviceTiming: DayServiceTiming): DayServiceTiming => {
  const weekOrder: Array<keyof DayServiceTiming> = daysOfWeek;

  const sortedServiceTiming: DayServiceTiming = {};
  weekOrder.forEach((day) => {
    if (serviceTiming[day]) {
      sortedServiceTiming[day] = serviceTiming[day]!;
    }
  });

  return sortedServiceTiming;
};
