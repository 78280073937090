import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import { Box } from "@mui/material";
import { routePaths } from "../../../router/routePaths";
import { getServices } from "../../../network/services";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";
import { RootState } from "../../../redux/rootReducer";
import useDebounce from "../../../hooks/useDebounce";
import AddBusinessComp, {
  Image,
} from "../../../components/AddBusinessComp/AddBusinessComp";

const EditBusiness = () => {
  const [services, setServices] = useState<Image[]>([]);
  const [searchServicesText, setSearchServicesText] = useState<string>("");
  const debouncedSearchServicesText = useDebounce(searchServicesText, 500);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updatedSelectedServices = useSelector(
    (state: RootState) => state.user.userData?.serviceIds
  );

  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  useEffect(() => {
    setSelectedServices(updatedSelectedServices || []);
  }, [updatedSelectedServices]);

  useEffect(() => {
    fetchServicesList();
  }, [debouncedSearchServicesText]);

  const fetchServicesList = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getServices(searchServicesText);
      setServices(res?.data);
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const addBusinessHandler = async () => {
    try {
      const payload = { serviceIds: selectedServices };
      dispatch(setUserData(payload));
      navigate(`${routePaths.editServiceCategory}?previous=true`);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Box className="addBusinessForm">
      <ProfileWrapper
        title="Edit Business"
        btnDisabled={selectedServices?.length === 0}
        onSubmit={addBusinessHandler}
        hide={true}
        buttonText="Next"
        backHandler={() => {
          dispatch(setUserData({ backClicked: true }));
          navigate(routePaths.settings);
        }}
      >
        <AddBusinessComp
          searchServicesText={searchServicesText}
          selectedServices={selectedServices}
          services={services}
          setSelectedServices={setSelectedServices}
          setSearchServicesText={setSearchServicesText}
        />
      </ProfileWrapper>
    </Box>
  );
};

export default EditBusiness;
