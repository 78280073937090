import { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/ConsumerHeader/Header";
import GetHelp from "../../../module/LandingPage/ServiceProvider/GetHelp/GetHelp";
import Banner from "./Banner/Banner";
import Search from "./Search/Search";

const ExpertServiceProvider = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const role = "consumer";
  return (
    <>
      <Header />
      <Banner />
      <Search />
      <GetHelp />
      <Footer role={role} />
    </>
  );
};

export default ExpertServiceProvider;
