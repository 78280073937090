import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    background: "#F5F5FA",
    padding: {
      lg: "80px 0",
      xs: "20px 0 0",
    },
    position: "relative",
  },
  container: {
    maxWidth: "xl",
  },
  contentBox: {
    boxShadow: "0px 2px 0px 0px #0000000F",
    border: "1px solid #0000001A",
    borderRadius: "36px",
    position: "relative",
    background: "#fff",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    textAlign: "center",
    color: "#06038D",
    fontSize: {
      xl: "38px",
      lg: "38px",
      sm: "32px",
      xs: "24px",
    },
    padding: {
      lg: "60px 0 30px 0",
      sm: "60px 0 30px 0",
      xs: "55px 0 20px 0",
      "@media (orientation: landscape) and (max-width: 999px)": {
        padding: "50px 0 20px 0",
      },
    },
  },
  content: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "16px",
    color: "#0D0639B2",
    padding: {
      lg: "0 70px 60px 70px",
      xs: "0 20px 30px 20px",
    },
    lineHeight: {
      lg: "40px",
      xs: "35px",
    },
  },
  backButtonBox: {
    cursor: "pointer",
    position: "absolute",
    top: {
      lg: "40px",
      xs: "20px",
    },
    left: {
      lg: "40px",
      xs: "20px",
    },
  },
  backButtonImage: {
    width: {
      xs: "30px",
      lg: "auto",
    },
  },
};

export default styles;
