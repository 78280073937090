export const initialState = {
  fullName: "",
  phoneNumber: "",
  email: "",
  description: "",
  address: "",
  city: "",
  province: "",
  zipCode: "",
  moreZipCodes: "",
  disabled: true,
  disabled2: true,
  formCount: 1,
  isAddMoreZipCode: false,
  selectedFiles: [] as File[],
  error: null as string | null,
  errors: {
    phoneNumber: "",
    email: "",
    fullName: "",
    zipCode: "",
    description: "",
    address: "",
    city: "",
    province: "",
    moreZipCodes: "",
  },
};

export const getSx = (val: string | null) => {
  return {
    borderColor: val ? "red" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};
