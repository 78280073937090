import { UserData } from "../redux/slices/userSlice";

export const formatUserData = (data: Partial<UserData>) => ({
  profilePic:
    data.profileImage &&
    typeof data.profileImage === "object" &&
    "url" in data.profileImage
      ? (data.profileImage as { url: string }).url
      : undefined,
  fullName: data.fullName,
  phoneNumber: data.phoneNumber,
  email: data.email,
  serviceNames: Array.isArray(data.serviceDetails)
    ? (data.serviceDetails as { name: string }[])
        .map((service) => service.name)
        .join(", ")
    : "",
  categoryDetails: data.categoryDetails,
  subCategoryDetails: data.subCategoryDetails,
  serviceImages: data.businessImages,
  businessTitle: data.businessTitle,
  description: data.description,
  businessDocuments: data.businessDocuments,
  address: data.address,
  city: data.city,
  province: data.province,
  zipCode: data.zipCode,
  zipCodes: Array.isArray(data.zipCodes) ? data.zipCodes : undefined, // Convert to a comma-separated string
  serviceIds: data.serviceIds,
  categoryIds: data.categoryIds,
  subCategoryIds: data.subCategoryIds,
  isProfileCompleted:
    typeof data.isProfileCompletion === "boolean"
      ? data.isProfileCompletion
      : undefined,
  errors: {
    phoneNumber: "",
    email: "",
    fullName: "",
    zipCode: "",
    message: "",
    address: "",
    city: "",
    state: "",
    businessTitle: "",
  },
});
