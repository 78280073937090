import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import styles from "./style";
import popupCloseIcon from "../../../assets/Dashboard/popupCloseIcon.svg";
import callIcon from "../../../assets/Dashboard/callIcon.svg";
import copyIcon from "../../../assets/Dashboard/copyIcon.svg";
import mailIcon from "../../../assets/Dashboard/mailIcon.svg";
import calenderIcon from "../../../assets/Dashboard/calenderIcon.svg";
import { formatTimestamp } from "../../../utils/formatTimeStamp";

interface CategoryDetail {
  categoryName: string;
  displayImage: {
    url: string;
  };
}
interface Inquiry {
  fullName: string;
  updatedAt: string;
  phoneNumber: string;
  email: string;
  message: string;
  categoryDetails: CategoryDetail[];
}

interface InquiryDetailsModalProps {
  open: boolean;
  inquiry: Inquiry | null;
  onClose: () => void;
  handleCopy: (text: string) => void;
}

const InquiryDetailsModal = ({
  open,
  inquiry,
  onClose,
  handleCopy,
}: InquiryDetailsModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: {
            xs: "90%",
            sm: "70%",
            md: "680px",
          },
          borderRadius: "20px",
          maxWidth: "90%",
        },
        "& .MuiDialogContent-root": {
          padding: {
            sm: "20px 24px",
            xs: "15px",
          },
          "@media (orientation: landscape) and (max-width: 999px)": {
            padding: "20px 0",
          },
        },
      }}
    >
      <DialogTitle id="customized-dialog-title"></DialogTitle>
      <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton}>
        <img src={popupCloseIcon} alt="closeIcon" />
      </IconButton>
      <DialogContent sx={styles.dialogContent}>
        {inquiry && (
          <>
            <Box sx={styles.dialogContentBox}>
              <Box sx={styles.flexBetween}>
                <Typography variant="h3" sx={styles.title}>
                  {inquiry.fullName}
                </Typography>
                <Box sx={styles.flexCenter}>
                  <img src={calenderIcon} alt="Calender Icon" />
                  <Typography variant="h3" sx={styles.calendarText}>
                    {formatTimestamp(inquiry.updatedAt)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.contactBox}>
                <Box sx={styles.contactItem}>
                  <Box sx={styles.contactIcon}>
                    <img src={callIcon} alt="Call Icon" />
                  </Box>
                  <Typography variant="h3" sx={styles.contactText}>
                    {inquiry.phoneNumber}
                  </Typography>
                  <Box
                    sx={styles.copyIcon}
                    onClick={() => inquiry && handleCopy(inquiry.phoneNumber)}
                  >
                    <img src={copyIcon} alt="Copy Icon" />
                  </Box>
                </Box>
                <Box sx={styles.flexCenter}>
                  <Box sx={styles.emailIcon}>
                    <img src={mailIcon} alt="Mail Icon" />
                  </Box>
                  <Typography variant="h3" sx={styles.contactText}>
                    {inquiry.email}
                  </Typography>
                  <Box
                    sx={styles.copyIcon}
                    onClick={() => inquiry && handleCopy(inquiry.email)}
                  >
                    <img src={copyIcon} alt="Copy Icon" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.serviceCategoryBox}>
              <Box sx={styles.serviceCategoryHeader}>
                <Typography variant="h4" sx={styles.serviceCategoryTitle}>
                  Service Category
                </Typography>
              </Box>
              <Box sx={styles.serviceCategoryList}>
                {inquiry.categoryDetails?.map((category: CategoryDetail) => (
                  <Box
                    sx={styles.serviceCategoryItem}
                    key={category?.categoryName}
                  >
                    <Box sx={styles.serviceCategoryIcon}>
                      <img
                        src={category.displayImage.url}
                        width="100%"
                        alt="Pipe Installation"
                      />
                    </Box>
                    {category.categoryName}
                  </Box>
                ))}
              </Box>
              <Box>
                <Typography variant="h4" sx={styles.descriptionTitle}>
                  Description
                </Typography>
                <Typography sx={styles.modalDescriptionText}>
                  {inquiry.message}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InquiryDetailsModal;
