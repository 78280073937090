import { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/ConsumerHeader/Header";
import GetHelp from "../ServiceProvider/GetHelp/GetHelp";
import Content from "./Content";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const role = "consumer";
  return (
    <>
      <Header />
      <Content />
      <GetHelp />
      <Footer role={role} />
    </>
  );
};

export default PrivacyPolicy;
