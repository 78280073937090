import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import bannerImage from "../../assets/Images/bannerImage.png";
import borderImage from "../../assets/Images/borderImage.png";
import { useState } from "react";
import { routePaths } from "../../router/routePaths";
import styles from "./style";

const Banner = () => {
  const [loadingBannerImage, setLoadingBannerImage] = useState(true);
  const [loadingBorderImage, setLoadingBorderImage] = useState(true);

  return (
    <>
      <Box sx={styles.mainBox}>
        <Grid2 sx={styles.gridContainer} container>
          <Grid2 size={{ xs: 12, sm: 12, lg: 4 }}>
            <Box>
              <Typography variant="h3" sx={styles.title}>
                Sell Your Home
              </Typography>
              <Box sx={styles.borderImageBox}>
                <img
                  src={borderImage}
                  alt="Border"
                  className="vectorImage"
                  style={{ display: loadingBorderImage ? "none" : "block" }}
                  onLoad={() => setLoadingBorderImage(false)}
                />
                {loadingBorderImage && <CircularProgress size={40} />}
              </Box>
              <Typography variant="h3" sx={styles.forAsLowAs}>
                For as low as
              </Typography>
              <Typography variant="h3" sx={styles.percentage}>
                1.95 %
              </Typography>
              <Box sx={styles.borderImageBox}>
                <img
                  src={borderImage}
                  alt="Border"
                  className="vectorImage"
                  style={{ display: loadingBorderImage ? "none" : "block" }}
                  onLoad={() => setLoadingBorderImage(false)}
                />
                {loadingBorderImage && <CircularProgress size={40} />}
              </Box>
              <Typography variant="h3" sx={styles.description}>
                Your Ultimate Home Selling Hub: Connect With Experts, Get Top
                Offers, And Simplify The Sale.
              </Typography>
              <Box>
                <NavLink to={routePaths.tieredPlans}>
                  <Button sx={styles.contactButton}>Contact Us</Button>
                </NavLink>
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 12, lg: 8 }} sx={styles.imageGridItem}>
            <Box sx={styles.imageBox}>
              <img
                src={bannerImage}
                alt="banner"
                width="100%"
                style={{ display: loadingBannerImage ? "none" : "block" }}
                onLoad={() => setLoadingBannerImage(false)}
              />
              {loadingBannerImage && (
                <CircularProgress size={40} sx={styles.loadingIndicator} />
              )}
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default Banner;
