import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    background: "#F6F6FD",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    padding: {
      md: "0 0 20px 30px",
      xs: "0",
    },
    position: "relative",
  },
  gridContainer: {
    "@media (min-width: 1536px)": {
      maxWidth: "1536px",
      margin: "auto",
    },
    flexDirection: {
      md: "row",
      xs: "column-reverse",
    },
  },
  textGridItem: {
    display: "flex",
    alignItems: "center",
  },
  textBox: {
    padding: {
      xl: "0 20px 0 0",
      lg: "50px 30px 50px 20px",
      md: "0 20px 0 0",
      xs: "16px",
    },
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    paddingTop: {
      xl: "0",
      md: "20px",
      xs: "20px",
    },
    padding: {
      lg: "20px 0 30px 0",
      md: " 20px 0 20px 0",
      xs: "10px 0 20px 0",
    },
  },
  title: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#0D0639",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
  },
  titleUnderline: {
    width: "60px",
    marginLeft: "20px",
    height: "3px",
    background: "#1CB469",
  },
  mainHeading: {
    fontSize: {
      lg: "46px",
      xs: "32px",
    },
    color: "#0F172A",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    lineHeight: {
      lg: "60px",
      xs: "44px",
    },
  },
  subHeading: {
    fontSize: {
      lg: "24px",
      xs: "18px",
    },
    paddingTop: "20px",
    color: "#0D0639",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    lineHeight: {
      lg: "44px",
      xs: "28px",
    },
  },
  imageGridItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
  },
  imageBox: {
    display: "flex",
    zIndex: "9",
    height: "100%",
    marginTop: "-30px",
  },
  bannerImage: {
    borderRadius: "24px 0 0 24px",
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  vectorImage: {
    position: "absolute",
    top: "-40px",
    left: "-120px",
  },
};

export default styles;
