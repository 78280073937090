import {
  Box,
  Container,
  Grid2,
  Typography,
  ListItem,
  CircularProgress,
  List,
  Button,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import styles from "../style";
import QuickBuyImmediateOfferImage from "../../../../../assets/QuickBuy/quickBuyImmediateOfferImage.jpg";
import OfferImage from "../../../../../assets/QuickBuy/offerImage.png";
import { routePaths } from "../../../../../router/routePaths";
import { useState } from "react";

const QuickBuyImmediateOffer = () => {
  const [
    isLoadingQuickBuyImmediateOfferImage,
    setIsLoadingQuickBuyImmediateOfferImage,
  ] = useState(true);
  return (
    <Box sx={styles.quickBuyImmediateOfferSection}>
      <Container maxWidth="xl">
        <Box>
          <Grid2
            spacing={{ md: 4 }}
            container
            sx={styles.quickBuyImmediateOfferGridWrapper}
          >
            <Grid2 size={{ xs: 12, md: 5, lg: 6 }} sx={styles.wrapper}>
              <Box>
                <Typography variant="h3" sx={styles.quickBuyLeasebackHeading}>
                  {`QuickBuy® Immediate Offer`}
                </Typography>
              </Box>
              <Box sx={styles.width}>
                <Typography variant="h3" sx={styles.quickBuyContent}>
                  {`When we say hassle-free we mean it. QuickBuy® is the perfect
                  way to sell your house quickly, with no problems and no
                  worries. You'll have more buying power and the cash you need
                  to find your next home.`}
                </Typography>
                <Typography
                  variant="h3"
                  sx={styles.quickBuyLeasebackSubHeading}
                >
                  {`Is QuickBuy® Immediate Offer the right fit for you?`}
                </Typography>
                <List>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    We present an immediate offer on a qualified home.
                  </ListItem>
                  <ListItem sx={styles.firstListItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    Bypass showings and preparing your home for market.
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    Close on your home in as few as 14 days.
                  </ListItem>
                </List>
              </Box>
              <NavLink to={routePaths.quickBuyForm}>
                <Button sx={styles.button}>Get My Offer</Button>
              </NavLink>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 7, lg: 6 }}>
              <Box sx={styles.relative}>
                <Box
                  component="img"
                  sx={styles.quickBuyImage}
                  src={QuickBuyImmediateOfferImage}
                  onLoad={() => setIsLoadingQuickBuyImmediateOfferImage(false)}
                ></Box>
                {isLoadingQuickBuyImmediateOfferImage && (
                  <CircularProgress
                    size={40}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                <Box sx={styles.offerImageWrapper}>
                  <Box
                    component="img"
                    src={OfferImage}
                    sx={styles.offerImage}
                    alt="offer"
                  ></Box>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
};

export default QuickBuyImmediateOffer;
