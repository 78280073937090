import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid2,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import DashboardFooter from "../../../components/DashboardFooter/DashboardFooter";
import { getServiceProviderDashboardData } from "../../../network/services";
import styles from "./style";
import Snackbar from "@mui/material/Snackbar";
import calenderIcon from "../../../assets/Dashboard/calenderIcon.svg";
import callIcon from "../../../assets/Dashboard/callIcon.svg";
import copyIcon from "../../../assets/Dashboard/copyIcon.svg";
import mailIcon from "../../../assets/Dashboard/mailIcon.svg";
import noDataFound from "../../../assets/Dashboard/noDataFound.svg";
import prevIcon from "../../../assets/Common/prevIcon.svg";
import nextIcon from "../../../assets/Common/nextIcon.svg";
import DashboardMobileHeader from "../../../components/DashboardHeader/DashboardMobileHeader";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import InquiryDetailsModal from "./InquiryDetailsModal";
import { formatTimestamp } from "../../../utils/formatTimeStamp";
interface CategoryDetail {
  categoryName: string;
  displayImage: {
    url: string;
  };
}
function formatText(text: string) {
  const maxCharacters = 120;
  if (text.length > maxCharacters) {
    return text.slice(0, maxCharacters);
  }
  return text;
}

const PreviousSlot = ({ onPreviousClick }: { onPreviousClick: () => void }) => (
  <Box sx={styles.paginationPrevious} onClick={onPreviousClick}>
    <img src={prevIcon} alt="Prev Icon" />
    <Box sx={styles.paginationText}>Previous</Box>
  </Box>
);

const NextSlot = ({
  page,
  totalPages,
  onNextClick,
}: {
  page: number;
  totalPages: number;
  onNextClick: () => void;
}) => (
  <Box
    sx={styles.paginationNext}
    onClick={onNextClick}
    style={{ pointerEvents: page === totalPages ? "none" : "auto" }}
  >
    <Box sx={styles.paginationText}>Next</Box>
    <img src={nextIcon} alt="Next Icon" />
  </Box>
);

const DashboardPage = () => {
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [inquiryList, setInquiryList] = useState([]);
  interface Inquiry {
    _id: string;
    fullName: string;
    updatedAt: string;
    phoneNumber: string;
    email: string;
    message: string;
    categoryDetails: CategoryDetail[];
  }

  const [selectedInquiry, setSelectedInquiry] = useState<Inquiry | null>(null);
  const [openTextCopiedSnackbar, setOpenTextCopiedSnackbar] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const dispatch = useDispatch();
  const resultsRef = useRef<HTMLDivElement>(null);
  const handleClickViewDetailsModalOpen = (inquiry: Inquiry) => {
    setSelectedInquiry(inquiry);
    setViewDetailsModal(true);
  };
  const handleClickViewDetailsModalClose = () => {
    setViewDetailsModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setOpenTextCopiedSnackbar(false);
  };

  const paginationSlots = {
    previous: PreviousSlot,
    next: NextSlot,
  };

  const getServiceProviderDashboardDetails = async () => {
    dispatch(setLoading(true));
    const response = await getServiceProviderDashboardData(page, 9);
    const data = await response.data;
    setTotalRecordsCount(response.totalCount);
    setTotalPages(Math.ceil(response.totalCount / 9));
    setInquiryList(data);
    dispatch(setLoading(false));
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setOpenTextCopiedSnackbar(true);
  };

  useEffect(() => {
    getServiceProviderDashboardDetails();
  }, [page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
    if (resultsRef.current) {
      window.requestAnimationFrame(() => {
        resultsRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
  };

  const handlePreviousClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <Box className="dashboardPage">
        <Box sx={styles.dashboardMobileHeader}>
          <DashboardMobileHeader />
        </Box>
        <Box sx={styles.dashboardHeaderDiv}>
          <DashboardHeader />
        </Box>
        <Box sx={styles.dashboardBox}>
          <Box sx={styles.dashboardHeaderBox}>
            <Typography variant="h3" sx={styles.dashboardTitle}>
              Dashboard
            </Typography>
          </Box>
          <Box sx={styles.inquiryListContainer}>
            <Grid2 container spacing={{ md: 3, xs: 2 }} ref={resultsRef}>
              {inquiryList.map((inquiry: Inquiry) => (
                <Grid2 size={{ xs: 12, md: 6, lg: 4 }} key={inquiry._id}>
                  <Box sx={styles.card}>
                    <Box sx={styles.cardHeader}>
                      <Box sx={styles.cardHeaderTitle}>
                        <Typography variant="h3" sx={styles.userName}>
                          {inquiry.fullName}
                        </Typography>
                        <Box sx={styles.dateWrapper}>
                          <img src={calenderIcon} alt="Calender Icon" />
                          <Typography variant="h3" sx={styles.date}>
                            {formatTimestamp(inquiry.updatedAt)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={styles.contactWrapper}>
                        <Box sx={styles.contactInnerWrapper}>
                          <Box sx={styles.callIcon}>
                            <img src={callIcon} alt="Call Icon" />
                          </Box>
                          <Typography variant="h3" sx={styles.callText}>
                            {inquiry.phoneNumber}
                          </Typography>
                        </Box>

                        <Box sx={styles.mailIconWrapper}>
                          <Box sx={styles.mailIcon}>
                            <img src={mailIcon} alt="Mail Icon" />
                          </Box>
                          <Typography variant="h3" sx={styles.mailText}>
                            {inquiry.email}
                          </Typography>
                          <Box
                            sx={styles.mailIconImage}
                            onClick={() =>
                              handleCopy(
                                inquiry.phoneNumber + " " + inquiry.email
                              )
                            }
                          >
                            <img src={copyIcon} alt="Copy Icon" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: {
                          xl: "20px",
                          xs: "15px",
                        },
                      }}
                    >
                      <Box sx={styles.flexWrapper}>
                        <Typography variant="h4" sx={styles.sectionLabel}>
                          Service Category
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={styles.viewAllButton}
                          onClick={() =>
                            handleClickViewDetailsModalOpen(inquiry)
                          }
                        >
                          VIEW ALL
                        </Typography>
                      </Box>
                      <Box sx={styles.serviceContainer}>
                        {inquiry.categoryDetails.map(
                          (category: CategoryDetail) => (
                            <Box
                              sx={styles.serviceBox}
                              key={category?.categoryName}
                            >
                              <Box sx={styles.serviceIconBox}>
                                <img
                                  src={category.displayImage.url}
                                  width="100%"
                                  alt="Pipe Installation"
                                />
                              </Box>
                              {category.categoryName}
                            </Box>
                          )
                        )}
                      </Box>
                      <Box>
                        <Typography variant="h4" sx={styles.descriptionTitle}>
                          Description
                        </Typography>
                        <Box>
                          <Typography sx={styles.descriptionText}>
                            {formatText(inquiry.message.slice(0, 88))}
                            {inquiry.message.length > 50 && (
                              <Box
                                component="span"
                                sx={styles.readMoreButton}
                                onClick={() =>
                                  handleClickViewDetailsModalOpen(inquiry)
                                }
                              >
                                &nbsp; Read More
                              </Box>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid2>
              ))}
            </Grid2>
            {inquiryList.length === 0 && (
              <Box sx={styles.noResultDataWrapper}>
                <Box
                  component="img"
                  src={noDataFound}
                  sx={styles.noResultImage}
                ></Box>
                <Typography variant="h5" sx={styles.heading}>
                  {`"Currently, there are no service requests available. Please
                  check back later for new requests."`}
                </Typography>
              </Box>
            )}

            {totalRecordsCount > 9 && (
              <Box sx={styles.paginationContainer}>
                <Pagination
                  count={Math.ceil(totalRecordsCount / 9)}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      slots={paginationSlots}
                      slotProps={{
                        previous: { onClick: handlePreviousClick },
                        next: {
                          onClick: handleNextClick,
                          disabled: page === totalPages,
                        },
                      }}
                      sx={{
                        ...(item.selected && styles.paginationItem),
                      }}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
          <Snackbar
            open={openTextCopiedSnackbar}
            autoHideDuration={1500}
            onClose={handleClose}
            message="Text copied"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          />
          <DashboardFooter />
        </Box>
      </Box>
      <InquiryDetailsModal
        open={viewDetailsModal}
        onClose={handleClickViewDetailsModalClose}
        inquiry={selectedInquiry}
        handleCopy={handleCopy}
      />
    </>
  );
};
export default DashboardPage;
