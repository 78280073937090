import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Link,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid2,
  Button,
} from "@mui/material";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/ConsumerHeader/Header";
import GetHelp from "../../../module/LandingPage/ServiceProvider/GetHelp/GetHelp";
import { getServiceProvider } from "../../../network/services";
import "./index.css";
import styles from "./style";
import backIcon from "../../../assets/Common/backBlueIcon.svg";
import breadCumsIcon from "../../../assets/Icons/breadCumsIcon.svg";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import dummyImage from "../../../assets/Common/dummyImage.jpg";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { sortDisplaySlotsByTime } from "../../../utils/calendar";
import { routePaths } from "../../../router/routePaths";
import InquiryForm from "./InquiryForm";
import {
  BusinessImage,
  Category,
  SearchContext,
} from "../../../context/SearchContext";
import { DisplaySlot, TimeSlot } from "../../../utils/interfaces";
import { UserData } from "../../../redux/slices/userSlice";

dayjs.extend(isSameOrAfter);

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const DetailsServiceProvider = () => {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }
  const { updateFilter, updateData } = searchContext;
  const [inquiryFormModal, setInquiryFormModal] = useState(false);
  const [inquirySubmitted, setInquirySubmitted] = useState(false);
  const { id } = useParams();
  const [serviceProviderDetails, setServiceProviderDetails] =
    useState<UserData>({} as UserData);
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const role = "consumer";

  const handleClickInquiryFormModalOpen = () => {
    setInquiryFormModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getParticularServiceProvider = async (id: string) => {
    const response = await getServiceProvider(id);
    const data = await response.data;
    setServiceProviderDetails(data);
    reduxDispatch(setLoading(false));
  };

  useEffect(() => {
    if (id) {
      reduxDispatch(setLoading(true));
      getParticularServiceProvider(id);
    }
  }, [id]);

  const getSlotsWithTimings = (day: string) => {
    const data =
      serviceProviderDetails?.serviceTiming &&
      serviceProviderDetails?.serviceTiming[day];
    if (!data) return null;
    const today = dayjs().format("YYYY-MM-DD");
    const filteredSlots = Array.isArray(data.slots)
      ? data.slots.filter((slot: TimeSlot, index: number, self: TimeSlot[]) => {
          const isDuplicate = self.some((s, idx) => {
            // If the previous slot is from a past month, it should not be considered a duplicate
            if (s?.monthDate && dayjs(s.monthDate).isBefore(today)) {
              return false;
            }
            return (
              idx < index &&
              s.startTime === slot.startTime &&
              s.endTime === slot.endTime
            );
          });

          if (isDuplicate) {
            return false;
          }

          if (slot.startTime && slot.endTime && !slot?.monthDate) {
            return true;
          }

          // Check slots with monthDate greater than or equal to today
          if (
            slot.startTime &&
            slot.endTime &&
            slot?.monthDate &&
            dayjs(slot.monthDate).isSameOrAfter(today)
          ) {
            return true;
          }

          return false;
        })
      : [];

    const displaySlots: DisplaySlot[] = filteredSlots.map((slot) => ({
      ...slot,
      startTime: slot.startTime ? slot.startTime.toString() : undefined,
      endTime: slot.endTime ? slot.endTime.toString() : undefined,
    }));
    const sortedSlots = sortDisplaySlotsByTime(displaySlots);
    if (filteredSlots?.length === 0) {
      return null;
    }

    return {
      ...data,
      slots: sortedSlots,
    };
  };
  const noSlotsAvailable = weekDays.every((day) => !getSlotsWithTimings(day));
  const location = sessionStorage.getItem("location");
  const zipCode = sessionStorage.getItem("zipCode");

  const handleBackNavigation = () => {
    if (inquirySubmitted) {
      updateFilter("selectedService", null);
      updateFilter("selectedCategory", "");
      updateFilter("selectedSubCategories", []);
      updateFilter("zipCode", zipCode);
      updateFilter("searchedAddress", "");
      updateFilter("location", location);
      updateData("totalRecordsCount", 0);
      updateData("isSearchClicked", false);
      updateData("serviceProviders", []);
      updateData("page", 1);
    }
    navigate(routePaths.expertServiceProvider);
  };

  const imageUrl =
    typeof serviceProviderDetails?.profileImage === "object" &&
    serviceProviderDetails.profileImage !== null &&
    "url" in serviceProviderDetails.profileImage &&
    typeof serviceProviderDetails?.profileImage?.url === "string"
      ? serviceProviderDetails.profileImage.url
      : dummyImage;
  return (
    <>
      <Header />
      <Box sx={styles.background}>
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xl: "0 24px 80px",
              lg: "0 60px 80px",
              xs: "0 16px 0",
            },
          }}
        >
          <Box sx={styles.contactWrappercontainer}>
            <Box
              component="img"
              src={backIcon}
              alt="BackArrow"
              sx={{
                width: {
                  xl: "auto",
                  xs: "30px",
                },
              }}
              onClick={handleBackNavigation}
            ></Box>
            <List sx={styles.list}>
              <ListItem
                sx={{
                  display: "flex",
                  alignItems: {
                    sm: "center",
                    xs: "flex-start",
                  },
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  padding: {
                    xs: "8px 0 8px 10px",
                    xl: "8px 10px",
                    lg: "8px 10px",
                  },
                }}
              >
                <ListItemText>
                  <Box onClick={handleBackNavigation}>
                    <Link sx={styles.link}>
                      Service Providers{" "}
                      <Box
                        sx={{
                          width: {
                            xl: "auto",
                            xs: "8px",
                          },
                        }}
                        component="img"
                        className="breadCums-icon"
                        src={breadCumsIcon}
                        alt="BreadCums"
                      ></Box>
                    </Link>
                  </Box>
                </ListItemText>
                <ListItemText>
                  <Link sx={styles.linkActive}>
                    {serviceProviderDetails?.fullName}
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box sx={styles.card}>
            <Box sx={styles.cardHeader}>
              <Box sx={styles.profileContainer}>
                <Box
                  sx={styles.profileImage}
                  component="img"
                  src={imageUrl}
                  alt="User"
                ></Box>
                <Box sx={styles.profileDetails}>
                  <Typography variant="h4" sx={styles.fullName}>
                    {serviceProviderDetails?.fullName}
                  </Typography>
                  <Box sx={styles.statusDot}></Box>
                  <Typography variant="h4" sx={styles.businessTitle}>
                    {serviceProviderDetails?.businessTitle}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {inquirySubmitted ? (
                  <Button sx={styles.inquirySentbutton}>Inquiry Sent</Button>
                ) : (
                  <Button
                    sx={styles.button}
                    onClick={handleClickInquiryFormModalOpen}
                  >
                    Send Inquiry
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={styles.operatingHours}>
              <Typography variant="body1" sx={styles.operatingHoursTitle}>
                Operating Hours:
              </Typography>
              <Typography variant="body1" sx={styles.operatingHoursText}>
                <div>
                  {noSlotsAvailable ? (
                    <p>No available slots</p> // Show this message if no valid slots are found
                  ) : (
                    weekDays.map((day) => (
                      <Box sx={{ marginBottom: "5px" }} key={day}>
                        {getSlotsWithTimings(day) && (
                          <>
                            <span>{capitalizeFirstLetter(day)}</span>
                            <br />
                            <Box sx={styles.slotContainer}>
                              {getSlotsWithTimings(day)?.slots?.map(
                                (slot: DisplaySlot, index: number) => (
                                  <span
                                    key={
                                      slot.startTime?.toString() ||
                                      index.toString()
                                    }
                                  >
                                    <>
                                      Slot {index + 1} : {slot.startTime} -{" "}
                                      {slot.endTime}
                                    </>
                                  </span>
                                )
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    ))
                  )}
                </div>
              </Typography>
            </Box>

            <Box sx={styles.servicesOffered}>
              <Typography variant="h4" sx={styles.servicesOfferedTitle}>
                Service Categories Offered:
              </Typography>
              <Box sx={styles.servicesList}>
                {serviceProviderDetails?.categoryDetails?.map(
                  (category, index) => {
                    if (
                      !(
                        "categoryName" in category && "displayImage" in category
                      )
                    ) {
                      return null;
                    }
                    return (
                      <Box
                        key={(category as Category)._id || index}
                        sx={styles.serviceCategory}
                      >
                        <Box sx={styles.serviceCategoryIcon}>
                          <img
                            src={category.displayImage.url}
                            alt={category.categoryName}
                            className="categoryTitle"
                          />
                        </Box>
                        {category.categoryName}
                      </Box>
                    );
                  }
                )}
              </Box>
            </Box>

            <Box sx={styles.servicesImages}>
              <Typography variant="h4" sx={styles.servicesImagesTitle}>
                Services Images:
              </Typography>
              <Grid2 container spacing={3}>
                {serviceProviderDetails?.businessImages?.map(
                  (businessImage: BusinessImage) => (
                    <Grid2
                      size={{ xs: 12, sm: 6, md: 4 }}
                      key={businessImage?.url}
                    >
                      <Box
                        sx={styles.serviceImage}
                        src={businessImage?.url}
                        alt="Business"
                        component="img"
                      ></Box>
                    </Grid2>
                  )
                )}
              </Grid2>
            </Box>

            <Box sx={styles.businessDescription}>
              <Typography variant="h4" sx={styles.businessDescriptionTitle}>
                {serviceProviderDetails?.businessTitle}
              </Typography>
              <Typography variant="body1" sx={styles.businessDescriptionText}>
                {serviceProviderDetails?.description}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <GetHelp />
      <Footer role={role} />

      {/* Modal Enquery Form */}
      <InquiryForm
        inquiryFormModal={inquiryFormModal}
        setInquiryFormModal={setInquiryFormModal}
        setInquirySubmitted={setInquirySubmitted}
      />
    </>
  );
};

export default DetailsServiceProvider;
