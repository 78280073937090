import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    background: "#F6F6FD",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    padding: {
      xl: "0 30px 0 30px",
      xs: "0 16px",
    },
    position: "relative",
  },
  gridContainer: {
    "@media (min-width: 1536px)": {
      maxWidth: "1536px",
      margin: "auto",
    },
    flexDirection: {
      md: "row",
      xs: "column-reverse",
    },
  },
  textGridItem: {
    display: "flex",
    alignItems: "center",
  },
  textBox: {
    padding: {
      lg: "0 20px 0 0",
      md: "0 20px 0 0",
      xs: "20px 20px 30px 0",
    },
  },
  mainHeading: {
    fontSize: {
      lg: "42px",
      xs: "32px",
    },
    color: "#0F172A",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    lineHeight: {
      lg: "60px",
      xs: "44px",
    },
  },
  subHeading: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    paddingTop: "14px",
    color: "#64748B",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
  },
  contactButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      sm: "13px 30px",
    },
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "20px 0 0 0",
      sm: "20px 0 0 0",
      lg: "40px 0 0 0",
    },
  },
  imageGridItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
  },
  imageBox: {
    display: "flex",
    zIndex: "9",
    marginTop: "-30px",
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  vectorImage: {
    position: "absolute",
    top: "-30px",
    left: "0",
  },
};

export default styles;
