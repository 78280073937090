import React, { useState } from "react";
import {
  TextField,
  Popover,
  List,
  ListItemButton,
  InputAdornment,
  Box,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import downArrow from "../../assets/Icons/downArrow.svg";

dayjs.extend(customParseFormat);
interface TimePickerProp {
  placeholderText: string;
  selectedTime: Dayjs | null;
  onTimeChange: (time: Dayjs | null) => void;
  minTime?: Dayjs | null;
}

const CustomTimePicker: React.FC<TimePickerProp> = ({
  placeholderText,
  selectedTime,
  onTimeChange,
  minTime,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Function to open the dropdown
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Generate time options from 12:00 AM to 11:00 PM
  const generateTimeOptions = (): string[] => {
    const times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      const time = dayjs().set("hour", hour).set("minute", 0).format("h:mm A");
      times.push(time);
    }
    return times;
  };

  // Handle time selection
  const handleTimeSelect = (time: string): void => {
    const selectedDayjs = dayjs(time, "h:mm A");
    onTimeChange(selectedDayjs);
    handleClose(); // Close dropdown after selection
  };

  const timeOptions = generateTimeOptions();

  // Filter time options if `minTime` is provided (for the end time picker)
  const filteredTimeOptions = timeOptions.filter((time): boolean => {
    const optionTime = dayjs(time, "h:mm A");
    if (minTime) {
      return optionTime.isAfter(minTime);
    }
    return true;
  });
  if (minTime) {
    filteredTimeOptions.push("11:59 PM");
  }

  return (
    <div style={{ width: "100%" }}>
      <TextField
        onClick={handleClick}
        value={selectedTime ? selectedTime.format("h:mm A") : ""}
        placeholder={placeholderText}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleClick}>
              <Box
                component="img"
                src={downArrow}
                alt="Down Arrow"
                sx={{
                  width: {
                    sm: "24px",
                    xs: "22px",
                  },
                  height: {
                    sm: "24px",
                    xs: "22px",
                  },
                  cursor: "pointer",
                }}
              ></Box>
            </InputAdornment>
          ),
        }}
        sx={{
          height: "100%",
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
            paddingRight: {
              sm: "14px",
              xs: "8px",
            },
          },
          "& input": {
            color: "#0D0639",
            fontSize: {
              sm: "14px",
              xs: "12px",
            },
            fontWeight: "400",
            fontFamily: "Montserrat, sans-serif",
          },
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          height: "200px",
          scrollbarWidth: "none",
          "& .MuiPopover-paper": {
            scrollbarWidth: "none",
            borderRadius: "10px",
            color: "#0F172A",
            fontSize: "14px",
            fontWeight: "400",
            width: "158px",
          },
        }}
      >
        <List>
          {filteredTimeOptions.map((time) => (
            <ListItemButton key={time} onClick={() => handleTimeSelect(time)}>
              {time}
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default CustomTimePicker;
