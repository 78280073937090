import { Box, InputLabel, TextField } from "@mui/material";
import React from "react";
import styles from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import editIcon from "../../../assets/Settings/editIcon.svg";
import { getSx } from "../../../utils/styleHelpers";

interface EditAddressProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleEditClick: (field: string) => void;
  isEditingTitle: boolean;
  isEditingDescription: boolean;
}

const EditTitleDescription: React.FC<EditAddressProps> = ({
  handleInputChange,
  handleEditClick,
  isEditingTitle,
  isEditingDescription,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  return (
    <>
      <Box
        className={`formControl ${userProfile?.errors?.businessTitle ? "errorWrapper" : ""}`}
        sx={styles.formControl}
      >
        <InputLabel className="inputLable">Title</InputLabel>
        <TextField
          name="businessTitle"
          value={userProfile?.businessTitle || ""}
          placeholder="Title"
          onChange={handleInputChange}
          disabled={!isEditingTitle}
          error={!!userProfile?.errors?.businessTitle}
          helperText={userProfile?.errors?.businessTitle}
          sx={getSx(userProfile?.errors?.businessTitle)}
        />
        <Box
          onClick={() => handleEditClick("businessTitle")}
          className="editIcon"
        >
          <img src={editIcon} alt="Edit Icon" />
        </Box>
      </Box>
      <Box className="formControl descriptionInput" sx={styles.formControl}>
        <InputLabel className="inputLable">Description</InputLabel>
        <TextField
          name="description"
          value={userProfile?.description || ""}
          placeholder="Description"
          onChange={handleInputChange}
          disabled={!isEditingDescription}
          error={!!userProfile?.errors?.description}
          helperText={userProfile?.errors?.description}
          sx={getSx(userProfile?.errors?.description)}
          multiline
          maxRows={3}
        />
        <Box
          onClick={() => handleEditClick("description")}
          className="editIcon description"
        >
          <Box component="img" src={editIcon} alt="Edit Icon"></Box>
        </Box>
      </Box>
    </>
  );
};

export default EditTitleDescription;
