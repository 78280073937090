import { SxProps, Theme } from "@mui/system";
import expertServiceImage from "../../../../assets/Banner/expertServiceImage.png";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    background: "#06038D",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    padding: {
      xl: "0 30px 0 30px",
      xs: "20px 16px 0 16px",
    },
    backgroundImage: `url(${expertServiceImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    position: "relative",
    marginTop: {
      md: "-70px",
      xs: "0",
    },
  },
  gridContainer: {
    "@media (min-width: 1536px)": {
      maxWidth: "1536px",
      margin: "auto",
    },
    overflow: "hidden",
    padding: {
      xl: "0 24px",
      lg: "0 50px",
    },
  },
  textGridItem: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  title: {
    fontSize: {
      xl: "62px",
      lg: "50px",
      xs: "40px",
    },
    color: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    lineHeight: {
      lg: "69px",
      xs: "54px",
    },
    paddingBottom: {
      sm: "0",
      xs: "20px",
    },
  },
  imageGridItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
    bottom: {
      md: "-80px",
      sm: "-40px",
    },
  },
  imageBox: {
    display: "flex",
    height: {
      lg: "500px",
      md: "auto",
    },
  },
  loadingIndicator: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
  },
};

export default styles;
