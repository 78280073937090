import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import "../../module/Profile/Dashboard/dashboard.css";
import { routePaths } from "../../router/routePaths";
import dashboardHeaderLogo from "../../assets/Dashboard/dashboardHeaderLogo.png";
import logoutStatusIcon from "../../assets/Dashboard/logoutStatusIcon.png";
import dashboardIcon from "../../assets/Dashboard/dashboardIcon.svg";
import dashboardActiveIcon from "../../assets/Dashboard/dashboardActiveIcon.svg";
import settingIcon from "../../assets/Dashboard/settingIcon.svg";
import settingActiveIcon from "../../assets/Dashboard/settingActiveIcon.svg";
import availabilityIcon from "../../assets/Dashboard/availabilityIcon.svg";
import availabilityActiveIcon from "../../assets/Dashboard/availabilityActiveIcon.svg";
import logoutIcon from "../../assets/Dashboard/logoutIcon.svg";
import styles from "./style";

const DashboardHeader = () => {
  const [logoutAccountModal, setLogoutAccountModal] = React.useState(false);
  const navigate = useNavigate();

  const handleClickLogoutAccountModalClose = () => {
    setLogoutAccountModal(false);
  };

  const handleClickLogoutAccountModalOpen = () => {
    setLogoutAccountModal(true);
  };

  const logoutHandler = () => {
    localStorage.removeItem("accessToken");
    navigate(routePaths.signIn);
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.logoBox}>
          <Link to="/">
            <Box
              component="img"
              sx={styles.logoImage}
              src={dashboardHeaderLogo}
              alt="Dashboard Header Logo"
            ></Box>
          </Link>
        </Box>
        <Box sx={styles.menuBox}>
          <List className="dashboardMenu" sx={styles.menuList}>
            <ListItem sx={styles.menuItem}>
              <ListItemText>
                <NavLink
                  to={routePaths.dashboard}
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Box sx={styles.menuItemBox} className="boxWrapper">
                    <Box
                      component="img"
                      sx={styles.menuItemImage}
                      src={dashboardIcon}
                      className="inactive"
                    ></Box>
                    <Box
                      component="img"
                      sx={styles.menuItemImage}
                      src={dashboardActiveIcon}
                      className="activeIcon"
                    ></Box>
                  </Box>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.menuItem}>
              <ListItemText>
                <NavLink
                  to={routePaths.availability}
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Box sx={styles.menuItemBox} className="boxWrapper">
                    <Box
                      component="img"
                      sx={styles.menuItemImage}
                      src={availabilityIcon}
                      className="inactive"
                    ></Box>
                    <Box
                      component="img"
                      sx={styles.menuItemImage}
                      src={availabilityActiveIcon}
                      className="activeIcon"
                    ></Box>
                  </Box>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.menuItem}>
              <ListItemText>
                <NavLink
                  to={routePaths.settings}
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Box sx={styles.menuItemBox} className="boxWrapper">
                    <Box
                      component="img"
                      sx={styles.menuItemImage}
                      src={settingIcon}
                      className="inactive"
                    ></Box>
                    <Box
                      component="img"
                      sx={styles.menuItemImage}
                      src={settingActiveIcon}
                      className="activeIcon"
                    ></Box>
                  </Box>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.logoutItem}>
              <ListItemText>
                <Box
                  onClick={handleClickLogoutAccountModalOpen}
                  sx={styles.logoutBox}
                >
                  <Box component="img" src={logoutIcon}></Box>
                </Box>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* Delete Accound Modal */}
      <Dialog
        onClose={handleClickLogoutAccountModalClose}
        aria-labelledby="customized-dialog-title"
        open={logoutAccountModal}
        sx={{
          "& .MuiDialog-paper": {
            width: {
              xs: "90%",
              sm: "70%",
              md: "400px",
            },
            borderRadius: "40px",
            maxWidth: "90%",
          },
        }}
      >
        <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
          <img src={logoutStatusIcon} alt="Sucess" />
          <Box>
            Are you sure you want to logout from Home SellerService LLC?
          </Box>
        </DialogTitle>

        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.contentBox}>
            <Box sx={styles.buttonContainer}>
              <Button sx={styles.yesButton} onClick={logoutHandler}>
                Yes
              </Button>
              <Button
                sx={styles.noButton}
                onClick={handleClickLogoutAccountModalClose}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DashboardHeader;
