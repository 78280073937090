import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import { Box } from "@mui/material";
import { routePaths } from "../../../router/routePaths";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { getSubCategories } from "../../../network/services";
import { updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";
import SuccessModal from "../../../components/Modal/SuccessModal";
import useDebounce from "../../../hooks/useDebounce";
import styles from "../SelectServiceSubCategory/style";
import AddSubcatComp, {
  Categories,
} from "../../../components/AddSubcatComp/AddSubcatComp";
import { deriveSubCategoryIds } from "../../../utils/profileHelpers";

const EditServiceSubCategory = () => {
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(
    []
  );
  const userData = useSelector((state: RootState) => state.user.userData);
  const [categoryIds, setCategoryIds] = useState<string[]>(
    userData?.categoryIds || []
  );
  const [categories, setCategories] = useState<Categories[]>([]);
  const [searchSubcatText, setSearchSubcatText] = useState<string>("");
  const debouncedSearchSubcatText = useDebounce(searchSubcatText, 500);
  const [openSuccessModal, setOpenSuccessModal] = useState<string>("");
  const [initialCall, setInitialCall] = useState<boolean>(true);

  const updatedSelectedItems = useSelector(
    (state: RootState) => state.user.userData?.subCategoryIds
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [staticSelectedSubCategories, setStaticSelectedSubCategories] =
    useState<string[]>([]);

  useEffect(() => {
    setSelectedSubCategories(updatedSelectedItems || []);
  }, [updatedSelectedItems]);

  useEffect(() => {
    if (userData?.categoryIds) {
      setCategoryIds(userData.categoryIds);
    }
  }, []);

  useEffect(() => {
    setSelectedSubCategories(updatedSelectedItems || []);
    setStaticSelectedSubCategories(updatedSelectedItems || []);
    if (userData?.categoryIds) {
      setCategoryIds(userData.categoryIds);
    }
  }, [userData]);

  useEffect(() => {
    fetchSubCategories(debouncedSearchSubcatText);
  }, [debouncedSearchSubcatText, categoryIds]);

  useEffect(() => {
    if (initialCall && staticSelectedSubCategories.length > 0) {
      fetchSubCategories(debouncedSearchSubcatText);
      setInitialCall(false);
    }
  }, [staticSelectedSubCategories]);

  const fetchSubCategories = async (textArg: string) => {
    try {
      const payload = {
        categoryIds: userData?.categoryIds || [],
        keyword: searchSubcatText,
      };
      const res = await getSubCategories(payload);
      const subCategoryIds = deriveSubCategoryIds(
        res,
        categoryIds,
        staticSelectedSubCategories
      );
      if (!textArg) setStaticSelectedSubCategories(subCategoryIds);
      setCategories(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const selectCategoryHandler = async () => {
    try {
      const payload = {
        serviceIds: userData?.serviceIds,
        categoryIds: userData?.categoryIds,
        subCategoryIds: staticSelectedSubCategories,
      };
      dispatch(setLoading(true));
      const response = await updateUserProfile(payload);
      dispatch(setUserData(payload));
      if (response) {
        dispatch(setLoading(false));
        setOpenSuccessModal("Business details updated successfully");
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  const closeModalHandler = () => {
    setOpenSuccessModal("");
    navigate(routePaths.settings);
  };
  return (
    <Box
      className="selectSubServiceCategoryForm"
      sx={styles.selectSubServiceCategoryForm}
    >
      <ProfileWrapper
        title="Edit Service Sub Category"
        btnDisabled={staticSelectedSubCategories?.length === 0}
        onSubmit={selectCategoryHandler}
        hide={true}
        buttonText="Save"
        backHandler={() =>
          navigate(`${routePaths.editServiceCategory}?previous=true`)
        }
      >
        <AddSubcatComp
          searchSubcatText={searchSubcatText}
          selectedSubCategories={selectedSubCategories}
          staticSelectedSubCategories={staticSelectedSubCategories}
          categories={categories}
          setSelectedSubCategories={setSelectedSubCategories}
          setStaticSelectedSubCategories={setStaticSelectedSubCategories}
          setSearchSubcatText={setSearchSubcatText}
        />
        {openSuccessModal && (
          <SuccessModal
            content={openSuccessModal}
            onClose={closeModalHandler}
            onSuccess={closeModalHandler}
          />
        )}
      </ProfileWrapper>
    </Box>
  );
};

export default EditServiceSubCategory;
