import axiosInstance from "./axios";

export const uploadImage = async (payload: FormData) => {
  const res = await axiosInstance.post(`/file/upload`, payload);
  return res.data;
};

export const getQuestions = async () => {
  const res = await axiosInstance.get(`/faq/list-for-user`);
  return res.data;
};

export const getTermsAndConditions = async () => {
  const res = await axiosInstance.get(`/terms-condition/list-for-user`);
  return res.data;
};

export const getPrivacyPolicy = async () => {
  const res = await axiosInstance.get(`/privacy-policy/list-for-user`);
  return res.data;
};
