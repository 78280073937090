import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid2,
  Typography,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import serviceVectorImage from "../../../../assets/Consumer/service-vector-image.svg";
import serviceIcon1 from "../../../../assets/Consumer/service-icon1.svg";
import serviceIcon2 from "../../../../assets/Consumer/service-icon2.svg";
import serviceIcon3 from "../../../../assets/Consumer/service-icon3.svg";
import serviceIcon4 from "../../../../assets/Consumer/service-icon4.svg";
import serviceIcon5 from "../../../../assets/Consumer/service-icon5.svg";
import serviceIcon6 from "../../../../assets/Consumer/service-icon6.svg";
import offerImage from "../../../../assets/Consumer/offerImage.png";
import quickBuyLockImage from "../../../../assets/Consumer/quickBuyLockImage.png";
import quickBuyLeackBack from "../../../../assets/Consumer/quickBuyLeackBack.png";
import serviceImage from "../../../../assets/Consumer/service-image.png";
import { useState } from "react";
import styles from "./style";
import { routePaths } from "../../../../router/routePaths";

const Services = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box sx={styles.mainBox}>
        <Container maxWidth="xl">
          <Typography variant="h3" sx={styles.title}>
            Together, Let’s Find the Best Home Sale Solution for You
          </Typography>
          <Typography variant="body1" sx={styles.subtitle}>
            We make selling your home convenient, flexible and quick
          </Typography>

          <Grid2
            container
            sx={{
              justifyContent: {
                sm: "center",
                xs: "flex-start",
                md: "flex-start",
              },
            }}
            spacing={6}
          >
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
              <Box sx={styles.serviceBox}>
                <Box sx={styles.serviceIconBox}>
                  <img src={offerImage} alt="Offer" />
                </Box>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={styles.serviceTitle}
                >
                  QuickBuy® Immediate Offer
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={styles.serviceDescription}
                >
                  Receive an immediate <b>cash</b> offer. Enjoy convenience and
                  certainty. No home prep and no showings. Close on your
                  timeline, in as few as 14 - 60 business days. Be in a better
                  position to buy.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
              <Box sx={styles.serviceBox}>
                <Box sx={styles.serviceIconBox}>
                  <img src={quickBuyLockImage} alt="Offer" />
                </Box>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={styles.serviceTitle}
                >
                  QuickBuy® Lock
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={styles.serviceDescription}
                >
                  QuickBuy <b>Lock</b> program is the same as cash, but allows
                  you to list your home for up to 150 days to see if the
                  traditional sale will net you more profit, knowing that you
                  have an offer in-hand by QuickBuy. You can accept the offer
                  any time while listed.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
              <Box sx={styles.serviceBox}>
                <Box sx={styles.serviceIconBox}>
                  <img src={quickBuyLeackBack} alt="Offer" />
                </Box>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={styles.serviceTitle}
                >
                  QuickBuy® Leaseback
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={styles.serviceDescription}
                >
                  QuickBuy will purchase your home and <b>lease</b> it back to
                  you. Short and long term lease available. Alleviates any
                  contingencies when purchasing another property. Allows you to
                  secure your home sale proceeds.
                </Typography>
              </Box>
            </Grid2>
          </Grid2>

          <Box sx={styles.buttonContainer}>
            <Link to={routePaths.quickBuyForm}>
              <Button sx={styles.contactButton}>Contact Us</Button>
            </Link>
            <Link to={routePaths.quickBuy}>
              <Button sx={styles.learnMoreButton}>Learn More {">"}</Button>
            </Link>
          </Box>
        </Container>
      </Box>
      <Box sx={styles.backgroundBox}>
        <Box sx={styles.vectorImageBox}>
          <img src={serviceVectorImage} alt="Service Vector" />
        </Box>
        <Typography variant="h3" sx={styles.homeSellingTitle}>
          Home Selling Services
        </Typography>
        <Typography variant="body1" sx={styles.homeSellingSubtitle}>
          Your Trusted Partners – Connecting You with Experts for Every Home
          Need and More
        </Typography>

        <Box sx={styles.containerPadding}>
          <Container maxWidth="xl">
            <Grid2 container spacing={3} sx={styles.gridContainer}>
              <Grid2 size={{ xs: 12, sm: 12, md: 6 }} sx={styles.gridItem}>
                <Box
                  sx={styles.serviceImageBox}
                  component="img"
                  src={serviceImage}
                  alt="Service"
                  onLoad={() => setIsLoading(false)}
                ></Box>
                {isLoading && (
                  <Box sx={styles.loadingBox}>
                    <CircularProgress />
                  </Box>
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                <Grid2 container spacing={2}>
                  <Grid2
                    size={{ xs: 12, sm: 6, xl: 4 }}
                    sx={styles.serviceGridItem}
                  >
                    <Box sx={styles.serviceContainer}>
                      <Box sx={styles.serviceIconContainer}>
                        <img src={serviceIcon1} alt="Service Icon" />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={styles.serviceContainerDescription}
                      >
                        <b style={{ fontWeight: "500" }}>Attorney Services:</b>{" "}
                        for your home sale and purchase. Attorneys also provide
                        additional professional services.
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, sm: 6, xl: 4 }}
                    sx={styles.serviceGridItem}
                  >
                    <Box sx={styles.serviceContainer}>
                      <Box
                        sx={{
                          ...styles.serviceIconContainer,
                          background: "#FAE6DF",
                        }}
                      >
                        <img src={serviceIcon2} alt="Service Icon" />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={styles.serviceContainerDescription}
                      >
                        <b style={{ fontWeight: "500" }}>
                          Full Array of Contractors:{" "}
                        </b>{" "}
                        {` Access trusted contractors for repairs, renovations, and
                        improvements to boost your home's value.`}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, sm: 6, xl: 4 }}
                    sx={styles.serviceGridItem}
                  >
                    <Box sx={styles.serviceContainer}>
                      <Box
                        sx={{
                          ...styles.serviceIconContainer,
                          background: "#E1F8F4",
                        }}
                      >
                        <img src={serviceIcon3} alt="Service Icon" />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={styles.serviceContainerDescription}
                      >
                        <b style={{ fontWeight: "500" }}>
                          Home Inspection Services:{" "}
                        </b>
                        Detailed inspections to identify and address any
                        potential issues, ensuring a smooth transaction.
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, sm: 6, xl: 4 }}
                    sx={styles.serviceGridItem}
                  >
                    <Box sx={styles.serviceContainer}>
                      <Box
                        sx={{
                          ...styles.serviceIconContainer,
                          background: "#EBEAFF",
                        }}
                      >
                        <img src={serviceIcon4} alt="Service Icon" />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={styles.serviceContainerDescription}
                      >
                        <b style={{ fontWeight: "500" }}>
                          Homeowners Insurance Services:{" "}
                        </b>
                        Obtain the best coverage to protect your home and
                        valuables.
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, sm: 6, xl: 4 }}
                    sx={styles.serviceGridItem}
                  >
                    <Box sx={styles.serviceContainer}>
                      <Box
                        sx={{
                          ...styles.serviceIconContainer,
                          background: "#FFF0FE",
                        }}
                      >
                        <img src={serviceIcon5} alt="Service Icon" />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={styles.serviceContainerDescription}
                      >
                        <b style={{ fontWeight: "500" }}>Mortgage Lending: </b>
                        Connect with Top Lending Institutions to secure the best
                        finance options for your next purchase.
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{ xs: 12, sm: 6, xl: 4 }}
                    sx={styles.serviceGridItem}
                  >
                    <Box sx={styles.serviceContainer}>
                      <Box
                        sx={{
                          ...styles.serviceIconContainer,
                          background: "#DDF8FA",
                        }}
                      >
                        <img src={serviceIcon6} alt="Service Icon" />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={styles.serviceContainerDescription}
                      >
                        <b style={{ fontWeight: "500" }}>
                          Professional Home Staging:{" "}
                        </b>
                        {`Expert staging services to showcase your home's best
                        features and attract more buyers.`}
                      </Typography>
                    </Box>
                  </Grid2>
                </Grid2>
                <Box sx={styles.viewMoreButtonBox}>
                  <NavLink to={routePaths.expertServiceProvider}>
                    <Button sx={styles.viewMoreButton}>View More</Button>
                  </NavLink>
                </Box>
              </Grid2>
            </Grid2>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Services;
