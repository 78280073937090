import React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { FormHelperText } from "@mui/material";

interface CustomTextFieldProps {
  label?: string; // Required label
  value: string; // Required value
  placeholder: string; // Required placeholder
  adornmentEnabled?: string; // Optional adornment
  adornmentDisabled?: string;
  endAdornment?: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  error?: boolean;
  errorMessage?: string;
  profile?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  allowOnlyAlphabets?: boolean;
  maxChar?: number;
  password?: boolean;
  onPwdIconClick?: () => void;
  autoComplete?: string;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  label,
  name,
  value,
  placeholder,
  adornmentEnabled,
  adornmentDisabled,
  type,
  onChange,
  onBlur,
  error = false,
  errorMessage = "",
  endAdornment, //also used to identify a search field
  multiline = false,
  disabled,
  allowOnlyAlphabets,
  maxChar,
  password,
  onPwdIconClick,
  autoComplete,
  onFocus,
}) => {
  const hasAdornment = Boolean(adornmentEnabled || adornmentDisabled);

  // for character restriction in input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (allowOnlyAlphabets) {
      newValue = newValue.replace(/[^a-zA-Z\s]/g, "");
    }
    onChange({
      ...e,
      target: { ...e.target, value: newValue },
    });
  };
  const handleIconClick = (value: string) => {
    if (value?.length && password) {
      if (onPwdIconClick) onPwdIconClick();
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Box
      className="formControl steps"
      sx={{
        position: "relative",
        borderRadius: "6px",
        marginBottom: {
          xl: "16px",
          lg: "12px",
        },
        input: {
          borderRadius: "6px",
          ml: hasAdornment ? "15px" : "0px",
        },
      }}
    >
      <InputLabel
        className="inputLabel"
        sx={{
          color: "#111038",
          fontWeight: 500,
          fontSize: "14px",
          mb: 1,
          fontFamily: "Montserrat, sans- serif",
        }}
      >
        {label}
      </InputLabel>
      <TextField
        value={value}
        sx={{
          background: "#F6F7F9",
          borderRadius: "6px",
          fontFamily: "Montserrat, sans- serif",
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(137, 137, 163, 1)",
            fontSize: "14px",
          },
          "& input::placeholder": {
            fontSize: "14px !important",
          },
          "& textarea::placeholder": {
            color: multiline ? "rgba(137, 137, 163, 1) !important" : "", // Red when multiline
            fontSize: "14px !important",
            fontFamily: "Montserrat, sans- serif !important",
          },
          "& input": {
            padding: {
              md: "14px 18px",
              fontSize: {
                xl: "16px",
                xs: "14px",
              },
              xl: endAdornment ? "10px 18px" : "16px 18px",
            },
          },
          "& textarea": {
            padding: "5px 0",
            scrollbarWidth: "none",
          },
          "& .MuiOutlinedInput-root": {
            borderColor: error ? "#d32f2f" : "transparent",
            "&:hover fieldset": {
              borderColor: error ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
            },
            fieldset: {
              borderColor: error ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
            },
            "&.Mui-focused fieldset": {
              borderColor: error ? "#d32f2f" : "#3f51b5",
            },
          },
        }}
        type={type}
        placeholder={placeholder}
        onChange={allowOnlyAlphabets ? handleInputChange : onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        id={name}
        multiline={multiline}
        disabled={disabled}
        autoComplete={autoComplete}
        onKeyDown={handleKeyDown}
        slotProps={{
          input: {
            ...(maxChar && { maxLength: maxChar }),
            ...(multiline ? { rows: 3, maxRows: 8 } : {}),
            startAdornment: adornmentEnabled ? (
              <InputAdornment
                position="start"
                sx={{
                  position: "absolute",
                  left: -8,
                  top: { md: 12, xl: 14 },
                  cursor: value?.length && password ? "pointer" : "",
                }}
              >
                <img
                  src={value?.length ? adornmentEnabled : adornmentDisabled}
                  alt="adornment"
                  onClick={() => handleIconClick(value)}
                />
              </InputAdornment>
            ) : null,
            endAdornment: endAdornment ? (
              <InputAdornment position="end">
                <img src={endAdornment} alt={name} />
              </InputAdornment>
            ) : null,
          },
        }}
      />
      {error && (
        <FormHelperText
          error
          sx={{
            maxWidth: "100%",
            whiteSpace: "normal",
            paddingLeft: "11px",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CustomTextField;
