import { Box, InputLabel, TextField } from "@mui/material";
import React from "react";
import styles from "./style";
import { formatFullAddress } from "../../../utils/formatUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import editIcon from "../../../assets/Settings/editIcon.svg";

interface EditAddressProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleEditClick: (field: string) => void;
  isEditingAddress: boolean;
}

const EditAddress: React.FC<EditAddressProps> = ({
  handleInputChange,
  handleEditClick,
  isEditingAddress,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  return (
    <>
      <Box sx={styles.addressBox}>
        <Box className="formControl" sx={styles.formControl}>
          <InputLabel className="inputLable">Address</InputLabel>
          <TextField
            name="address"
            value={formatFullAddress(
              userProfile?.address || "",
              userProfile?.city || "",
              userProfile?.province || "",
              userProfile?.zipCode || ""
            )}
            placeholder="Description"
            onChange={handleInputChange}
            disabled={!isEditingAddress}
          />
          <Box onClick={() => handleEditClick("address")} className="editIcon">
            <img src={editIcon} alt="Edit Icon" />
          </Box>
        </Box>
      </Box>
      <Box className="formControl zipcode" sx={styles.formControl}>
        <InputLabel className="inputLable">
          Service Location Zip Codes
        </InputLabel>
        <TextField
          name="zipCodes"
          value={userProfile?.zipCodes || ""}
          disabled
          placeholder="Service Location Zip Codes"
        />
      </Box>
    </>
  );
};

export default EditAddress;
