import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SingIn from "./module/LandingPage/SignIn/SignIn";
import PersonalDetails from "./module/Profile/PersonalDetails/PersonalDetails";
import Consumer from "./module/LandingPage/Consumer";
import ServiceProvider from "./module/LandingPage/ServiceProvider";
import ExpertServiceProvider from "./module/LandingPage/ExpertServiceProvider";
import TermsAndConditions from "./module/LandingPage/TermsAndConditions";
import PrivacyPolicy from "./module/LandingPage/PrivacyPolicy";
import DetailsServiceProvider from "./module/LandingPage/DetailsServiceProvider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { routePaths } from "./router/routePaths";
import UpdateProfile from "./module/Profile/UpdateProfile/UpdateProfile";
import AddBusiness from "./module/Profile/AddBusiness/AddBusiness";
import SelectServiceCategory from "./module/Profile/SelectServiceCategory/SelectServiceCategory";
import AddBusinessDetails from "./module/Profile/AddBusinessDetails/AddBusinessDetails";
import SelectServiceSubCategory from "./module/Profile/SelectServiceSubCategory/SelectServiceSubCategory";
import AddBusinessImages from "./module/Profile/AddBusinessImages/AddBusinessImages";
import QuickBuy from "./module/LandingPage/QuickBuy";
import Availability from "./module/Profile/EditAvailabilty/Availability";
import DashboardPage from "./module/Profile/Dashboard/Dashboard";
import AboutUs from "./module/LandingPage/AboutUs";
import AddOperatingHours from "./module/Profile/AddOperatingHours/AddOperatingHours";
import OperatingHours from "./module/Profile/AddOperatingHours/OperatingHours";
import EditBusiness from "./module/Profile/EditBusiness/EditBusiness";

import Settings from "./module/Profile/Settings";
import EditServiceCategory from "./module/Profile/EditServiceCategory/EditServiceCategory";
import EditServiceSubCategory from "./module/Profile/EditServiceSubCategory/EditServiceSubCategory";
import EditBusinessImages from "./module/Profile/EditBusinessImages/EditBusinessImages";
import EditAddress from "./module/Profile/EditAddress/EditAddress";
import EditLicenseDocuments from "./module/Profile/EditLicenseDocuments/EditLicenseDocuments";
import ContactUs from "./module/LandingPage/ContactUs";
import TieredPlans from "./module/LandingPage/TieredPlans";
import QuickBuyForm from "./module/LandingPage/QuickBuyForm";
import Contact from "./module/LandingPage/Contact";
import UploadDocuments from "./module/Profile/UploadDocuments/UploadDocuments";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { setLoading, showToast } from "./redux/slices/commonSlice";
import Loader from "./components/Loader/Loader";
import { RootState } from "./redux/rootReducer";
import { useEffect } from "react";
import { fetchProfileDetails } from "./redux/slices/userSlice";
import { AppDispatch } from "./redux/store";
import { getAddressFromCoordinates } from "./utils/getAddressFromCoordinates";
import SearchProviderWrapper from "./router/SearchProviderWrapper";

const App = () => {
  const toaster = useSelector((state: RootState) => state.common.toaster);
  const loader = useSelector((state: RootState) => state.common.loader);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.error("Error getting location", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  const closeToast = () => {
    setTimeout(() => {
      dispatch(showToast(null));
    }, 500);
  };

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  useEffect(() => {
    if (
      location.pathname === routePaths.signIn &&
      localStorage.getItem("accessToken")
    ) {
      if (localStorage.getItem("isProfileCompletion") === "true") {
        navigate(routePaths.dashboard);
      } else {
        navigate(routePaths.personalDetails);
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      dispatch(fetchProfileDetails());
    }
  }, [dispatch, localStorage.getItem("accessToken")]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(toaster)}
        autoHideDuration={4000}
        onClose={closeToast}
      >
        <Alert onClose={closeToast} severity={toaster?.color || "info"}>
          {toaster?.msg}
        </Alert>
      </Snackbar>
      {loader && <Loader />}
      <Routes>
        <Route
          path={routePaths.serviceproviders}
          element={<ServiceProvider />}
        />
        <Route path={routePaths.aboutUs} element={<AboutUs />} />
        <Route path={routePaths.dashboard} element={<DashboardPage />} />
        <Route path={routePaths.settings} element={<Settings />} />
        <Route path={routePaths.availability} element={<Availability />} />
        <Route element={<SearchProviderWrapper />}>
          <Route
            path={routePaths.expertServiceProvider}
            element={<ExpertServiceProvider />}
          />
          <Route
            path={routePaths.detailsServiceproviders}
            element={<DetailsServiceProvider />}
          />
        </Route>
        <Route path={routePaths.quickBuy} element={<QuickBuy />} />
        <Route path={routePaths.signIn} element={<SingIn />} />
        <Route
          path={routePaths.personalDetails}
          element={<PersonalDetails />}
        />
        <Route path={routePaths.uploadProfile} element={<UpdateProfile />} />
        <Route path={routePaths.addBusiness} element={<AddBusiness />} />
        <Route
          path={routePaths.selectServiceCategory}
          element={<SelectServiceCategory />}
        />
        <Route
          path={routePaths.selectServiceSubCategory}
          element={<SelectServiceSubCategory />}
        />
        <Route
          path={routePaths.addBusinessDetails}
          element={<AddBusinessDetails />}
        />
        <Route
          path={routePaths.addBusinessImages}
          element={<AddBusinessImages />}
        />
        <Route
          path={routePaths.addOperatingHours}
          element={<AddOperatingHours />}
        />
        <Route path={routePaths.operatingHours} element={<OperatingHours />} />
        <Route
          path={routePaths.uploadDocuments}
          element={<UploadDocuments />}
        />
        <Route path={routePaths.editBusiness} element={<EditBusiness />} />
        <Route
          path={routePaths.editServiceCategory}
          element={<EditServiceCategory />}
        />
        <Route
          path={routePaths.editServiceSubCategory}
          element={<EditServiceSubCategory />}
        />
        <Route
          path={routePaths.editBusinessImages}
          element={<EditBusinessImages />}
        />
        <Route path={routePaths.editAddress} element={<EditAddress />} />
        <Route
          path={routePaths.editLicenseDocuments}
          element={<EditLicenseDocuments />}
        />
        <Route path={routePaths.default} element={<Consumer />} />
        <Route
          path={routePaths.termsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route path={routePaths.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={routePaths.contactUs} element={<ContactUs />} />
        <Route path={routePaths.contact} element={<Contact />} />
        <Route path={routePaths.tieredPlans} element={<TieredPlans />} />
        <Route path={routePaths.quickBuyForm} element={<QuickBuyForm />} />
        <Route path="*" element={<Consumer />} />
      </Routes>
    </>
  );
};

export default App;
