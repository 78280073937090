import { Box, Container, Typography, Grid2 } from "@mui/material";
import { styles } from "./style";
import testimonialBgColor from "../../../../../assets/AboutUs/testimonialBgColor.png";
import flexibility from "../../../../../assets/AboutUs/flexibility.svg";
import convenience from "../../../../../assets/Images/convenience.svg";
import expertise from "../../../../../assets/AboutUs/expertise.svg";
import whyChooseVectorImage from "../../../../../assets/AboutUs/whyChooseVectorImage.png";

const WhyChoose = () => {
  return (
    <Box sx={styles.outerBox(testimonialBgColor)}>
      <Container sx={styles.container} maxWidth="xl">
        <Typography variant="h3" sx={styles.titleTypography}>
          Why <span style={styles.highlightedText}>Choose Us?</span>
        </Typography>
        <Typography variant="body1" sx={styles.descriptionTypography}>
          Expert Guidance, Tailored Solutions, and Trusted Professionals - Your
          Complete Real Estate Resource
        </Typography>

        <Grid2
          container
          spacing={{ xs: 0, md: 4, lg: 6 }}
          sx={styles.gridContainer}
        >
          {/* Flexibility Card */}
          <Grid2 size={{ xs: 12, sm: 12, md: 4 }} sx={styles.gridItem}>
            <Box sx={styles.flexibilityCardBox}>
              <Box sx={styles.cardIconContainer("#9f2f2f")}>
                <img src={flexibility} alt="Flexibility Icon" />
              </Box>
              <Typography variant="body1" sx={styles.cardTitle}>
                Flexibility
              </Typography>
              <Box sx={styles.cardDivider}></Box>
              <Typography variant="body1" sx={styles.cardDescription}>
                We offer flexible, tailored solutions to meet your unique needs,
                from selling your home to accessing top professionals.
              </Typography>
            </Box>
          </Grid2>

          {/* Convenience Card */}
          <Grid2 size={{ xs: 12, sm: 12, md: 4 }} sx={styles.gridItem}>
            <Box sx={styles.convenienceCardBox}>
              <Box sx={styles.cardIconContainer("#2e2c9f")}>
                <img src={convenience} alt="Convenience" />
              </Box>
              <Typography variant="body1" sx={styles.cardTitle}>
                Convenience
              </Typography>
              <Box sx={styles.cardDivider}></Box>
              <Typography variant="body1" sx={styles.cardDescription}>
                Experience unmatched convenience with our one-stop service,
                providing everything from expert real estate guidance to a
                network of trusted professionals - all in one place.
              </Typography>
            </Box>
          </Grid2>

          {/* Expertise Card */}
          <Grid2 size={{ xs: 12, sm: 12, md: 4 }} sx={styles.gridItem}>
            <Box sx={styles.expertieseCardBox}>
              <Box sx={styles.cardIconContainer("#41bf82")}>
                <img src={expertise} alt="Expertise Icon" />
              </Box>
              <Typography variant="body1" sx={styles.cardTitle}>
                Expertise
              </Typography>
              <Box sx={styles.cardDivider}></Box>
              <Typography variant="body1" sx={styles.cardDescription}>
                Rely on our exceptional expertise, backed by top-rated agents
                and a trusted network of professionals, ensuring you make
                informed decisions at every step.
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Container>
      <Box
        component="img"
        src={whyChooseVectorImage}
        sx={styles.floatingImage}
      ></Box>
    </Box>
  );
};

export default WhyChoose;
