import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid2,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import styles from "../style";
import { routePaths } from "../../../../../router/routePaths";
import QuickBuyLockGuaranteedSaleImage from "../../../../../assets/QuickBuy/quickBuyLockGuaranteedSaleImage.jpg";
import QuickBuyLockGuaranteedSaleIcon from "../../../../../assets/QuickBuy/quickBuyLockGuaranteedSaleIcon.png";
import QuickBuyVectorImage from "../../../../../assets/QuickBuy/quickBuyVectorImage.png";

const QuickBuyLockGuranteedSale = () => {
  const [
    isLoadingQuickBuyLockGuaranteedSaleImage,
    setIsLoadingQuickBuyLockGuaranteedSaleImage,
  ] = useState(true);
  return (
    <Box sx={styles.quickBuyLockGuaranteedSaleWrapper}>
      <Container maxWidth="xl">
        <Box>
          <Box sx={styles.quickBuyLockGuaranteedSaleIconBoxWrapper}>
            <Box
              component="img"
              src={QuickBuyLockGuaranteedSaleIcon}
              sx={styles.quickBuyLockGuaranteedSaleIconImage}
              alt="quickBuySaleIcon"
            ></Box>
            <Typography
              variant="h3"
              sx={styles.quickBuyLockGuaranteedSaleHeading}
            >
              {`QuickBuy® Lock Guaranteed Sale`}
            </Typography>
          </Box>
          <Grid2 spacing={{ md: 4 }} container sx={styles.flexDirection}>
            <Grid2 size={{ xs: 12, md: 5, lg: 6 }} sx={styles.wrapper}>
              <Box sx={styles.width}>
                <Typography variant="h3" sx={styles.quickBuyContent}>
                  {` Now you can list your house with more confidence. Enjoy the
                  benefits of the traditional home sale and view multiple
                  offers, keeping the assurance of our QuickBuy® Immediate
                  Offer in hand for 150 days. The offer can be accepted at any
                  time.`}
                </Typography>
                <Typography
                  variant="h3"
                  sx={styles.quickBuyLeasebackSubHeading}
                >
                  {`Is QuickBuy® Lock Guaranteed Sale the right fit for you?`}
                </Typography>
                <List>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    {` We won't take it personally if you take 150 days to see what
                    other offers are out there.`}
                  </ListItem>
                  <ListItem sx={styles.firstListItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    The safety net of the QuickBuy® offer empowers you to take
                    the time you need to sell your home.
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    {` You've got nothing to lose by listing your home on the
                    market, because you have an offer to fall back on.`}
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    Knowing you can sell at any time gives you complete control
                    over your next steps.
                  </ListItem>
                </List>
              </Box>
              <NavLink to={routePaths.quickBuyForm}>
                <Button sx={styles.getMyOfferButton}>Get My Offer</Button>
              </NavLink>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 7, lg: 6 }}>
              <Box sx={styles.relative}>
                <Box
                  component="img"
                  sx={styles.quickBuyImage}
                  onLoad={() =>
                    setIsLoadingQuickBuyLockGuaranteedSaleImage(false)
                  }
                  src={QuickBuyLockGuaranteedSaleImage}
                ></Box>
                {isLoadingQuickBuyLockGuaranteedSaleImage && (
                  <CircularProgress
                    size={40}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
      <Box sx={styles.quickBuyVectorImage}>
        <img src={QuickBuyVectorImage} alt="quickBuyVector" />
      </Box>
    </Box>
  );
};

export default QuickBuyLockGuranteedSale;
