import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import styles from "./style";
import { Autocomplete as GoogleAutocomplete } from "@react-google-maps/api";
import closeIcon from "../../../../assets/Common/closeIcon.svg";
import searchCloseIcon from "../../../../assets/Common/searchCloseIcon.svg";
import locationIcon from "../../../../assets/ExpertServiceProvider/locationIcon.svg";
import { useContext, useRef } from "react";
import { SearchContext } from "../../../../context/SearchContext";
import { getAddressFromCoordinates } from "../../../../utils/getAddressFromCoordinates";

interface LocationModalProps {
  locationModal: boolean;
  setLocationModal: (value: boolean) => void;
}

export interface Component {
  long_name: string;
  short_name: string;
  types: string[];
}

const LocationModal = ({
  locationModal,
  setLocationModal,
}: LocationModalProps) => {
  const searchContext = useContext(SearchContext);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.formatted_address) {
        const addressComponents = place.address_components;
        const formattedAddress = place.formatted_address;
        const zipCodeComponent = addressComponents?.find((comp: Component) =>
          comp.types.includes("postal_code")
        );
        const zipCode = zipCodeComponent?.long_name || "";
        updateFilter("searchedAddress", formattedAddress || "");
        updateFilter("zipCode", zipCode);
        updateData("serviceProviders", []);
        updateData("totalRecordsCount", 0);
        updateData("page", 1);
        handleClickLocationModalClose();
      }
    }
  };

  const handleClickLocationModalClose = () => {
    updateFilter(
      "location",
      (autocompleteRef &&
        autocompleteRef.current?.getPlace()?.formatted_address) ||
        ""
    );
    updateFilter("searchedAddress", "");
    setLocationModal(false);
  };

  const handleCloseIconLocationModalClose = () => {
    updateFilter("searchedAddress", "");
    setLocationModal(false);
  };

  const handleClickUserCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude,
            updateFilter
          );
        },
        (error) => {
          console.error("Error getting location", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
      updateData("serviceProviders", []);
      updateData("totalRecordsCount", 0);
    }
    setLocationModal(false);
  };

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }

  const { filters, updateData, updateFilter } = searchContext;
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={locationModal}
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
        Select Location
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseIconLocationModalClose}
        sx={styles.closeButton}
      >
        <img src={closeIcon} alt="closeIcon" />
      </IconButton>
      <DialogContent>
        <Box sx={styles.dialogContentBox}>
          <Box className="ZipCodeSearch">
            <GoogleAutocomplete
              onLoad={(autocomplete) => {
                autocompleteRef.current = autocomplete;
              }}
              onPlaceChanged={() => {
                handlePlaceChanged();
              }}
              options={{
                types: ["geocode"],
                // componentRestrictions: { country: 'us' }
              }}
            >
              <TextField
                className="formControl"
                placeholder="Search for your Zip Code"
                value={filters.searchedAddress}
                onChange={(e) =>
                  updateFilter("searchedAddress", e.target.value)
                }
                sx={styles.TextField}
              />
            </GoogleAutocomplete>
            {filters.searchedAddress && (
              <Button sx={styles.searchIcon}>
                <Box
                  sx={styles.popupSearchIcon}
                  onClick={() => {
                    updateFilter("searchedAddress", "");
                  }}
                >
                  <img src={searchCloseIcon} alt="Search Close Icon" />
                </Box>
              </Button>
            )}
          </Box>
          <Box
            sx={styles.currentLocationBox}
            onClick={handleClickUserCurrentLocation}
          >
            <img src={locationIcon} alt="locationIcon" />
            <Box sx={styles.currentLocationText}>Use Current Location</Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LocationModal;
