import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "../FormElements/CustomTextField";
import Search from "../../assets/Icons/Search.svg";
import TextError from "../TextError/TextError";
import styles from "../../module/Profile/SelectServiceSubCategory/style";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
export interface Categories {
  categoryName: string;
  subcategories: SubCategory[];
}

export interface SubCategory {
  _id: string;
  subCategoryName: string;
  displayImage?: DisplayImage;
}

interface DisplayImage {
  url: string;
  key: string;
}

export interface Category {
  _id: string;
  categoryName: string;
  displayImage?: DisplayImage;
  subcategories: SubCategory[];
}

export interface ResponseCategories {
  data: Category[];
}
interface AddSubcatProp {
  searchSubcatText: string;
  selectedSubCategories: string[];
  staticSelectedSubCategories: string[];
  categories: Categories[];
  setSelectedSubCategories: React.Dispatch<React.SetStateAction<string[]>>;
  setStaticSelectedSubCategories: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  setSearchSubcatText: React.Dispatch<React.SetStateAction<string>>;
}
const AddSubcatComp: React.FC<AddSubcatProp> = ({
  searchSubcatText,
  selectedSubCategories,
  staticSelectedSubCategories,
  categories,
  setSelectedSubCategories,
  setStaticSelectedSubCategories,
  setSearchSubcatText,
}) => {
  const loader = useSelector((state: RootState) => state.common.loader);

  const handleSelectServiceCategories = (id: string) => {
    if (selectedSubCategories.includes(id)) {
      setSelectedSubCategories(
        selectedSubCategories.filter((item) => item !== id)
      );
      setStaticSelectedSubCategories(
        staticSelectedSubCategories.filter((item) => item !== id)
      );
    } else {
      setSelectedSubCategories([...selectedSubCategories, id]);
      setStaticSelectedSubCategories([...staticSelectedSubCategories, id]);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchSubcatText(e.target.value);
  };
  return (
    <Box sx={styles.innerContainer}>
      <form>
        <Box>
          <CustomTextField
            value={searchSubcatText}
            onChange={handleSearch}
            placeholder="Search sub categories"
            type={"text"}
            name="fullName"
            endAdornment={Search}
            profile={true}
            allowOnlyAlphabets={true}
            maxChar={32}
          />
        </Box>
      </form>

      <Box sx={styles.categoriesContainer}>
        {categories?.length === 0 && !loader && searchSubcatText && (
          <TextError msg="No items matches your search." />
        )}
        {categories?.map((category, index) => (
          <>
            {category?.subcategories?.length > 0 && (
              <Typography
                sx={{
                  ...styles.categoryName,
                  mt: index === 0 ? 0 : 2,
                }}
              >
                {category?.categoryName}
              </Typography>
            )}
            <Grid2 container spacing={2}>
              {category?.subcategories?.map((subCat: SubCategory) => {
                return (
                  <Grid2 key={subCat?._id}>
                    <Box
                      sx={{
                        ...styles.subcategoriesGrid,
                        border: `${selectedSubCategories.includes(subCat?._id) ? "1px solid #1CB469" : "1px solid #8989A3"}`,
                        background: `${selectedSubCategories.includes(subCat?._id) ? "#1CB469" : ""}`,
                      }}
                      onClick={() => handleSelectServiceCategories(subCat?._id)}
                    >
                      <Typography
                        sx={{
                          ...styles.subCatName,
                          color: `${selectedSubCategories.includes(subCat?._id) ? "white" : "#8989A3"}`,
                        }}
                      >
                        {subCat?.subCategoryName}
                      </Typography>
                    </Box>
                  </Grid2>
                );
              })}
            </Grid2>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default AddSubcatComp;
