import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import CustomTimePicker from "./CustomTimePicker";
import { Dayjs } from "dayjs";
import addMoreSlots from "../../assets/Icons/addMoreSlots.svg";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/slices/commonSlice";
import { timeRangeSelectorStyles } from "./style"; // Import styles

type MonthDate = Dayjs | null | string;
interface TimeRangeSelectorProps {
  timeSlots: {
    startTime: Dayjs | null;
    endTime: Dayjs | null;
    monthDate?: MonthDate;
    _id?: string | null;
  }[];
  setTimeSlots: React.Dispatch<
    React.SetStateAction<
      {
        startTime: Dayjs | null;
        endTime: Dayjs | null;
        monthDate?: MonthDate;
        _id?: string | null;
      }[]
    >
  >;
  removedSlots: { startTime: Dayjs | null; endTime: Dayjs | null }[]; // Track removed slots
  setRemovedSlots: React.Dispatch<
    React.SetStateAction<{ startTime: Dayjs | null; endTime: Dayjs | null }[]>
  >;
  selectedDate: Dayjs | null | string | undefined;
}

const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  timeSlots,
  setTimeSlots,
  selectedDate,
}) => {
  const dispatch = useDispatch();
  const lastSlotRef = useRef<HTMLDivElement | null>(null);

  const isOverlapping = (
    newStartTime: Dayjs | null,
    newEndTime: Dayjs | null,
    index: number
  ) => {
    return timeSlots.some((slot, i): boolean | null => {
      if (i === index || !slot.startTime || (newEndTime && !slot.endTime))
        return false;
      return (
        (newStartTime &&
          (newStartTime.isSame(slot.startTime) ||
            newStartTime.isAfter(slot.startTime)) &&
          newStartTime.isBefore(slot.endTime)) ||
        (newEndTime &&
          newEndTime.isAfter(slot.startTime) &&
          (newEndTime.isSame(slot.endTime) ||
            newEndTime.isBefore(slot.endTime))) ||
        (newStartTime &&
          newEndTime &&
          newStartTime.isBefore(slot.startTime) &&
          newEndTime.isAfter(slot.endTime))
      );
    });
  };

  const handleStartTimeChange = (index: number, time: Dayjs | null): void => {
    const updatedSlots = [...timeSlots];

    if (
      time &&
      updatedSlots[index].endTime &&
      time.isAfter(updatedSlots[index].endTime)
    ) {
      dispatch(
        showToast({
          color: "error",
          msg: "Start time cannot be greater than end time",
        })
      );
      return;
    }
    if (
      time &&
      updatedSlots[index].endTime &&
      time.isSame(updatedSlots[index].endTime)
    ) {
      dispatch(
        showToast({
          color: "error",
          msg: "Start time cannot be equal to end time",
        })
      );
      return;
    }

    if (time && isOverlapping(time, updatedSlots[index].endTime, index)) {
      dispatch(
        showToast({
          color: "error",
          msg: "Selected time slot overlaps with another slot",
        })
      );
      return;
    }

    updatedSlots[index].startTime = time;
    if (selectedDate) {
      updatedSlots[index].monthDate = selectedDate; // Associate the time slot with the selected date
    }
    setTimeSlots(updatedSlots);
  };

  const handleEndTimeChange = (index: number, time: Dayjs | null): void => {
    const updatedSlots = [...timeSlots];
    if (!updatedSlots[index].startTime) return;

    if (
      time &&
      updatedSlots[index].startTime &&
      time.isBefore(updatedSlots[index].startTime)
    ) {
      dispatch(
        showToast({
          color: "error",
          msg: "End time cannot be earlier than start time",
        })
      );
      return;
    }
    if (
      time &&
      updatedSlots[index].startTime &&
      time.isSame(updatedSlots[index].startTime)
    ) {
      dispatch(
        showToast({
          color: "error",
          msg: "End time cannot be equal to start time",
        })
      );
      return;
    }

    if (
      time &&
      updatedSlots[index].startTime &&
      isOverlapping(updatedSlots[index].startTime, time, index)
    ) {
      dispatch(
        showToast({
          color: "error",
          msg: "Selected time slot overlaps with another slot",
        })
      );
      return;
    }
    updatedSlots[index].endTime = time;
    setTimeSlots(updatedSlots);
  };

  // to add an empty start and end slot
  const handleAddSlot = (): void => {
    if (lastSlotRef.current) {
      lastSlotRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const hasEmptySlot = timeSlots.some(
      (slot) => !slot.startTime || !slot.endTime
    );
    if (hasEmptySlot) {
      dispatch(
        showToast({
          color: "error",
          msg: "Please fill in all existing time slots before adding more.",
        })
      );
      return;
    }
    setTimeSlots([
      ...timeSlots,
      { startTime: null, endTime: null, monthDate: null },
    ]);
  };

  return (
    <Box sx={timeRangeSelectorStyles.container}>
      <Box sx={timeRangeSelectorStyles.slotsContainer}>
        {timeSlots.map((slot, index) => (
          <Box
            key={slot._id}
            sx={timeRangeSelectorStyles.slot}
            ref={index === timeSlots.length - 1 ? lastSlotRef : null}
          >
            <CustomTimePicker
              placeholderText="Start Time"
              selectedTime={slot.startTime}
              onTimeChange={(time) => handleStartTimeChange(index, time)}
            />
            <CustomTimePicker
              placeholderText="End Time"
              selectedTime={slot.endTime}
              onTimeChange={(time) => handleEndTimeChange(index, time)}
              minTime={slot.startTime} // Ensure end time is after start time
            />
          </Box>
        ))}
      </Box>
      <Box sx={timeRangeSelectorStyles.addMoreContainer}>
        <img
          src={addMoreSlots}
          alt="add"
          style={{ cursor: "pointer" }}
          onClick={handleAddSlot}
        />
        <Typography sx={timeRangeSelectorStyles.addMoreText}>
          Add more Slots
        </Typography>
      </Box>
    </Box>
  );
};

export default TimeRangeSelector;
