import { useState } from "react";
import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { getStyles } from "./style";

import MenuCloseIcon from "../../assets/Icons/menuCloseIcon.svg";
import headerLogo from "../../assets/Common/home-seller-savings-logo.png";
import headerLogoHomeSeller from "../../assets/Common/header_logo_home_seller.png";
import menuIcon from "../../assets/Common/menu_icon.svg";
import { routePaths } from "../../router/routePaths";

interface HeaderProps {
  HeaderRef?: React.RefObject<HTMLDivElement>;
}

const Header: React.FC<HeaderProps> = ({ HeaderRef }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const styles = getStyles(menuVisible);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <Box ref={HeaderRef} sx={styles.box}>
        <Container maxWidth="xl">
          <Box sx={styles.toggleMenu}>
            <Box>
              <NavLink to="/">
                <img src={headerLogo} alt="Header Logo" width="70%" />
              </NavLink>
            </Box>
            <Box onClick={toggleMenu} sx={styles.toggleMenuIcon}>
              <img src={menuIcon} alt="Menu Icon" />
            </Box>
          </Box>

          <List className="headerMenu" sx={styles.menuList}>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.logoListItemText}>
                <NavLink to="/">
                  <Link href="#">
                    <Box
                      component="img"
                      sx={styles.headerLogo}
                      src={headerLogoHomeSeller}
                      alt="Header Logo"
                    ></Box>
                  </Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to={routePaths.expertServiceProvider}
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Service Providers</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to={routePaths.aboutUs}
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>About Us</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to={routePaths.contact}
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Contact Us</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <NavLink to={routePaths.serviceproviders}>
              <ListItem sx={styles.primaryButton}>
                <ListItemText sx={styles.primaryButtonLinkText}>
                  <Link href="#" sx={styles.primaryButtonText}>
                    Become a Service Provider
                  </Link>
                </ListItemText>
              </ListItem>
            </NavLink>
            <ListItem sx={styles.menucloseIcon}>
              <Box
                onClick={toggleMenu}
                component="img"
                src={MenuCloseIcon}
                alt="Menu Close Icon"
              ></Box>
            </ListItem>
          </List>
        </Container>
      </Box>
    </>
  );
};

export default Header;
