import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/Icons/backIcon.svg";
import contactCallIcon from "../../../assets/Icons/contactCallIcon.svg";
import contactGmailIcon from "../../../assets/Icons/contactGmailIcon.svg";
import contactWebIcon from "../../../assets/Icons/contactWebIcon.svg";
import styles from "./style";

const ContactUs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.contentBox}>
          <Box sx={styles.backButtonBox}>
            <Box onClick={() => navigate(-1)}>
              <img src={backIcon} alt="backArrowIcon" />
            </Box>
          </Box>
          <Typography variant="body1" sx={styles.title}>
            {`Let’s stay connected`}
          </Typography>
          <Typography variant="body1" sx={styles.subtitle}>
            {`It's very easier to get in touch with`}{" "}
            <span
              style={{
                color: "#06038D",
                fontWeight: "700",
              }}
            >
              Home Seller Savings,LLC
            </span>{" "}
            <Box sx={styles.hiddenBox}>
              <br />
            </Box>
            {`Email us your query and we'll get back to you as soon as possible!`}
          </Typography>
          <Box sx={styles.contactBox}>
            <Typography variant="body1" sx={styles.contactTitle}>
              Contact Us
            </Typography>
            <Box sx={styles.contactItemBox}>
              <Box sx={styles.contactItem}>
                <Box sx={styles.contactIconBox}>
                  <Box
                    component="img"
                    width="25px"
                    src={contactGmailIcon}
                    alt="Contact Gmail Icon"
                  ></Box>
                </Box>
                <Link
                  sx={styles.contactLink}
                  href="mailto:homesellerinfo@gmail.com"
                >
                  homesellerinfo@gmail.com
                </Link>
              </Box>
              <Box sx={styles.contactItem}>
                <Box sx={styles.contactIconBox}>
                  <Box
                    component="img"
                    width="30px"
                    src={contactCallIcon}
                    alt="Contact Gmail Icon"
                  ></Box>
                </Box>
                <Link sx={styles.contactLink} href="tel:800-234-567">
                  800-234-567
                </Link>
              </Box>
              <Box sx={styles.contactItem}>
                <Box sx={styles.contactIconBox}>
                  <Box
                    component="img"
                    width="26px"
                    src={contactWebIcon}
                    alt="Contact Gmail Icon"
                  ></Box>
                </Box>
                <Link sx={styles.contactLink} href="www.homesellerinfo.help">
                  www.homesellerinfo.help
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
