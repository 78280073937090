import { Paper, Box, Grid2, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import styles from "./style";
import { routePaths } from "../../../../router/routePaths";
import noResultSearchImage from "../../../../assets/ExpertServiceProvider/No_Results_View_Search.svg";

const NoResultsView = () => {
  return (
    <Grid2 size={{ md: 10, xs: 12 }} sx={styles.noDataContainer}>
      <Paper
        sx={{
          background: "transparent",
          boxShadow: "none",
        }}
        elevation={2}
      >
        <Box>
          <Box
            component="img"
            src={noResultSearchImage}
            sx={styles.noResultImage}
          ></Box>
          <Typography variant="h5" sx={styles.heading}>
            No Service Providers Available
          </Typography>
          <Box sx={styles.noDataTextContainer}>
            <Typography variant="body1" sx={styles.text}>
              Currently no service provider is available in your area.{" "}
              <Box sx={styles.breakText}>
                <br />
              </Box>
              If you need more information please feel free to click on{" "}
              <Box component="span" sx={styles.blueColor}>
                {"‘"}Get Help{"’"}
              </Box>{" "}
              button below
            </Typography>
          </Box>
          <NavLink to={routePaths.contact}>
            <Button sx={styles.noDataButton}>Get Help</Button>
          </NavLink>
        </Box>
      </Paper>
    </Grid2>
  );
};

export default NoResultsView;
