export const validateField = (name: string, value: string): string => {
  switch (name) {
    case "fullName":
      return value.length >= 1 && value.length < 3
        ? "Full name must be between 3 and 40 characters long."
        : "";
    case "phoneNumber":
      return value.length >= 1 && value.length < 10
        ? "Phone number must start with +1 and be 7-15 digits long."
        : "";

    case "email": {
      const isValidEmail =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      return !isValidEmail && value.length >= 1
        ? "Please enter a valid email format."
        : "";
    }

    case "zipCode":
      return value.length >= 1 && value.length < 5
        ? "Zip code must be between 5 and 9 digits long."
        : "";

    case "address":
      return value.length >= 1 && value.length < 5
        ? "Address must be between 5 and 100 characters long"
        : "";

    case "city":
      return value.length >= 1 && value.length < 3
        ? "City name must be between 3 and 32 characters long."
        : "";

    case "state":
      return value.length >= 1 && value.length < 3
        ? "State name must be between least 3 and 32 characters long."
        : "";

    case "message":
      return value.length >= 1 && value.length < 5
        ? "Message should be between 5 and 1000 characters long."
        : "";
    case "subject":
      return value.length >= 1 && value.length < 3
        ? "Subject must be between 3 and 100 characters long."
        : "";
    case "businessTitle":
      return value.length >= 1 && value.length < 3
        ? "Title must be between 3 to 40 characters long."
        : "";
    case "description":
      return value.length >= 1 && value.length < 5
        ? "Description must be between 5 to 1000 characters long."
        : "";
    case "province":
      return value.length >= 1 && value.length < 3
        ? "Province name must be between 3 to 32 characters long."
        : "";

    default:
      return "";
  }
};
