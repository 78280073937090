import { Autocomplete, InputAdornment, TextField, Box } from "@mui/material";
import styles from "./style";
import dropdownArrow from "../../../../assets/ExpertServiceProvider/dropdownArrow.svg";
import searchIcon from "../../../../assets/ExpertServiceProvider/searchIcon.svg";
import { useContext, useState } from "react";
import { SearchContext } from "../../../../context/SearchContext";

interface Service {
  _id: string;
  name: string;
}

const SelectServiceDropdown = () => {
  const searchContext = useContext(SearchContext);
  const [isOpen, setIsOpen] = useState(false);

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }
  const { data, filters, updateFilter, updateData } = searchContext;
  return (
    <Autocomplete
      fullWidth
      sx={styles.autocomplete}
      className="selectDropdown search"
      options={data.services}
      getOptionLabel={(option) => option.name}
      onChange={(event: React.ChangeEvent<{}>, newValue: Service | null) => {
        updateFilter("selectedService", newValue);
        updateFilter("selectedCategory", "");
        updateData("serviceProviders", []);
        updateData("isSearchClicked", false);
        updateData("totalRecordsCount", 0);
        updateData("page", 1);
      }}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search Service"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  src={searchIcon}
                  alt="search icon"
                  component="img"
                  sx={{
                    width: {
                      xl: "auto",
                      xs: "24px",
                    },
                  }}
                ></Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setIsOpen((prev) => !prev)}
                sx={{ cursor: "pointer" }}
              >
                <img src={dropdownArrow} alt="dropdown icon" />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          {option.name}
        </li>
      )}
      value={filters.selectedService}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
    />
  );
};
export default SelectServiceDropdown;
