import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import successStausImage from "../../assets/Common/sucessStatusImage.png";

const SuccessForm = () => {
  return (
    <>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <img src={successStausImage} alt="Success Status" />
        </Box>
        <Typography
          variant="h3"
          sx={{
            fontSize: { lg: "24px", xs: "20px" },
            color: "#0D0639",
            fontWeight: "700",
            fontFamily: "Montserrat, sans-serif",
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          Inquiry Submitted Successfully
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: { lg: "20px", xs: "16px" },
            color: "#8989A3",
            fontWeight: "400",
            fontFamily: "Montserrat, sans-serif",
            textAlign: "center",
            lineHeight: "24px",
            paddingTop: "20px",
          }}
        >
          Details Submitted Successfully <br /> You will receive a call from the
          team to continue
        </Typography>
        <Box sx={{ textAlign: "center", marginTop: "40px" }} className="button">
          <NavLink to="/">
            <Button
              sx={{
                background: "#06038D",
                padding: "13px 50px",
                borderRadius: "12px",
                fontSize: { lg: "18px", xs: "16px" },
                boxShadow: "4px 8px 24px 0px #3F8CFF40",
                color: "#fff",
                fontWeight: "600",
                textDecoration: "none",
                fontFamily: "Montserrat, sans-serif",
                textTransform: "capitalize",
                "&:disabled": {
                  background: "#B3B3CF",
                  color: "#fff",
                  boxShadow: "none",
                },
              }}
            >
              Done
            </Button>
          </NavLink>
        </Box>
      </Box>
    </>
  );
};

export default SuccessForm;
