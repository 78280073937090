export const styles = {
  servicesBox: {
    background: "linear-gradient(180deg, #D8EDE3 5.04%, #FFFFFF 103.28%)",
    padding: {
      lg: "40px 0",
      xs: "20px 0 0",
    },
  },
  container: {
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  servicesTitle: {
    fontSize: {
      lg: "40px",
      xs: "22px",
    },
    paddingTop: "14px",
    color: "#0D0639",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    paddingBottom: "20px",
    textAlign: "center",
  },
  servicesSubtitle: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#8989A3",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: "20px",
    width: {
      lg: "70%",
      xs: "100%",
    },
    margin: {
      md: "0 auto 50px",
      xs: "0 auto 30px",
    },
  },
  gridContainer: {
    spacing: {
      xs: 4,
      md: 6,
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: {
      lg: "flex-end",
      xs: "center",
    },
    alignItems: "center",
  },
  relativeBox: {
    position: "relative",
    marginTop: {
      sm: "30px",
    },
  },
  absoluteBox: {
    position: {
      sm: "absolute",
      xs: "unset",
    },
    display: {
      sm: "block",
      xs: "none",
    },
    bottom: "-50px",
    left: "-60px",
  },
  serviceIconBox: {
    border: "1px solid #41C485",
    boxShadow: "5px 15px 60px 0px #1B212F1A",
    background: "#fff",
    borderRadius: "20px",
    padding: "14px 35px 14px 14px",
    position: {
      sm: "absolute",
      xs: "unset",
    },
    top: "-40px",
    marginBottom: {
      sm: "0",
      xs: "20px",
    },
    left: {
      lg: "-120px",
      md: "0",
    },
    width: {
      sm: "max-content",
      xs: "auto",
    },
    zIndex: "99",
  },
  serviceIconText: {
    color: "#1B212F",
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      lg: "20px",
      xs: "16px",
    },
    fontWeight: "600",
    paddingLeft: "20px",
  },
  flexibleBox: {
    border: "1px solid #F14D5D",
    boxShadow: "5px 15px 60px 0px #1B212F1A",
    background: "#fff",
    borderRadius: "20px",
    padding: "14px 35px 14px 14px",
    position: {
      sm: "absolute",
      xs: "unset",
    },
    top: "50%",
    marginBottom: {
      sm: "0",
      xs: "20px",
    },
    left: {
      xl: "-170px",
      lg: "-120px",
      md: "0",
    },
    transform: {
      sm: "translateY(-50%)",
      xs: "unset",
    },
    width: {
      sm: "max-content",
      xs: "auto",
    },
    zIndex: "99",
  },
  profitableBox: {
    boxShadow: "5px 15px 60px 0px #1B212F1A",
    background: "#fff",
    borderRadius: "20px",
    padding: "14px 35px 14px 14px",
    width: {
      sm: "max-content",
      xs: "auto",
    },
    position: {
      sm: "absolute",
      xs: "unset",
    },
    marginBottom: {
      sm: "0",
      xs: "20px",
    },
    bottom: "-30px",
    right: "20px",
    zIndex: "99",
    border: "1px solid #8E66FF",
  },
  serviceImage: {
    zIndex: "9",
    position: "relative",
    width: {
      lg: "572px",
      xs: "100%",
    },
  },
  loadingBox: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "99",
  },
  accordionGridItem: {
    padding: {
      lg: "50px 0",
      xs: "0 0 20px",
    },
    marginTop: {
      sm: "30px",
    },
  },
  accordion: {
    borderLeft: `5px solid`,
    marginBottom: "20px",
    boxShadow: "none",
    padding: "10px 0",
  },
  accordionSummary: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  },
  accordionIcon: {
    marginRight: "8px",
    marginLeft: "5px",
    paddingRight: "20px",
  },
  accordionDetails: {
    color: "#202124",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Montserrat, sans-serif",
    paddingLeft: "67px",
    lineHeight: "24px",
    paddingTop: "0",
  },
};
