import { Box, InputLabel } from "@mui/material";
import { NavLink } from "react-router-dom";
import { VisuallyHiddenInput } from "../../../components/VisuallyHiddenInput/VisuallyHiddenInput";
import styles from "./style";
import { routePaths } from "../../../router/routePaths";
import editIcon from "../../../assets/Settings/editIcon.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";

const EditServiceImages = () => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  return (
    <Box className="formControl" sx={styles.formControl}>
      <Box sx={styles.serviceCategoryBox}>
        <InputLabel className="inputLable">Service Images</InputLabel>
        <Box component="label" tabIndex={-1}>
          <NavLink to={routePaths.editBusinessImages}>
            <Box
              component="img"
              src={editIcon}
              alt="editIcon"
              className="boxEditIcon"
            ></Box>
          </NavLink>
          <VisuallyHiddenInput type="file" />
        </Box>
      </Box>
      <Box sx={styles.serviceImagesList}>
        {userProfile?.serviceImages?.map((image: { url: string }) => (
          <Box
            key={image?.url}
            component="img"
            src={image.url}
            sx={styles.serviceImage}
          ></Box>
        ))}
      </Box>
    </Box>
  );
};

export default EditServiceImages;
