import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    height: "100vh",
    background: "#F5F5FA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentBox: {
    background: "#fff",
    borderRadius: "40px",
    height: "70vh",
    width: "80%",
    padding: {
      sm: "60px 0 40px",
      xs: "70px 0 40px",
    },
    position: "relative",
  },
  backButtonBox: {
    cursor: "pointer",
    position: "absolute",
    top: {
      lg: "40px",
      xs: "20px",
    },
    left: {
      lg: "40px",
      xs: "20px",
    },
  },
  title: {
    textAlign: "center",
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    fontSize: {
      lg: "28px",
      xs: "22px",
    },
    padding: "0 0 20px",
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    color: "#8989A3",
    textAlign: "center",
    fontWeight: "500",
    padding: "0 30px",
  },
  hiddenBox: {
    display: {
      md: "block",
      xs: "none",
    },
    height: "0px",
  },
  contactBox: {
    background: "#8989A34D",
    padding: {
      lg: "20px 60px",
      xs: "20px",
    },
    margin: {
      lg: "50px auto",
      xs: "20px auto",
    },
    borderRadius: "20px",
    width: {
      lg: "50%",
      md: "60%",
      sm: "60%",
      xs: "80%",
    },
  },
  contactTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      lg: "28px",
      xs: "26px",
    },
    color: "#0D0639",
    fontWeight: "600",
  },
  contactItemBox: {
    paddingTop: "20px",
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
  },
  contactIconBox: {
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50px",
    minWidth: "50px",
    background: "#231F20",
    borderRadius: "50%",
  },
  contactLink: {
    wordBreak: "break-all",
    fontSize: "18px",
    fontWeight: "500",
    color: "#0D0639",
    textDecoration: "none",
  },
};

export default styles;
