import { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/ConsumerHeader/Header";
import QuickBuyBanner from "./QuickBuyBanner/QuickBuyBanner";
import QuickBuyContent from "./QuickBuyContent/QuickBuyContent";

const QuickBuy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const role = "consumer";

  return (
    <>
      <Header />
      <QuickBuyBanner />
      <QuickBuyContent />
      <Footer role={role} />
    </>
  );
};

export default QuickBuy;
