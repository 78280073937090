import { Typography, Box, InputLabel, TextField } from "@mui/material";
import styles from "./style";
import astrikIcon from "../../../../assets/Common/astrikIcon.svg";
import { getSx } from "./helper";

interface PersonalDetailsFormProps {
  state: {
    fullName: string;
    phoneNumber: string;
    email: string;
    description: string;
    errors: {
      fullName?: string;
      phoneNumber?: string;
      email?: string;
      description?: string;
    };
    disabled: boolean;
  };
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormCount: (count: number) => void;
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({
  state,
  handleInputChange,
  handleFormCount,
}) => {
  return (
    <form onSubmit={() => handleFormCount(2)}>
      <Typography variant="h3" sx={styles.formTitle}>
        Inquiry Form
      </Typography>
      <Box sx={styles.requiredFields}>
        <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
        <Typography variant="body2" sx={styles.requiredText}>
          All the fields are required
        </Typography>
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">Full Name</InputLabel>
        <TextField
          name="fullName"
          value={state.fullName}
          onChange={handleInputChange}
          placeholder="Add First & Last Name"
          error={!!state.errors.fullName}
          helperText={state.errors.fullName}
          sx={getSx(state.errors.fullName ?? null)}
        />
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">Phone Number</InputLabel>
        <TextField
          name="phoneNumber"
          value={state.phoneNumber}
          onChange={handleInputChange}
          placeholder="Add Number"
          error={!!state.errors.phoneNumber}
          helperText={state.errors.phoneNumber}
          sx={getSx(state.errors.phoneNumber ?? null)}
        />
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">Email Address</InputLabel>
        <TextField
          name="email"
          value={state.email}
          onChange={handleInputChange}
          type="email"
          placeholder="Add Email"
          error={!!state.errors.email}
          helperText={state.errors.email}
          sx={getSx(state.errors.email ?? null)}
        />
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">Description</InputLabel>
        <TextField
          name="description"
          value={state.description}
          onChange={handleInputChange}
          placeholder="Add Description"
          multiline
          minRows={1}
          maxRows={4}
          error={!!state.errors.description}
          helperText={state.errors.description}
          sx={getSx(state.errors.description ?? null)}
        />
      </Box>
      <Box sx={styles.buttonContainer} className="button">
        <button
          className={`${state.disabled ? "disableBtn" : "activeBtn"}`}
          disabled={state.disabled}
        >
          Next
        </button>
      </Box>
    </form>
  );
};

export default PersonalDetailsForm;
