import { Box, Grid2, Typography } from "@mui/material";
import CustomTextField from "../FormElements/CustomTextField";
import styles from "../../module/Profile/SelectServiceSubCategory/style";
import Search from "../../assets/Icons/Search.svg";
import TextError from "../TextError/TextError";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

interface AddServiceProp {
  searchCategoryText: string;
  selectedCategories: string[];
  staticSelectedCategories: string[];
  services: Services[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  setStaticSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchCategoryText: React.Dispatch<React.SetStateAction<string>>;
}

interface DisplayImage {
  url: string;
  key: string;
}

interface Category {
  categoryName: string;
  displayImage: DisplayImage;
  _id: string;
}

interface Service {
  serviceName: string;
  _id: string;
  categories: Category[];
}

export interface Services {
  serviceName: string;
  categories: Category[];
}
export interface ResponseServices {
  data: Service[];
}
const AddServiceComp: React.FC<AddServiceProp> = ({
  searchCategoryText,
  selectedCategories,
  staticSelectedCategories,
  services,
  setSelectedCategories,
  setStaticSelectedCategories,
  setSearchCategoryText,
}) => {
  const loader = useSelector((state: RootState) => state.common.loader);

  const handleSelectServiceCategories = (id: string) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== id));
      setStaticSelectedCategories(
        staticSelectedCategories.filter((item) => item !== id)
      );
    } else {
      setSelectedCategories([...selectedCategories, id]);
      setStaticSelectedCategories([...staticSelectedCategories, id]);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCategoryText(e.target.value);
  };
  return (
    <Box sx={styles.innerContainer}>
      <form>
        <Box>
          <CustomTextField
            value={searchCategoryText}
            onChange={handleSearch}
            placeholder="Search Categories"
            type={"text"}
            name="fullName"
            endAdornment={Search}
            profile={true}
            allowOnlyAlphabets={true}
            maxChar={32}
          />
        </Box>
      </form>

      <Box sx={styles.categoriesContainer}>
        {services?.length === 0 && !loader && searchCategoryText && (
          <TextError msg="No items matches your search." />
        )}
        {services?.map((service, index) => (
          <>
            {service?.categories?.length > 0 && (
              <Typography
                sx={{
                  ...styles.categoryName,
                  mt: index === 0 ? 0 : 2,
                }}
              >
                {service?.serviceName}
              </Typography>
            )}
            <Grid2 container spacing={2}>
              {service?.categories?.map((category: Category) => {
                return (
                  <Grid2 key={category?._id}>
                    <Box
                      sx={{
                        ...styles.categoriesGrid,
                        border: `${selectedCategories.includes(category?._id) ? "1px solid #1CB469" : "1px solid #8989A3"}`,
                        background: `${selectedCategories.includes(category?._id) ? "#1CB469" : ""}`,
                      }}
                      onClick={() =>
                        handleSelectServiceCategories(category?._id)
                      }
                    >
                      <Box
                        sx={styles.imageStyles}
                        src={category?.displayImage?.url}
                        alt={category?.categoryName}
                        component="img"
                      ></Box>
                      <Typography
                        sx={{
                          ...styles.subCatName,
                          color: `${selectedCategories.includes(category?._id) ? "white" : "#8989A3"}`,
                        }}
                      >
                        {category?.categoryName}
                      </Typography>
                    </Box>
                  </Grid2>
                );
              })}
            </Grid2>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default AddServiceComp;
