import Header from "../../../components/ConsumerHeader/Header";
import Banner from "../../../components/Banner/Banner";
import Services from "./Services/services";
import Testimonials from "./Testimonials/testimonials";
import Faq from "./FAQ/faq";
import Footer from "../../../components/Footer/Footer";
import { useEffect } from "react";

const Consumer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const role = "consumer";
  return (
    <>
      <Header />
      <Banner />
      <Services />
      <Testimonials />
      <Faq />
      <Footer role={role} />
    </>
  );
};

export default Consumer;
