import {
  Typography,
  Box,
  InputLabel,
  TextField,
  Grid2,
  CircularProgress,
  Button,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import backIcon from "../../../assets/Icons/backIcon.svg";
import styles from "./style";
import "./EditAddress.css";
import { updateUserProfile } from "../../../network/user";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../router/routePaths";
import { validateField } from "../../../utils/validationUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { formatInput } from "../../../utils/formatUtils";
import SuccessModal from "../../../components/Modal/SuccessModal";
import { UserData } from "../../../redux/slices/userSlice";

const getSx = (val: string | null | undefined) => {
  return {
    borderColor: val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};
const EditAddress = () => {
  const [userAddressDetails, setUserAddressDetails] = useState<
    Partial<UserData>
  >({
    address: "",
    city: "",
    province: "",
    zipCode: "",
    errors: { address: "", city: "", province: "", zipCode: "" },
  });
  const [updatedAddress, setUpdatedAddress] = useState<Partial<UserData>>({});
  const [updateAddressDetailsModal, setUpdateAddressDetailsModal] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userData = useSelector((state: RootState) => state.user.userData);

  const navigate = useNavigate();

  const navigateBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (userData) {
      setUserAddressDetails({
        address: userData?.address,
        city: userData?.city,
        province: userData?.province,
        zipCode: userData?.zipCode,
        errors: { address: "", city: "", province: "", zipCode: "" },
      });
    }
  }, [userData]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === "zipCode" || name === "city" || name === "province") {
      value = formatInput(name, value);
    }
    if (name === "address") {
      value = formatInput(name, value, 100);
    }
    const errorMessage = validateField(name, value);
    setUserAddressDetails({
      ...userAddressDetails,
      [name]: value,
      errors: { ...userAddressDetails.errors, [name]: errorMessage },
    });
    setUpdatedAddress({
      ...updatedAddress,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await updateUserProfile(updatedAddress);
    if (response) {
      setIsLoading(false);
      setUpdateAddressDetailsModal(
        "Your Address details has been updated successfully!"
      );
    }
  };
  const handleClickUpdateDetailsModalClose = () => {
    setUpdateAddressDetailsModal("");
    navigate(routePaths.settings);
  };

  return (
    <Box sx={styles.editAddressPage}>
      <Box sx={styles.editAddressWrapper}>
        <Box
          src={backIcon}
          component="img"
          alt="backIcon"
          sx={styles.backIcon}
          onClick={navigateBackHandler}
        ></Box>

        <Typography sx={styles.editAddressTitle}>Edit Address</Typography>
        <Box
          sx={{
            width: {
              xl: "60%",
              margin: "auto",
            },
            paddingTop: {
              sm: "0",
              xs: "15px",
            },
          }}
        >
          <form onSubmit={handleFormSubmit}>
            <Box className="formControl">
              <InputLabel className="inputLable">Address</InputLabel>
              <TextField
                name="address"
                value={userAddressDetails?.address}
                onChange={handleInputChange}
                placeholder="Add Address"
                error={!!userAddressDetails.errors?.address}
                helperText={userAddressDetails.errors?.address}
                sx={getSx(userAddressDetails.errors?.address)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">City</InputLabel>
              <TextField
                name="city"
                value={userAddressDetails?.city}
                onChange={handleInputChange}
                placeholder="Enter City"
                error={!!userAddressDetails.errors?.city}
                helperText={userAddressDetails.errors?.city}
                sx={getSx(userAddressDetails.errors?.city)}
              />
            </Box>

            <Box>
              <Grid2 container>
                <Grid2 size={{ xs: 12, lg: 6 }}>
                  <Box
                    className="formControl"
                    sx={{
                      marginRight: {
                        lg: "5px",
                        xs: "0",
                      },
                    }}
                  >
                    <InputLabel className="inputLable">Province</InputLabel>
                    <TextField
                      name="province"
                      value={userAddressDetails?.province}
                      onChange={handleInputChange}
                      placeholder="Enter Province"
                      error={!!userAddressDetails.errors?.province}
                      helperText={userAddressDetails.errors?.province}
                      sx={getSx(userAddressDetails.errors?.province)}
                    />
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 6 }}>
                  <Box
                    className="formControl"
                    sx={{
                      marginLeft: {
                        lg: "5px",
                        xs: "0",
                      },
                    }}
                  >
                    <InputLabel className="inputLable">Zip Code</InputLabel>
                    <TextField
                      name="zipCode"
                      value={userAddressDetails?.zipCode}
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Add Zip Code"
                      error={!!userAddressDetails.errors?.zipCode}
                      helperText={userAddressDetails.errors?.zipCode}
                      sx={getSx(userAddressDetails.errors?.zipCode)}
                    />
                  </Box>
                </Grid2>
              </Grid2>
            </Box>
            {userAddressDetails.error && (
              <p className="errorText">{userAddressDetails.error}</p>
            )}
            <Box sx={styles.buttonContainer} className="button">
              {isLoading ? (
                <Button sx={styles.submitButton} type="submit" disabled={true}>
                  <CircularProgress size={40} />
                </Button>
              ) : (
                <Button
                  sx={{ textTransform: "capitalize" }}
                  type="submit"
                  disabled={
                    Boolean(userAddressDetails.errors?.address) ||
                    Boolean(userAddressDetails.errors?.city) ||
                    Boolean(userAddressDetails.errors?.province) ||
                    Boolean(userAddressDetails.errors?.zipCode) ||
                    !userAddressDetails.address ||
                    userAddressDetails.address === "" ||
                    userAddressDetails.city === "" ||
                    userAddressDetails.province === "" ||
                    userAddressDetails.zipCode === ""
                  }
                >
                  Update Address
                </Button>
              )}
            </Box>
          </form>
          {updateAddressDetailsModal && (
            <SuccessModal
              content={updateAddressDetailsModal}
              onClose={handleClickUpdateDetailsModalClose}
              onSuccess={handleClickUpdateDetailsModalClose}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditAddress;
