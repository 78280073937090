import { Box, Container, Grid2, Typography } from "@mui/material";
import styles from "./style";
import EasyDoesIt from "../../../../assets/QuickBuy/easyDoesIt.svg";
import SellWithCertainity from "../../../../assets/QuickBuy/sellWithCertainity.svg";
import SkipTheHassles from "../../../../assets/QuickBuy/skipTheHassles.svg";
import QuickBuyImmediateOffer from "./QuickBuyImmediateOffer/QuickBuyImmediateOffer";
import QuickBuyLockGuranteedSale from "./QuickBuyLockGuaranteedSale/QuickBuyLockGuranteedSale";
import QuickBuyLeackBack from "./QuickBuyLeackBack/QuickBuyLeackBack";

const QuickBuyContent = () => {
  return (
    <>
      <Box sx={styles.quickBuyContentImage}>
        <Container maxWidth="xl">
          <Typography variant="h3" sx={styles.quickBuyContentTitle}>
            QuickBuy : Makes It Easy
          </Typography>
          <Typography variant="body1" sx={styles.quickBuyContentSubTitle}>
            Offering you more options when selling your home
          </Typography>

          <Grid2
            container
            spacing={{ md: 4, lg: 6, xs: 0 }}
            sx={styles.gridContainerWrapper}
          >
            <Grid2 size={{ xs: 12, sm: 12, md: 4 }} sx={styles.gridContainer}>
              <Box sx={styles.card}>
                <Box sx={styles.cardIcon}>
                  <img src={SellWithCertainity} alt="Sell With Certainity" />
                </Box>
                <Typography variant="body1" sx={styles.cardName}>
                  Sell with Certainity
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  To remove the uncertainty, we will match you with a qualified
                  real estate agent in your area who will help you understand
                  immediate offers and other solutions.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 12, md: 4 }} sx={styles.gridContainer}>
              <Box sx={styles.skipTheHasslesCard}>
                <Box sx={styles.skipTheHasslesCardIcon}>
                  <img src={SkipTheHassles} alt="Sell With Certainity" />
                </Box>
                <Typography variant="body1" sx={styles.cardName}>
                  Skip the Hassles
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  Eliminate the time and expense of putting your home on the
                  market. No showings, open houses, stress, or worries.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 12, md: 4 }} sx={styles.gridContainer}>
              <Box sx={styles.card}>
                <Box sx={styles.cardIcon}>
                  <img src={EasyDoesIt} alt="Sell With Certainity" />
                </Box>
                <Typography variant="body1" sx={styles.cardName}>
                  Easy Does It
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  Your agent will help you choose the right QuickBuy option for
                  your needs. Buy and sell on your preferred timeline.
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      <QuickBuyImmediateOffer />
      <QuickBuyLockGuranteedSale />
      <QuickBuyLeackBack />
    </>
  );
};

export default QuickBuyContent;
