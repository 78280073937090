import React, { useEffect, useRef, useState } from "react";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import uploadDoc from "../../../assets/UploadDocuments/uploadDoc.svg";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { uploadImage } from "../../../network/common";
import SuccessModal from "../../../components/Modal/SuccessModal";
import ShowSelectedFiles from "../../../components/ShowSelectedFiles/ShowSelectedFiles";
import { setLoading, showToast } from "../../../redux/slices/commonSlice";
import styles from "../UploadDocuments/style";
import { routePaths } from "../../../router/routePaths";
import { RootState } from "../../../redux/rootReducer";
interface DocPayload {
  fileUrl: string;
  key: string;
  size: number;
  name: string;
}
const UploadDocuments = () => {
  const [documentsPayload, setDocumentsPayload] = useState<DocPayload[]>([]);
  const [openSuccessModal, setOpenSuccessModal] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const businessDocuments = useSelector(
    (state: RootState) => state.user.userData?.businessDocuments
  );

  useEffect(() => {
    setDocumentsPayload(businessDocuments || []);
  }, []);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      const acceptedImageTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      for (const file of newFiles) {
        if (!acceptedImageTypes.includes(file.type)) {
          dispatch(
            showToast({
              color: "error",
              msg: "Please upload images in these formats (jpeg, png, jpg ,pdf,doc)",
            })
          );
          return;
        }
      }
      if (documentsPayload.length + newFiles.length > 3) {
        dispatch(
          showToast({ color: "error", msg: "Maximum of 3 files can be added" })
        );
        return;
      }
      const nonDuplicateFiles = newFiles.filter(
        (newFile) =>
          !documentsPayload.some(
            (existingFile) => existingFile.name === newFile.name
          )
      );

      if (nonDuplicateFiles.length < newFiles.length) {
        dispatch(
          showToast({ color: "error", msg: "Duplicate files are not allowed." })
        );
        return;
      }
      const formDataList = newFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        return formData;
      });

      try {
        const uploadPromises = formDataList.map((formData) =>
          uploadImage(formData)
        );
        dispatch(setLoading(true));
        const responses = await Promise.all(uploadPromises);
        const urls: DocPayload[] = responses.map((res, index) => ({
          fileUrl: res.data.fileUrl,
          key: res.data.key,
          name: newFiles[index]?.name,
          size: newFiles[index]?.size / 1024,
        }));

        setDocumentsPayload((prev) => [...prev, ...urls]);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        console.error("Error uploading images:", error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      await updateUserProfile({ businessDocuments: documentsPayload });
      dispatch(setUserData({ businessDocuments: documentsPayload }));
      setOpenSuccessModal("Bussiness Details Added Successfully!");
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const closeModalHandler = () => {
    setOpenSuccessModal("");
    navigate(routePaths.settings);
  };

  const handleRemoveFile = (index: number) => {
    setDocumentsPayload((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Box className="uploadLicenseForm" sx={styles.selectSubServiceCategoryForm}>
      <ProfileWrapper
        title="Upload License/Insurance Document"
        btnDisabled={documentsPayload?.length === 0}
        onSubmit={handleSubmit}
        hide={true}
      >
        <Box sx={styles.uploadDocWrapper}>
          {documentsPayload?.length < 3 && (
            <>
              <Box sx={styles.uploadContainer}>
                <Box
                  component="img"
                  src={uploadDoc}
                  alt="Upload document"
                  sx={{
                    cursor: "pointer",
                    width: "50px",
                  }}
                  onClick={handleImageClick}
                ></Box>

                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="application/pdf, image/png, image/jpeg"
                  style={{ display: "none" }}
                  multiple
                />

                <Typography sx={styles.uploadLisence}>
                  Upload License Document
                </Typography>
                <Typography sx={styles.uploadDesc}>
                  Lorem ipsum dolor sit amet <br /> consectetur.
                </Typography>
              </Box>
              <Typography sx={styles.uploadNote}>
                Note: Max 3 documents can be added
              </Typography>
            </>
          )}
          {documentsPayload?.map((doc, index) => (
            <ShowSelectedFiles
              key={doc?.name}
              index={index}
              fileName={doc?.name}
              fileSize={doc?.size}
              handleRemoveFile={handleRemoveFile}
            />
          ))}
        </Box>
        {openSuccessModal && (
          <SuccessModal
            content={openSuccessModal}
            onClose={closeModalHandler}
            onSuccess={closeModalHandler}
          />
        )}
      </ProfileWrapper>
    </Box>
  );
};

export default UploadDocuments;
