import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import quickBuyBannerImage from "../../../../assets/QuickBuy/quickBuyBannerImage.png";
import quickBuyVectorImage from "../../../../assets/QuickBuy/quickBuyVectorImage.png";
import { useState } from "react";
import { routePaths } from "../../../../router/routePaths";
import styles from "./style";

const QuickBuyBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box sx={styles.mainBox}>
        <Grid2 sx={styles.gridContainer} container>
          <Grid2
            sx={styles.textGridItem}
            size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}
          >
            <Box sx={styles.textBox}>
              <Typography variant="h3" sx={styles.mainHeading}>
                {`Together, Let’s Find the Best Home Sale Solution for You`}
              </Typography>
              <Typography variant="h3" sx={styles.subHeading}>
                We make selling your home convenient, flexible and quick
              </Typography>
              <NavLink to={routePaths.quickBuyForm}>
                <Button sx={styles.contactButton}>Contact Us</Button>
              </NavLink>
            </Box>
          </Grid2>
          <Grid2
            sx={styles.imageGridItem}
            size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 8 }}
          >
            <Box sx={styles.imageBox}>
              <img
                onLoad={() => setIsLoading(false)}
                src={quickBuyBannerImage}
                width="100%"
                alt="Quick Banner"
              />
              {isLoading && (
                <CircularProgress size={40} sx={styles.loadingIndicator} />
              )}
            </Box>
            <Box
              component="img"
              src={quickBuyVectorImage}
              sx={styles.vectorImage}
            ></Box>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default QuickBuyBanner;
