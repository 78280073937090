import { Dispatch } from "redux";
import { showToast } from "../redux/slices/commonSlice";
import { ServiceTiming, TimeSlot } from "./interfaces";
import dayjs from "dayjs";

export const removeFalsyIdsFromSlots = (serviceTiming: ServiceTiming): void => {
  Object.values(serviceTiming).forEach((value) => {
    const dayValue = value as { isAvailable: boolean; slots: TimeSlot[] };

    const { slots } = dayValue;

    if (Array.isArray(slots) && slots.length > 0) {
      slots.forEach((slot: TimeSlot) => {
        if (slot && !slot._id) {
          delete slot._id; // Remove _id if it's falsy
        }
      });
    }
  });
};

// Helper function to validate the last slot
// To check if all slots has a start time and end time
export const validateLastSlot = (
  dispatch: Dispatch,
  timeSlots: TimeSlot[]
): boolean => {
  const lastSlot = timeSlots[timeSlots.length - 1];
  if (!lastSlot.startTime || !lastSlot.endTime) {
    dispatch(
      showToast({
        color: "error",
        msg: "Start time or end time for a slot cannot be empty.",
      })
    );
    return false;
  }
  return true;
};

// Helper function to deep copy the serviceTiming
export const deepCopyServiceTiming = (
  serviceTimingProp: ServiceTiming | undefined
): ServiceTiming => {
  if (!serviceTimingProp) {
    return {};
  }
  return JSON.parse(JSON.stringify(serviceTimingProp));
};

// Helper function to format the time slots into string format
export const formatTimeSlots = (timeSlots: TimeSlot[]): TimeSlot[] => {
  return timeSlots.map((slot) => ({
    startTime: dayjs(slot.startTime).isValid()
      ? dayjs(slot.startTime).format("h:mm A")
      : slot.startTime || "",
    endTime: dayjs(slot.endTime).isValid()
      ? dayjs(slot.endTime).format("h:mm A")
      : slot.endTime || "",
    monthDate: slot.monthDate ?? null,
    _id: slot._id ?? undefined, // Ensure compatibility with `string | undefined`
  }));
};
