import { Box, Grid2, Typography, CircularProgress } from "@mui/material";
import serviceExpertImage from "../../../../assets/Banner/serviceExpertImage.png";
import { useState } from "react";
import styles from "./style";

const Banner = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box sx={styles.mainBox}>
        <Grid2 sx={styles.gridContainer} container maxWidth="xl">
          <Grid2 sx={styles.textGridItem} size={{ xs: 12, sm: 5, lg: 4 }}>
            <Box>
              <Typography variant="h3" sx={styles.title}>
                Expert Service Providers
              </Typography>
            </Box>
          </Grid2>
          <Grid2 sx={styles.imageGridItem} size={{ xs: 12, sm: 7, lg: 8 }}>
            <Box sx={styles.imageBox}>
              <img
                src={serviceExpertImage}
                width="100%"
                alt="banner"
                onLoad={() => setIsLoading(false)}
              />
              {isLoading && (
                <CircularProgress size={40} sx={styles.loadingIndicator} />
              )}
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default Banner;
