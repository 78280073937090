import axiosInstance from "./axios";
import {
  GetCategoryPayload,
  SubmitServiceInquiryPayload,
  SubmitTieredPlanInquiryPayload,
  SubmitGlobalInquiryPayload,
  GetSubCategoryPayload,
  GetServiceProvidersPayload,
  SubmitInquiryPayload,
} from "./APIRequestType";

export const getServices = async (searchText?: string) => {
  const keyword = searchText ? `?keyword=${searchText}` : "";
  const res = await axiosInstance.get(`/service/list-for-user${keyword}`);
  return res?.data;
};

export const getCategories = async (payload: GetCategoryPayload) => {
  const res = await axiosInstance.post(`/category/list-for-user`, payload);
  return res?.data;
};
export const getSubCategories = async (payload: GetSubCategoryPayload) => {
  const res = await axiosInstance.post(
    `/sub-category/list-for-consumer`,
    payload
  );
  return res?.data;
};

export const getServiceProviders = async (
  payload?: GetServiceProvidersPayload
) => {
  const res = await axiosInstance.post(
    `/user/search-service-providers`,
    payload
  );
  return res?.data;
};

export const getServiceProvider = async (id: string) => {
  const res = await axiosInstance.get(`user/service-provider-details/${id}`);
  return res?.data;
};

export const submitServiceInquiry = async (
  payload: SubmitServiceInquiryPayload
) => {
  const res = await axiosInstance.post(`/user/send-sp-inquiry`, payload);
  return res?.data;
};

export const submitTieredPlanInquiry = async (
  payload: SubmitTieredPlanInquiryPayload
) => {
  const res = await axiosInstance.post(`/user/contact-us`, payload);
  return res?.data;
};

export const submitGlobalInquiry = async (
  payload: SubmitGlobalInquiryPayload
) => {
  const res = await axiosInstance.post(`/user/contact-us`, payload);
  return res?.data;
};

export const submitInquiry = async (payload: SubmitInquiryPayload) => {
  const res = await axiosInstance.post(`/user/submit-inquiry`, payload);
  return res?.data;
};

export const getServiceProviderDashboardData = async (
  page: number,
  limit: number
) => {
  const res = await axiosInstance.get(
    `/user/inquiry-list?page=${page}&limit=${limit}`
  );
  return res?.data;
};
