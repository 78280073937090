import { Box, Typography } from "@mui/material";

interface TextErrorProps {
  msg: string;
}

const TextError = ({ msg }: TextErrorProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: "#8989A3",
          fontSize: {
            xl: "16px",
            xs: "14px",
          },
          fontFamily: "Montserrat, sans- serif",
          opacity: "0.5",
        }}
      >
        {msg}
      </Typography>
    </Box>
  );
};

export default TextError;
