import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import { Box } from "@mui/material";
import { routePaths } from "../../../router/routePaths";
import { getServices } from "../../../network/services";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/slices/userSlice";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setLoading } from "../../../redux/slices/commonSlice";
import useDebounce from "../../../hooks/useDebounce";
import AddBusinessComp, {
  Image,
} from "../../../components/AddBusinessComp/AddBusinessComp";

// adds business/services provided by the service provider
const AddBusiness = () => {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [services, setServices] = useState<Image[]>([]);
  const [searchServicesText, setSearchServicesText] = useState<string>("");
  const debouncedSearchServicesText = useDebounce(searchServicesText, 500);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      setSelectedServices(res?.data?.serviceIds || []);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchServicesList();
  }, [debouncedSearchServicesText]);

  const fetchServicesList = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getServices(searchServicesText);
      setServices(res?.data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const addBusinessHandler = async () => {
    try {
      const payload = { serviceIds: selectedServices };
      await updateUserProfile(payload);
      dispatch(setUserData(payload));
      navigate(routePaths.selectServiceCategory);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className="addBusinessForm">
      <ProfileWrapper
        title="Add Business"
        btnDisabled={selectedServices?.length === 0}
        onSubmit={addBusinessHandler}
        stepperCount={3}
      >
        <AddBusinessComp
          searchServicesText={searchServicesText}
          selectedServices={selectedServices}
          services={services}
          setSelectedServices={setSelectedServices}
          setSearchServicesText={setSearchServicesText}
        />
      </ProfileWrapper>
    </Box>
  );
};

export default AddBusiness;
