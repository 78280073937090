import {
  Box,
  Typography,
  Link,
  Container,
  ListItem,
  ListItemText,
  List,
  Grid2,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { getStyles } from "./style";
import facebook from "../../assets/Common/facebook.svg";
import instagram from "../../assets/Common/instagram.svg";
import twitter from "../../assets/Common/twitter.svg";
import youtube from "../../assets/Common/youtube.svg";
import linkedin from "../../assets/Common/linkedin.svg";
import homeSellerFooterLogo from "../../assets/Common/homeSellerFooterLogo.png";
import { routePaths } from "../../router/routePaths";

interface FooterProps {
  role: string;
}

const Footer = ({ role }: FooterProps) => {
  const styles = getStyles();

  return (
    <footer>
      <Box sx={styles.footerWrapper}>
        <Container maxWidth="xl" sx={styles.footerContainer}>
          <Box>
            <Grid2 container spacing={2}>
              <Grid2
                sx={styles.gridItem}
                size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              >
                <Box
                  sx={{
                    textAlign: {
                      lg: "center",
                      md: "left",
                      xs: "left",
                    },
                  }}
                >
                  <NavLink to="/">
                    <Box
                      component="img"
                      src={homeSellerFooterLogo}
                      alt="Home Seller Savings Logo"
                      title="Home Seller Savings"
                      sx={{
                        width: {
                          xl: "80%",
                          lg: "100%",
                          sm: "80%",
                          xs: "50%",
                        },
                      }}
                    ></Box>
                  </NavLink>
                </Box>
              </Grid2>
              <Grid2
                sx={styles.gridItem}
                size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={styles.sectionHeading}
                  >
                    Our Company
                  </Typography>
                  <List sx={styles.list}>
                    <ListItem sx={styles.listItem}>
                      <ListItemText sx={styles.listItemText}>
                        <NavLink
                          className="nav-link-about-us"
                          to={routePaths.aboutUs}
                        >
                          About Us
                        </NavLink>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <ListItemText sx={styles.listItemText}>
                        <NavLink
                          className="nav-link-about-us"
                          to={routePaths.termsAndConditions}
                        >
                          Terms & Conditions
                        </NavLink>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <ListItemText sx={styles.listItemText}>
                        <NavLink
                          className="nav-link-about-us"
                          to={routePaths.privacyPolicy}
                        >
                          Privacy Policy
                        </NavLink>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              </Grid2>
              <Grid2
                sx={styles.gridItem}
                size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              >
                <Box sx={styles.customer}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={styles.sectionHeading}
                  >
                    For Customers
                  </Typography>
                  <List sx={styles.list}>
                    <ListItem sx={styles.listItem}>
                      <ListItemText sx={styles.listItemText}>
                        <NavLink
                          to={
                            role === "serviceProvider"
                              ? routePaths.expertServiceProvider
                              : routePaths.serviceproviders
                          }
                          className="nav-link-about-us"
                        >
                          {role === "serviceProvider"
                            ? "Get Service"
                            : "Provide Service"}
                        </NavLink>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              </Grid2>
              <Grid2
                sx={styles.gridItem}
                size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              >
                <Box sx={styles.socialIcons}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={styles.sectionHeading}
                  >
                    Social Links
                  </Typography>
                  <Box sx={styles.socialIconBox}>
                    <Box sx={styles.socialIconWrapper}>
                      <Link
                        href="https://www.facebook.com/"
                        target="_blank"
                        sx={styles.socialLink}
                      >
                        <img src={facebook} alt="Facebook Icon" />
                      </Link>
                    </Box>
                    <Box sx={styles.socialIconWrapper}>
                      <Link
                        href="https://www.instagram.com/homesellersavings"
                        target="_blank"
                        sx={styles.socialLink}
                      >
                        <img src={instagram} alt="Instagram Icon" />
                      </Link>
                    </Box>
                    <Box sx={styles.socialIconWrapper}>
                      <Link
                        href="https://x.com/HomeSellerSave"
                        target="_blank"
                        sx={styles.socialLink}
                      >
                        <img src={twitter} alt="Twitter Icon" />
                      </Link>
                    </Box>
                    <Box sx={styles.socialIconWrapper}>
                      <Link
                        href="https://www.youtube.com/channel/UC2s9ivJt3bcjMXqj0AvH2jQ"
                        target="_blank"
                        sx={styles.socialLink}
                      >
                        <img src={youtube} alt="Youtube Icon" />
                      </Link>
                    </Box>
                    <Box sx={styles.socialIconWrapper}>
                      <Link
                        href="https://www.linkedin.com/in/homesellersavings-llc-080880336/"
                        target="_blank"
                        sx={styles.socialLink}
                      >
                        <img src={linkedin} alt="Linkedin Icon" />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
