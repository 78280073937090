import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

import styles from "./style";
interface ConfirmationModalType {
  content: string;
  onReject: () => void;
  onAccept: () => void;
  imgSrc: string;
}
const ConfirmationModal: React.FC<ConfirmationModalType> = ({
  content,
  onReject,
  onAccept,
  imgSrc,
}) => {
  return (
    <Dialog
      onClose={onReject}
      aria-labelledby="customized-dialog-title"
      open={true}
      sx={styles.confirmationModalPaper}
    >
      <DialogTitle
        sx={styles.confirmationModalTitle}
        id="customized-dialog-title"
      >
        <Box
          src={imgSrc}
          alt="Sucess Image"
          component="img"
          sx={{
            width: "auto",
            "@media (orientation: landscape) and (max-width: 999px)": {
              width: "140px",
            },
          }}
        ></Box>
        <Box>{content}</Box>
      </DialogTitle>

      <DialogContent sx={styles.confirmationDialogContent}>
        <Box sx={styles.paddingTwenty}>
          <Box sx={styles.confirmationModalButtonDiv}>
            <Button sx={styles.confirmationModalYesButton} onClick={onAccept}>
              Yes
            </Button>
            <Button sx={styles.confirmationModalNoButton} onClick={onReject}>
              No
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
