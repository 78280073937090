import { Box, InputLabel, TextField } from "@mui/material";
import React from "react";
import styles from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import editIcon from "../../../assets/Settings/editIcon.svg";
import { getSx } from "../../../utils/styleHelpers";

interface EditPersonalDetailsProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleEditClick: (field: string) => void;
  isEditingPhoneNumber: boolean;
  isEditingFullName: boolean;
  isEditingEmail: boolean;
}

const EditPersonalDetails: React.FC<EditPersonalDetailsProps> = ({
  handleInputChange,
  handleEditClick,
  isEditingPhoneNumber,
  isEditingFullName,
  isEditingEmail,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  return (
    <>
      <Box
        className={`formControl ${userProfile?.errors?.fullName ? "errorWrapper" : ""}`}
        sx={styles.formControl}
      >
        <InputLabel className="inputLable">Name</InputLabel>
        <TextField
          name="fullName"
          value={userProfile?.fullName || ""}
          placeholder="Name"
          onChange={handleInputChange}
          disabled={!isEditingFullName}
          error={!!userProfile?.errors?.fullName}
          helperText={userProfile?.errors?.fullName}
          sx={getSx(userProfile?.errors?.fullName)}
        />
        <Box onClick={() => handleEditClick("fullName")} className="editIcon">
          <img src={editIcon} alt="Edit Icon" />
        </Box>
      </Box>
      <Box
        className={`formControl ${userProfile?.errors?.phoneNumber ? "errorWrapper" : ""}`}
        sx={styles.formControl}
      >
        <InputLabel className="inputLable">Phone Number</InputLabel>
        <TextField
          name="phoneNumber"
          value={userProfile?.phoneNumber || ""}
          placeholder="Phone Number"
          onChange={handleInputChange}
          disabled={!isEditingPhoneNumber}
          error={!!userProfile?.errors?.phoneNumber}
          helperText={userProfile?.errors?.phoneNumber}
          sx={getSx(userProfile?.errors?.phoneNumber)}
        />
        <Box
          onClick={() => handleEditClick("phoneNumber")}
          className="editIcon"
        >
          <img src={editIcon} alt="Edit Icon" />
        </Box>
      </Box>
      <Box
        className={`formControl ${userProfile?.errors?.email ? "errorWrapper" : ""}`}
        sx={styles.formControl}
      >
        <InputLabel className="inputLable">Email Address</InputLabel>
        <TextField
          name="email"
          value={userProfile?.email || ""}
          placeholder="Email Address"
          disabled={!isEditingEmail}
          error={!!userProfile?.errors?.email}
          helperText={userProfile?.errors?.email}
          sx={getSx(userProfile?.errors?.email)}
        />
      </Box>
    </>
  );
};

export default EditPersonalDetails;
