import React, {
  ChangeEvent,
  useEffect,
  useState,
  useReducer,
  FormEvent,
} from "react";
import "./Home.css";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Container } from "@mui/material";
import { submitInquiry } from "../../../../network/services";
import styles from "./style";
import { initialState } from "./helper";
import { setLoading, showToast } from "../../../../redux/slices/commonSlice";
import formvectorImage from "../../../../assets/ServiceProvider/formvectorImage.png";
import SuccessForm from "../../../../components/SucessForm/index";
import { validateField } from "../../../../utils/validationUtils";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { formatInput } from "../../../../utils/formatUtils";
import PersonalDetailsForm from "./PersonalDetailsForm";
import AddressDetailsForm from "./AddressDetailsForm";
import UploadDocumentsForm from "./UploadDocumentsForm";
import { uploadImage } from "../../../../network/common";

interface SnackBarState extends SnackbarOrigin {
  open: boolean;
}

interface CustomLocationState {
  formState?: string;
}

interface State {
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  isAddMoreZipCode: boolean;
  moreZipCodes: string;
  description: string;
  address: string;
  city: string;
  province: string;
  selectedFiles: File[];
  disabled: boolean;
  disabled2: boolean;
  formCount: number;
  error: string | null;
  errors: {
    phoneNumber: string;
    email: string;
    fullName: string;
    zipCode: string;
    description: string;
    address: string;
    city: string;
    province: string;
    moreZipCodes: string;
  };
}

interface DocPayload {
  fileUrl?: string;
  key?: string;
  size?: number;
  name?: string;
}

type Action =
  | {
      type: "SET_FIELD";
      payload: { field: string; value: string | boolean | number };
    }
  | { type: "SET_ERROR"; payload: { field: string; message: string } }
  | { type: "CLEAR_ERRORS" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.payload.field]: action.payload.value };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {
          phoneNumber: "",
          email: "",
          fullName: "",
          zipCode: "",
          description: "",
          address: "",
          city: "",
          province: "",
          moreZipCodes: "",
        },
      };
    default:
      return state;
  }
};

const Home = React.forwardRef((props, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const snackMsg = "";
  const [documentsPayload, setDocumentsPayload] = useState<DocPayload[]>([]);
  const commonDispatch = useDispatch();
  const location = useLocation();
  const pathState = location.state as CustomLocationState;
  const formState = pathState?.formState;
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = snackBarState;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setSnackBarState({ ...newState, open: true });
  };

  const handleClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const validateMoreZipCodes = (value: string) => {
    const moreZipCodesArray = value.split(",").map((zip: string) => zip.trim());

    if (new Set(moreZipCodesArray).size !== moreZipCodesArray.length) {
      return "zip codes must be unique.";
    }
    const zipPattern = /^\d{5,9}$/;
    for (const zip of moreZipCodesArray) {
      if (!zipPattern.test(zip)) {
        return "Zip codes must be between 5 and 9 digits long.";
      }
    }
    return null;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let { value } = event.target;

    if (
      name === "zipCode" ||
      name === "fullName" ||
      name === "city" ||
      name === "province" ||
      name === "phoneNumber"
    ) {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "address") {
      value = formatInput(name, value, 100);
    }
    if (name === "description") {
      value = formatInput(name, value, 1000);
    }

    if (name === "phoneNumber") {
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
      const errorMessage = validateField(name, value);
      dispatch({
        type: "SET_ERROR",
        payload: { field: name, message: errorMessage },
      });
    } else if (name === "moreZipCodes") {
      const errormsg = validateMoreZipCodes(value);
      if (errormsg) {
        dispatch({
          type: "SET_ERROR",
          payload: { field: name, message: errormsg },
        });
      } else {
        dispatch({ type: "SET_ERROR", payload: { field: name, message: "" } });
      }
    } else {
      const errorMessage = validateField(name, value);
      dispatch({
        type: "SET_ERROR",
        payload: { field: name, message: errorMessage },
      });
    }

    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
  };

  const handleFormCount = (value: number) => {
    dispatch({ type: "SET_FIELD", payload: { field: "formCount", value } });
  };

  const handleFormContinue = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.scrollTo({
      top: window.innerHeight * 0.85,
      behavior: "smooth",
    });
    const userData = {
      fullName: state.fullName,
      phoneNumber: state.phoneNumber,
      email: state.email,
      description: state.description,
      address: state.address,
      city: state.city,
      province: state.province,
      zipCode: state.zipCode,
      zipCodes: [
        ...(state.moreZipCodes
          ? state.moreZipCodes
              .split(",")
              .map((zip) => zip.trim())
              .filter((zip) => zip)
          : []),
      ],
      businessDocuments: documentsPayload,
    };
    const response = await submitInquiry(userData);
    const data = await response.data;

    if (data) {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "formCount", value: 4 },
      });
    }
  };

  const handleFileTrigger = () => {
    const fileElem = document.querySelector(".mainFileSelector");
    if (fileElem && fileElem instanceof HTMLInputElement) {
      fileElem.click();
    } else {
      console.error(
        "File input element not found or is not an HTMLInputElement"
      );
    }
  };

  const handleAddMoreZipCode = () => {
    dispatch({
      type: "SET_FIELD",
      payload: { field: "isAddMoreZipCode", value: true },
    });
  };
  useEffect(() => {
    const hasErrors = Object.values(state.errors).some((error) => error !== "");
    if (
      !hasErrors &&
      state.fullName !== "" &&
      state.phoneNumber !== "" &&
      state.email !== "" &&
      state.description !== ""
    )
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: false },
      });
    else
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: true },
      });
  }, [state.errors]);

  useEffect(() => {
    const hasErrors = Object.values(state.errors).some((error) => error !== "");
    if (
      !hasErrors &&
      state.address !== "" &&
      state.city !== "" &&
      state.province !== "" &&
      state.zipCode !== "" &&
      state.moreZipCodes !== ""
    ) {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled2", value: false },
      });
    } else {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled2", value: true },
      });
    }
  }, [
    state.address,
    state.city,
    state.province,
    state.zipCode,
    state.moreZipCodes,
    state.errors,
  ]);

  const handleRemoveFile = (index: number) => {
    setDocumentsPayload((prev) => prev.filter((_, i) => i !== index));
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      const acceptedImageTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      for (const file of newFiles) {
        if (!acceptedImageTypes.includes(file.type)) {
          commonDispatch(
            showToast({
              color: "error",
              msg: "Please upload images in these formats (jpeg, png, jpg ,pdf,doc)",
            })
          );
          return;
        }
      }
      if (documentsPayload.length + newFiles.length > 3) {
        commonDispatch(
          showToast({ color: "error", msg: "Maximum of 3 files can be added" })
        );
        return;
      }
      const nonDuplicateFiles = newFiles.filter(
        (newFile) =>
          !documentsPayload.some(
            (existingFile) => existingFile.name === newFile.name
          )
      );

      if (nonDuplicateFiles.length < newFiles.length) {
        commonDispatch(
          showToast({ color: "error", msg: "Duplicate files are not allowed." })
        );
        return;
      }

      const formDataList = newFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        return formData;
      });

      try {
        const uploadPromises = formDataList.map((formData) =>
          uploadImage(formData)
        );
        commonDispatch(setLoading(true));
        const responses = await Promise.all(uploadPromises);
        const urls: DocPayload[] = responses.map((res, index) => ({
          fileUrl: res.data.fileUrl,
          key: res.data.key,
          name: newFiles[index]?.name,
          size: newFiles[index]?.size / 1024,
        }));

        setDocumentsPayload((prev) => [...prev, ...urls]);
        commonDispatch(setLoading(false));
      } catch (error) {
        commonDispatch(setLoading(false));
        console.error("Error uploading images:", error);
      }
    }
    e.target.value = "";
  };

  useEffect(() => {
    if (formState === "form") {
      setTimeout(() => {
        const elem = document.getElementById("register");
        elem?.click();
      }, 200);
    }
  }, []);

  const buttons = (
    <>
      <Button
        style={{ display: "none" }}
        id="snackbar"
        onClick={handleClick({ vertical: "top", horizontal: "right" })}
      >
        Top-Right
      </Button>
    </>
  );

  return (
    <Box>
      <Box sx={styles.background}>
        <Container maxWidth="md">
          <Box ref={ref} sx={styles.formBox}>
            <Box>
              <Grid2 container>
                <Grid2 size={{ xs: 12, lg: 8 }} sx={styles.gridItem}>
                  {state.formCount === 1 && (
                    <PersonalDetailsForm
                      state={state}
                      handleInputChange={handleInputChange}
                      handleFormCount={handleFormCount}
                    />
                  )}
                  {state.formCount === 2 && (
                    <AddressDetailsForm
                      state={state}
                      handleInputChange={handleInputChange}
                      handleFormCount={handleFormCount}
                      handleAddMoreZipCode={handleAddMoreZipCode}
                    />
                  )}
                  {state.formCount === 3 && (
                    <UploadDocumentsForm
                      documentsPayload={documentsPayload}
                      state={state}
                      handleFileChange={handleFileChange}
                      handleFormCount={handleFormCount}
                      handleRemoveFile={handleRemoveFile}
                      handleFileTrigger={handleFileTrigger}
                      handleFormContinue={handleFormContinue}
                    />
                  )}
                  {state.formCount === 4 && <SuccessForm />}
                </Grid2>
              </Grid2>
            </Box>
          </Box>
        </Container>
        <Box sx={styles.vectorImage}>
          <img src={formvectorImage} alt="Vector" />
        </Box>
      </Box>

      <Box sx={styles.w500}>
        {buttons}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={snackMsg}
          key={vertical + horizontal}
        />
      </Box>
    </Box>
  );
});

export default Home;
