import { VisuallyHiddenInput } from "../../../components/VisuallyHiddenInput/VisuallyHiddenInput";
import { Box, Typography } from "@mui/material";
import imageEditIcon from "../../../assets/Settings/imageEditIcon.svg";
import styles from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";

interface EditProfileImageProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleEditClick: (field: string) => void;
}

const EditProfileImage: React.FC<EditProfileImageProps> = ({
  handleInputChange,
  handleEditClick,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  return (
    <Box>
      <Typography variant="body1" sx={styles.profileImagesTitle}>
        Profile Image
      </Typography>
      <Box sx={styles.profileImageBox}>
        <Box
          sx={styles.profileImage}
          component="img"
          src={userProfile?.profilePic}
        ></Box>
        <Box sx={styles.editIconBox}>
          <Box sx={styles.editIconContainer} component="label" tabIndex={-1}>
            <Box
              src={imageEditIcon}
              sx={styles.editIconImage}
              alt="Image Edit Icon"
              component="img"
              onClick={() => handleEditClick("profilePic")}
            ></Box>
            <VisuallyHiddenInput
              name="profilePic"
              type="file"
              accept="image/*"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditProfileImage;
