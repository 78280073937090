import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./style";
import dropdownArrow from "../../../../assets/ExpertServiceProvider/dropdownArrow.svg";
import { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../../../context/SearchContext";
import { getCategories } from "../../../../network/services";

const DropdownIcon = ({ onClick }: { onClick: () => void }) => (
  <img
    src={dropdownArrow}
    alt="arrow"
    className="downArrowIcon"
    onClick={onClick}
  />
);

const MenuProps = {
  PaperProps: {
    className: "dropdownMenu",
  },
};

const SelectCategoryDropdown = ({
  setIsCategorySelected,
}: {
  setIsCategorySelected: (value: boolean) => void;
}) => {
  const searchContext = useContext(SearchContext);
  const [open, setOpen] = useState(false);

  const DropdownIconComponent = () => {
    return <DropdownIcon onClick={handleDropdownToggle} />;
  };
  const handleDropdownToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }
  const { data, filters, updateData, updateFilter } = searchContext;
  const selectedCategoryHandleChange = (event: SelectChangeEvent<string>) => {
    setIsCategorySelected(true);
    updateFilter("selectedCategory", event.target.value);
    updateFilter("selectedSubCategories", []);
    updateData("serviceProviders", []);
    updateData("isSearchClicked", false);
    updateData("totalRecordsCount", 0);
    updateData("page", 1);
  };

  useEffect(() => {
    getCategoriesList();
  }, [filters.selectedService]);

  const getCategoriesList = async () => {
    try {
      if (
        typeof filters.selectedService == "object" &&
        filters.selectedService !== null
      ) {
        const serviceId =
          filters.selectedService && filters.selectedService._id;
        const response = await getCategories({
          serviceIds: [serviceId],
        });
        const fetchedData = await response.data;
        updateData("category", fetchedData[0].categories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  return (
    <FormControl
      sx={styles.formControl}
      fullWidth
      variant="outlined"
      className="selectDropdown"
    >
      <Select
        labelId="select-location-label"
        MenuProps={MenuProps}
        value={filters.selectedCategory}
        onChange={selectedCategoryHandleChange}
        displayEmpty
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        IconComponent={DropdownIconComponent}
        renderValue={(selectedValue) =>
          selectedValue !== "" ? (
            selectedValue
          ) : (
            <span className="selectPlaceholder">Select Category</span>
          )
        }
        sx={{
          pointerEvents: !filters.selectedService ? "none" : "auto",
        }}
      >
        {data.category.map(
          (category: { categoryName: string; _id: string }) => (
            <MenuItem key={category._id} value={category.categoryName}>
              {category.categoryName}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default SelectCategoryDropdown;
