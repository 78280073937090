export const getStyles = (menuVisible: boolean) => ({
  box: {
    background: "#fff",
    borderRadius: {
      md: "0 0px 25px 25px",
      xs: "0",
    },
    padding: {
      xl: "14px 0",
      xs: "8px 0",
    },
    position: "sticky",
    top: "0",
    zIndex: "999",
    borderBottom: {
      xl: "none",
      xs: "1px solid #8989A3",
    },
  },
  toggleMenu: {
    cursor: "pointer",
    display: {
      xs: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (min-width: 1024px)": {
        display: "none",
      },
    },
  },
  toggleMenuIcon: {
    paddingRight: "5px",
  },
  menuList: {
    display: {
      xs: menuVisible ? "block" : "none",
      "@media (min-width: 1024px)": {
        display: "flex",
      },
    },
    alignItems: "center",
    justifyContent: "space-between",
    position: {
      xs: "absolute",
      "@media (min-width: 1024px)": {
        position: "static",
      },
    },
    top: "55px",
    background: {
      xs: "#06038d",
      "@media (min-width: 1024px)": {
        background: "transparent",
      },
    },
    height: {
      "@media (min-width: 1024px)": {
        height: "auto",
      },
      xs: "100vh",
    },
    width: {
      md: "auto",
      xs: "auto",
    },
    left: "0",
    right: "0",
    padding: {
      xs: "20px",
      "@media (min-width: 1024px)": {
        padding: 0,
      },
    },
  },
  headerLogo: {
    width: {
      xl: "365px",
      xs: "300px",
    },
    paddingTop: "5px",
  },
  listItem: {
    padding: 0,
    width: "auto",
  },
  logoListItemText: {
    padding: 0,
    margin: 0,
    display: {
      xs: "none",
      "@media (min-width: 1024px)": {
        display: "flex",
      },
    },
  },
  listItemText: {
    padding: 0,
    margin: {
      "@media (min-width: 1024px)": {
        margin: "0",
      },
      xs: "0 0 20px 0",
    },
  },
  link: {
    fontSize: {
      xl: "18px",
      xs: "14px",
    },
    color: {
      "@media (min-width: 1024px)": {
        color: "#070477CC",
      },
      xs: "#fff",
    },
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
  },
  primaryButton: {
    background: "#06038D",
    padding: {
      xl: "13px 20px",
      xs: "10px 14px",
    },
    borderRadius: "8px",
    width: "max-content",
    border: {
      xl: "none",
      xs: "1px solid #fff",
    },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
  },
  menucloseIcon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    padding: "0",
    justifyContent: "flex-end",
    display: {
      sm: "inline-block",
      md: "none",
    },
    width: "auto",
    "& img": {
      border: "1px solid #fff",
      padding: "10px",
      borderRadius: "50%",
    },
  },
  primaryButtonLinkText: {
    padding: 0,
    margin: 0,
  },
  primaryButtonText: {
    fontSize: {
      xl: "18px",
      xs: "14px",
    },
    color: "#fff",
    fontWeight: "600",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
  },
  bannerBgColor: {
    textAlign: "left",
    background: "linear-gradient(103.19deg, #06038D 46.27%, #FFFFFF 178.75%)",
  },
  bannerWrapper: {
    padding: {
      xl: "100px 0 0 66px",
      md: "30px 30px 0 30px",
      xs: "20px 16px 0",
    },
  },
  bannerContainer: {
    "@media (min-width: 1536px)": {
      maxWidth: "1536px",
      margin: "auto",
    },
  },
  bannerHeading: {
    fontSize: {
      lg: "46px",
      xs: "28px",
    },
    color: "#fff",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "700",
    lineHeight: {
      lg: "62px",
    },
  },
  bannerHighlightText: {
    color: "#1AD378",
  },
  bannerText: {
    fontSize: {
      lg: "24px",
      xs: "18px",
    },
    lineHeight: {
      xs: "30px",
      lg: "40px",
    },
    color: "#fff",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    padding: {
      xs: "20px 0",
      lg: "40px 0",
    },
  },
  signInButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      lg: "13px 0",
    },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "0 15px 30px 0",
      sm: "0 30px 0 0",
      lg: "0 30px 20px 0",
    },
    width: {
      lg: "180px",
      xs: "auto",
    },
  },
  registerButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      lg: "13px 0",
    },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "0 0 30px 0",
      sm: "0 0 0 0",
      lg: "0 0 20px 0",
    },
    width: {
      lg: "180px",
      xs: "auto",
    },
  },
  dialogTitle: {
    m: 0,
    fontFamily: "Montserrat, sans- serif",
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "700",
  },
  yesButton: {
    background: "#B3B3CF",
    padding: "13px 80px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    margin: {
      sm: "0 20px 0 0",
      xs: "0 0 20px 0",
    },
    width: {
      sm: "max-content",
      xs: "100%",
    },
  },
  noButton: {
    background: "#06038D",
    padding: "13px 80px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    width: {
      sm: "max-content",
      xs: "100%",
    },
  },
  buttonWrapper: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  dialogContentWrapper: {
    padding: {
      lg: "30px 90px 20px",
      xs: "0 0 20px 0",
    },
  },
  dialogBox: {
    padding: "0 20px",
  },
});
