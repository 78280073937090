import { Filters, Service } from "../context/SearchContext";
import { Component } from "../module/LandingPage/ExpertServiceProvider/Search/LocationModal";

export const getAddressFromCoordinates = async (
  lat: number,
  lng: number,
  updateFilter?: (
    key: keyof Filters,
    value: string | string[] | Service | null
  ) => void
): Promise<void> => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.results.length > 0) {
      const firstResult = data.results[0];
      const formattedAddress = firstResult.formatted_address;
      const addressComponents = firstResult.address_components;
      const zipCodeComponent = addressComponents.find((comp: Component) =>
        comp.types.includes("postal_code")
      );
      const zipCode = zipCodeComponent?.long_name || "Unknown Zip Code";
      if (updateFilter) {
        updateFilter("zipCode", zipCode);
        updateFilter("searchedAddress", "");
        updateFilter("location", formattedAddress);
      }
      sessionStorage.setItem("location", formattedAddress);
      sessionStorage.setItem("zipCode", zipCode);
    }
  } catch (err) {
    console.error("Error fetching address from coordinates:", err);
  }
};
