import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepConnectorProps,
  StepContextType,
} from "@mui/material";
import CustomStepIcon from "./CustomStepIcon";

interface CustomStepperProps {
  currentStep: number;
}

// CustomConnector with correct typing
const CustomConnector = React.forwardRef<HTMLDivElement, StepConnectorProps>(
  (props, ref) => {
    const { completed } = props as StepContextType;

    return (
      <StepConnector
        ref={ref}
        {...props}
        sx={{
          top: "23px",
          height: "1px",
          right: "calc(50% + 19px)",
          backgroundColor: completed ? "blue" : "#ccc",
          "&.Mui-active": {
            backgroundColor: "blue",
          },
        }}
      />
    );
  }
);
CustomConnector.displayName = "CustomConnector";
// Main component
const CustomStepper: React.FC<CustomStepperProps> = ({ currentStep }) => {
  const steps = [
    { label: "Step 1" },
    { label: "Step 2" },
    { label: "Step 3" },
    { label: "Step 4" },
    { label: "Step 5" },
    { label: "Step 6" },
    { label: "Step 7" },
    { label: "Step 8" },
  ];

  return (
    <Stepper
      activeStep={currentStep - 1} // Adjust for zero-based index
      alternativeLabel
      connector={<CustomConnector />} // Use custom connector
      sx={{
        overflowX: "auto",
        scrollbarWidth: "none",
        // padding: '0 20px',
      }}
    >
      {steps.map((step, index) => {
        let status: "active" | "inactive" | "done";

        if (index < currentStep - 1) {
          status = "done";
        } else if (index === currentStep - 1) {
          status = "active";
        } else {
          status = "inactive";
        }

        return (
          <Step
            key={step.label}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StepLabel
              StepIconComponent={() => (
                <div style={{ transition: "opacity 0.8s" }}>
                  <CustomStepIcon icon={index + 1} status={status} />
                </div>
              )}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 0,
              }}
            ></StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomStepper;
