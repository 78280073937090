import {
  Container,
  Grid2,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { useState } from "react";
import { styles } from "./style";
import ourMisstionImageVector from "../../../../../assets/AboutUs/ourMisstionImageVector.png";

import ourExpertiseImage from "../../../../../assets/AboutUs/ourExpertiseImage.jpg";
import { routePaths } from "../../../../../router/routePaths";
import { NavLink } from "react-router-dom";

const Expertise = () => {
  const [isExperServiceImageLoading, setIsExperServiceImageLoading] =
    useState(true);
  return (
    <Container maxWidth="xl" sx={styles.expertiesContainer}>
      <Box sx={styles.expertiseBox}>
        <Grid2 container sx={styles.expertiseGridContainer}>
          <Grid2
            size={{ xs: 12, md: 6, lg: 6 }}
            sx={styles.expertiseTextGridItem}
          >
            <Box>
              <Typography variant="h3" sx={styles.expertiseTitle}>
                Our Expertise
              </Typography>
            </Box>
            <Box>
              <Typography variant="h3" sx={styles.expertiseDescription}>
                {`With many years of experience in the real estate market, we've
                built strong relationships with trusted service providers. We're
                proud to share these connections with you, offering a
                one-stop-shop experience that adds another layer of convenience
                to your home selling journey.`}
              </Typography>
            </Box>
            <NavLink to={routePaths.contact}>
              <Button sx={styles.learnMoreButton}>Learn More</Button>
            </NavLink>
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 6, lg: 6 }}
            sx={styles.expertiseImageGridItem}
          >
            <Box
              component="img"
              sx={styles.expertiseImage}
              src={ourExpertiseImage}
              onLoad={() => setIsExperServiceImageLoading(false)}
            ></Box>
            {isExperServiceImageLoading && (
              <Box sx={styles.expertiseImageLoadingBox}>
                <CircularProgress />
              </Box>
            )}
          </Grid2>
        </Grid2>
        <Box sx={styles.expertiseVectorImage}>
          <img src={ourMisstionImageVector} alt="Our Services Vector" />
        </Box>
      </Box>
    </Container>
  );
};

export default Expertise;
