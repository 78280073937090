import { Box, Grid2, IconButton } from "@mui/material";
import React from "react";
import close from "../../../assets/Icons/multiImageClose.svg";
import styles from "./style";
interface UploadImageProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileRef: React.RefObject<HTMLInputElement | null>;
  preview?: string;
  previews?: string[];
  camera: string;
  handleFileRef: () => void;
  uploadImage: string;
  multiple?: boolean;
  handleClose?: (index: number) => void;
}

const UploadImage: React.FC<UploadImageProps> = ({
  handleFileChange,
  fileRef,
  preview,
  camera,
  handleFileRef,
  uploadImage,
  multiple,
  previews,
  handleClose,
}) => {
  return (
    <Box sx={styles.externalcontainer}>
      <input
        type="file"
        onChange={handleFileChange}
        accept="image/jpeg, image/jpg, image/png"
        style={{ display: "none" }}
        ref={fileRef}
        multiple={multiple}
      />
      {/* multiple is for business Images and previews is for its previews since upto 3 images can be added in businessImages */}
      {/* preview or the final else part is to upload preview or uploaded image of profile */}
      {multiple ? (
        <Grid2 container spacing={2} sx={styles.gridContainer}>
          {previews &&
            previews.length > 0 &&
            previews.map((item, index) => (
              <Grid2 key={item} sx={styles.paddingZero}>
                <Box sx={styles.businessImages}>
                  <img
                    src={item}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      borderRadius: "10px",
                    }}
                    alt="Selected Preview"
                  />
                </Box>
                <IconButton
                  onClick={() => handleClose?.(index)} // Pass index to handleClose
                  sx={styles.closeIconButton}
                >
                  <img src={close} alt="Close" />
                </IconButton>
              </Grid2>
            ))}
          {/* Show upload image as a third image if previews length is less than 3 */}
          {previews && previews.length < 3 && (
            <Grid2 sx={styles.paddingZero}>
              <Box onClick={handleFileRef}>
                <Box
                  component="img"
                  src={uploadImage}
                  sx={styles.uploadPlaceholderImage}
                  alt="Upload Icon"
                ></Box>
              </Box>
            </Grid2>
          )}
        </Grid2>
      ) : preview ? (
        <div className="image-preview" style={{ position: "relative" }}>
          <Box
            alt="Selected Preview"
            src={preview}
            component="img"
            sx={styles.selectedPreview}
          ></Box>
          <Box
            src={camera}
            component="img"
            onClick={handleFileRef}
            sx={styles.cameraImg}
          ></Box>
        </div>
      ) : (
        <Box
          component="img"
          src={uploadImage}
          alt="Upload Icon"
          onClick={handleFileRef}
          sx={styles.uploadIcon}
        ></Box>
      )}
    </Box>
  );
};

export default UploadImage;
