const styles = {
  singInLableHeading: {
    color: "#070477",
    fontSize: {
      xl: "45px",
      xs: "28px",
    },
    fontFamily: "Montserrat, sans-serif",
    marginBottom: "8px",
    fontWeight: "600",
  },
  singInLable: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      xl: "20px",
      xs: "14px",
    },
    color: "#8989A3",
    marginBottom: "30px",
  },
  registerLable: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      xl: "20px",
      xs: "14px",
    },
    color: "#5B5B71",
  },
  typographyHeader: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 700,
    marginBottom: "5px",
    textAlign: "center",
    fontSize: {
      xs: "26px",
      xl: "30px",
    },
    paddingTop: "10px",
    color: "rgba(13, 6, 57, 1)",
  },
  rightSide: {
    borderRadius: "20px",
    background: "rgba(237, 236, 248, 1)",
    color: "rgb(36, 36, 178)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: {
      sm: "0px 30px 10px 30px",
      xs: "10px 20px 20px 20px",
    },
    height: {
      sm: "calc(100vh - 40px)",
      xs: "auto",
    },
    position: "relative",
    "@media (orientation: landscape) and (max-width: 999px)": {
      height: "auto",
      paddingTop: "40px",
    },
  },
  typographyBody: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    textAlign: "center",
    width: {
      md: "80%",
    },
    color: "#1D0C99",
    fontSize: {
      xs: "14px",
      xl: "18px",
    },
  },
  singInImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  singInImageWrapper: {
    height: {
      xs: "auto",
      sm: "67vh",
    },
    width: "100%",
  },
  primaryColor: {
    color: "#06038D",
  },
  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  registerNowBtn: {
    color: "#06038D",
    fontWeight: "700",
    paddingLeft: "5px",
    cursor: "pointer",
  },
  signinBtnContainer: {
    margin: {
      xl: "20px 0",
      xs: "8px 0 12px",
    },
  },
  rememberMeLabel: {
    fontFamily: "Montserrat, sans-serif",
    color: "#06038DCC",
    fontSize: {
      xl: "16px",
      xs: "14px",
    },
  },
  rememberMeCheckBox: {
    width: {
      xl: "24px",
      xs: "20px",
    },
  },
  savedCredsList: {
    cursor: "pointer",
    padding: "5px",
    "&:hover": { background: "#f0f0f0" },
  },
  f12: {
    fontSize: "12px",
  },
  credsDropdown: {
    position: "absolute",
    top: "100%", // Position below the input
    left: 0,
    right: 0,
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    zIndex: 1,
    maxHeight: "200px",
    overflowY: "auto",
  },
  signInSubContainer: {
    width: {
      md: "auto",
      xs: "100%",
    },
    paddingRight: {
      sm: "30px",
      md: "0",
    },
  },
  signInContainer: {
    marginBottom: {
      xs: "30px",
      lg: "0",
    },
  },
};

export default styles;
