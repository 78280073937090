import { Box, Typography } from "@mui/material";

import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import DashboardFooter from "../../../components/DashboardFooter/DashboardFooter";
import OperatingHours from "../AddOperatingHours/OperatingHours";

import styles from "../Dashboard/style";
import DashboardMobileHeader from "../../../components/DashboardHeader/DashboardMobileHeader";

const Availability = () => {
  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.dashboardMobileHeader}>
          <DashboardMobileHeader />
        </Box>
        <Box sx={styles.dashboardHeaderDiv}>
          <DashboardHeader />
        </Box>
        <Box className="settingsPage" sx={styles.settingsPage}>
          <Box sx={styles.headerBox}>
            <Typography variant="h3" sx={styles.availabilityTitle}>
              Availability
            </Typography>
          </Box>
          <OperatingHours from={"edit"} />
        </Box>
      </Box>
      <Box sx={styles.dashboardFooterBox}>
        <DashboardFooter />
      </Box>
    </>
  );
};

export default Availability;
