import { Box, Grid2, Typography } from "@mui/material";
import CustomTextField from "../FormElements/CustomTextField";
import Search from "../../assets/Icons/Search.svg";
import TextError from "../TextError/TextError";
import styles from "../../module/Profile/AddOperatingHours/style";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

export interface Image {
  _id: string;
  name: string;
  displayImage: {
    url: string;
  };
}

interface AddBusinessProps {
  searchServicesText: string;
  selectedServices: string[];
  services: Image[];
  setSelectedServices: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchServicesText: React.Dispatch<React.SetStateAction<string>>;
}

const AddBusinessComp: React.FC<AddBusinessProps> = ({
  searchServicesText,
  selectedServices,
  services,
  setSelectedServices,
  setSearchServicesText,
}) => {
  const loader = useSelector((state: RootState) => state.common.loader);

  const selectServiceHandler = (image: Image) => {
    if (selectedServices.includes(image._id)) {
      setSelectedServices(
        selectedServices.filter((item) => item !== image._id)
      );
    } else {
      setSelectedServices([...selectedServices, image._id]);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchServicesText(e.target.value);
  };
  return (
    <Box sx={styles.addBusinessFormWrapper} className="addBusinessFormWrapper">
      <form>
        <Box>
          <CustomTextField
            value={searchServicesText}
            onChange={handleSearch}
            placeholder="Search services"
            type={"text"}
            name="fullName"
            endAdornment={Search}
            profile={true}
            allowOnlyAlphabets={true}
            maxChar={32}
          />
        </Box>
      </form>

      <Box sx={styles.servicesImagesDiv}>
        <Grid2 container spacing={2}>
          {services.map((service) => (
            <Grid2 size={{ xs: 12, sm: 4 }} key={service?._id}>
              <Box
                sx={{
                  ...styles.eachServiceDiv,
                  border: `${selectedServices.includes(service?._id) ? "2px solid #1CB469" : "1px solid #06038D4D"}`,
                  boxShadow: `${selectedServices.includes(service?._id) ? "0px 4px 36px 0px #00000029" : ""}`,
                  fontWeight: `${selectedServices.includes(service?._id) ? "600" : ""}`,
                }}
                onClick={() => selectServiceHandler(service)}
              >
                <Box
                  component="img"
                  sx={styles.eachServiceImage}
                  src={service.displayImage.url}
                  alt={service?._id}
                ></Box>
                <Typography
                  sx={{
                    ...styles.serviceName,
                    color: `${selectedServices.includes(service?._id) ? "#06038D" : "#0D0639"}`,
                  }}
                >
                  {service?.name}
                </Typography>
              </Box>
            </Grid2>
          ))}
          {services?.length === 0 && !loader && searchServicesText && (
            <TextError msg="No items matches your search." />
          )}
        </Grid2>
      </Box>
    </Box>
  );
};

export default AddBusinessComp;
