import dayjs, { Dayjs } from "dayjs";
import { ensureDayExists, keyToDayMapping } from "./calendar";
import {
  deepCopyServiceTiming,
  formatTimeSlots,
  removeFalsyIdsFromSlots,
  validateLastSlot,
} from "./availabilityHelpers";
import { ServiceTiming, TimeSlot } from "./interfaces";
import { Dispatch } from "redux";

export const getServiceTimingFromSlotsAndRepeatedDays = (
  dispatch: Dispatch,
  timeSlots: TimeSlot[],
  serviceTimingProp: ServiceTiming | undefined,
  repeatDays: string[],
  removedSlots: TimeSlot[]
): ServiceTiming | undefined => {
  if (validateLastSlot(dispatch, timeSlots)) {
    const updatedServiceTiming = deepCopyServiceTiming(serviceTimingProp);
    const formattedSlots = formatTimeSlots(timeSlots);

    // step:1 Process all slots for days with specific dates (monthDate)
    processSlotsWithMonthDate(formattedSlots, updatedServiceTiming);

    // step:2 Process repeatDays (without specific dates and only days)
    processRepeatDaysSlots(formattedSlots, repeatDays, updatedServiceTiming);

    // step:3 Handle removed slots-Removed slots are kept separately in removedSlots array. So remove them from serviceTiming
    handleRemovedSlots(removedSlots, repeatDays, updatedServiceTiming);

    //step4:remove any duplications if any
    removeFalsyIdsFromSlots(updatedServiceTiming);
    return updatedServiceTiming;
  }
};
// step:1
// Process slots with specific dates (monthDate)
export const processSlotsWithMonthDate = (
  formattedSlots: TimeSlot[],
  updatedServiceTiming: ServiceTiming
): void => {
  formattedSlots.forEach((slot: TimeSlot) => {
    if (slot.monthDate) {
      const dayOfWeek = dayjs(slot.monthDate).day(); // Get the day of the week (0-6)
      const day = keyToDayMapping[dayOfWeek]; // Get corresponding day name

      if (day) {
        ensureDayExists(day, updatedServiceTiming);

        // Handle the current slot (with monthDate)
        processSlotForSpecificDay(slot, day, updatedServiceTiming);
      }
    }
  });
};

// Process each individual slot with a specific monthDate while p
const processSlotForSpecificDay = (
  slot: TimeSlot,
  day: string,
  updatedServiceTiming: ServiceTiming
): void => {
  const existingSlots = updatedServiceTiming[day].slots || [];
  const existingSlotIndex = existingSlots.findIndex(
    (existingSlot: TimeSlot) =>
      (existingSlot._id === slot._id &&
        existingSlot?._id &&
        existingSlot.monthDate === slot.monthDate) ||
      existingSlot.startTime === null
  );
  if (existingSlotIndex !== -1) {
    updatedServiceTiming[day].slots[existingSlotIndex] = { ...slot };
  } else {
    updatedServiceTiming[day].slots.push({ ...slot });
  }
};

// step:2
// Add general (repeat) slots without monthDate to a day
export const processRepeatDaysSlots = (
  formattedSlots: TimeSlot[],
  repeatDays: string[], // Specify more accurate type if possible
  updatedServiceTiming: ServiceTiming
): void => {
  repeatDays.forEach((day: string) => {
    ensureDayExists(day, updatedServiceTiming); // Ensure the day exists
    addGeneralSlotsWithoutDate(formattedSlots, day, updatedServiceTiming);
  });
};

// step:3
export const handleRemovedSlots = (
  removedSlots: TimeSlot[],
  repeatDays: string[],
  updatedServiceTiming: ServiceTiming
): void => {
  if (removedSlots && removedSlots.length > 0) {
    // Format the removed slots to compare properly
    const formattedRemovedSlots = formatRemovedSlots(removedSlots);
    const specificDate = removedSlots[0]?.monthDate;

    if (specificDate) {
      const day = dayjs(specificDate).format("dddd").toLowerCase(); // Get the day name from the date
      updateSlotsForDay(day, formattedRemovedSlots, updatedServiceTiming);
    } else {
      repeatDays.forEach((day: string) => {
        updateSlotsForDay(day, formattedRemovedSlots, updatedServiceTiming);
      });
    }
  }
};

const addGeneralSlotsWithoutDate = (
  formattedSlots: TimeSlot[],
  day: string,
  updatedServiceTiming: ServiceTiming
): void => {
  const existingSlots = updatedServiceTiming[day].slots || [];
  formattedSlots.forEach((slot: TimeSlot) => {
    if (!slot.monthDate) {
      const newSlot = createNewSlot(slot);

      const existingSlotIndex = existingSlots.findIndex(
        (existingSlot: TimeSlot) =>
          existingSlot._id && existingSlot._id === newSlot._id
      );
      const hasSameTimeSlot = existingSlots.some(
        (existingSlot: TimeSlot) =>
          existingSlot.startTime === newSlot.startTime &&
          existingSlot.endTime === newSlot.endTime &&
          !existingSlot.monthDate
      );

      if (existingSlotIndex !== -1) {
        existingSlots[existingSlotIndex] = { ...newSlot };
      } else if (!hasSameTimeSlot) {
        existingSlots.push(newSlot);
      }
    }
  });
};

// Helper function to create a new slot object
export const createNewSlot = (slot: TimeSlot): TimeSlot => {
  return {
    startTime: slot.startTime,
    endTime: slot.endTime,
    _id: slot._id,
  };
};

const updateSlotsForDay = (
  day: string,
  formattedRemovedSlots: TimeSlot[],
  updatedServiceTiming: ServiceTiming
): void => {
  const existingSlots = updatedServiceTiming[day]?.slots || [];
  // Filter out the removed slots for the given day
  updatedServiceTiming[day].slots = existingSlots.filter(
    (existingSlot: TimeSlot) =>
      !formattedRemovedSlots.some(
        (removedSlot: TimeSlot) =>
          removedSlot.startTime === existingSlot.startTime &&
          removedSlot.endTime === existingSlot.endTime &&
          (removedSlot.monthDate === null ||
            removedSlot.monthDate === existingSlot.monthDate)
      )
  );
};

// Helper function to format removedSlots for comparison
const formatRemovedSlots = (removedSlots: TimeSlot[]): TimeSlot[] => {
  return removedSlots.map((slot: TimeSlot) => ({
    startTime:
      slot.startTime instanceof Dayjs ? slot.startTime.format("h:mm A") : "",
    endTime: slot.endTime instanceof Dayjs ? slot.endTime.format("h:mm A") : "",
    monthDate: slot.monthDate || null, // Ensure monthDate is included for comparison
  }));
};
