import { Outlet } from "react-router-dom";
import { SearchProvider } from "../context/SearchContext";

const SearchProviderWrapper = () => (
  <SearchProvider>
    <Outlet />
  </SearchProvider>
);

export default SearchProviderWrapper;
