import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import { Box } from "@mui/material";
import { routePaths } from "../../../router/routePaths";
import { useDispatch } from "react-redux";
import { getSubCategories } from "../../../network/services";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";
import useDebounce from "../../../hooks/useDebounce";
import AddSubcatComp, {
  Categories,
  ResponseCategories,
} from "../../../components/AddSubcatComp/AddSubcatComp";
import { deriveSubCategoryIds } from "../../../utils/profileHelpers";

const SelectServiceSubCategory = () => {
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(
    []
  );
  const [staticSelectedSubCategories, setStaticSelectedSubCategories] =
    useState<string[]>([]);
  const [categoryIds, setCategoryIds] = useState<string[]>([]);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [searchSubcatText, setSearchSubcatText] = useState<string>("");
  const debouncedSearchSubcatText = useDebounce(searchSubcatText, 500);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getProfileDetails = async () => {
      try {
        dispatch(setLoading(true));
        const res = await getUserProfile();
        dispatch(setUserData(res.data));
        setCategoryIds(res?.data?.categoryIds);
        // selectedSubcategories contains all selected subcategories of previous selected categories even if they were deselected
        //  staticSelectedSubCategories is used to store only currently selected categories' subcategories
        setSelectedSubCategories(res?.data?.subCategoryIds || []);
        setStaticSelectedSubCategories(res?.data?.subCategoryIds || []);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setLoading(false));
      }
    };
    getProfileDetails();
  }, []);

  const deriveCategoryIdsHandler = (
    categories: ResponseCategories | undefined
  ) => {
    return deriveSubCategoryIds(
      categories,
      categoryIds,
      staticSelectedSubCategories
    );
  };

  useEffect(() => {
    const fetchSubCategories = async (textArg: string) => {
      try {
        const payload = {
          categoryIds: categoryIds || [],
          keyword: debouncedSearchSubcatText,
        };
        const res = await getSubCategories(payload);
        const subCategoryIds = deriveCategoryIdsHandler(res);
        setSelectedSubCategories(subCategoryIds);
        if (!textArg) setStaticSelectedSubCategories(subCategoryIds);
        setCategories(res?.data);
      } catch (err) {
        console.log(err);
      }
    };
    // fetches categories with subcategories for each categories
    fetchSubCategories(debouncedSearchSubcatText);
  }, [debouncedSearchSubcatText, categoryIds]);

  const selectCategoryHandler = async () => {
    try {
      const payload = { subCategoryIds: staticSelectedSubCategories };
      await updateUserProfile(payload);
      dispatch(setUserData(payload));
      navigate(routePaths.addBusinessDetails);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className="selectSubServiceCategoryForm">
      <ProfileWrapper
        title="Select Service Sub Category"
        btnDisabled={staticSelectedSubCategories?.length === 0}
        onSubmit={selectCategoryHandler}
        stepperCount={4}
      >
        <AddSubcatComp
          searchSubcatText={searchSubcatText}
          selectedSubCategories={selectedSubCategories}
          staticSelectedSubCategories={staticSelectedSubCategories}
          categories={categories}
          setSelectedSubCategories={setSelectedSubCategories}
          setStaticSelectedSubCategories={setStaticSelectedSubCategories}
          setSearchSubcatText={setSearchSubcatText}
        />
      </ProfileWrapper>
    </Box>
  );
};

export default SelectServiceSubCategory;
