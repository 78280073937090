import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Container,
  Grid2,
  Typography,
  Button,
  Pagination,
  PaginationItem,
  FormControl,
} from "@mui/material";
import "./search.css";
import {
  getSubCategories,
  getServiceProviders,
  getServices,
} from "../../../../network/services";
import styles from "./style";
import searchArrow from "../../../../assets/ExpertServiceProvider/searchArrow.svg";
import {
  SearchContext,
  ServiceProvider,
  SubCategory,
  Category,
} from "../../../../context/SearchContext";
import dropdownArrow from "../../../../assets/ExpertServiceProvider/dropdownArrow.svg";
import prevIcon from "../../../../assets/Common/prevIcon.svg";
import nextIcon from "../../../../assets/Common/nextIcon.svg";
import { setLoading } from "../../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import LocationModal from "./LocationModal";
import NoResultsView from "./NoResultsView";
import SearchResults from "./SearchResults";
import SelectServiceDropdown from "./SelectServiceDropdown";
import SelectCategoryDropdown from "./SelectCategoryDropdown";
import { GetServiceProvidersPayload } from "../../../../network/APIRequestType";
import { getAddressFromCoordinates } from "../../../../utils/getAddressFromCoordinates";

const PreviousSlot = ({ onPreviousClick }: { onPreviousClick: () => void }) => (
  <Box sx={styles.paginationPrevious} onClick={onPreviousClick}>
    <img src={prevIcon} alt="Prev Icon" />
    <Box sx={styles.paginationText}>Previous</Box>
  </Box>
);

const NextSlot = ({
  page,
  totalPages,
  onNextClick,
}: {
  page: number;
  totalPages: number;
  onNextClick: () => void;
}) => (
  <Box
    sx={styles.paginationNext}
    onClick={onNextClick}
    style={{ pointerEvents: page === totalPages ? "none" : "auto" }}
  >
    <Box sx={styles.paginationText}>Next</Box>
    <img src={nextIcon} alt="Next Icon" />
  </Box>
);

const Search: React.FC = () => {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }
  const { data, filters, updateFilter, updateData } = searchContext;
  const [isCategorySelected, setIsCategorySelected] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [locationModal, setLocationModal] = React.useState(false);
  const location = sessionStorage.getItem("location");
  const resultsRef = useRef<HTMLDivElement>(null);
  const paginationSlots = {
    previous: PreviousSlot,
    next: NextSlot,
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    updateData("page", newPage);
    if (resultsRef.current) {
      window.requestAnimationFrame(() => {
        resultsRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
  };

  const handlePreviousClick = () => {
    if (data.page > 1) {
      updateData("page", data.page - 1);
    }
  };

  const handleNextClick = () => {
    if (data.page < data.totalPages) {
      updateData("page", data.page + 1);
    }
  };

  const handleClickLocationModalOpen = () => {
    setLocationModal(true);
  };

  const handleSubCategoryClick = (value: string) => {
    if (filters.selectedSubCategories.includes(value)) {
      const updatedSubCategories = filters.selectedSubCategories.filter(
        (subCategory: string) => subCategory !== value
      );
      updateFilter("selectedSubCategories", updatedSubCategories);
    } else {
      updateFilter("selectedSubCategories", [
        ...filters.selectedSubCategories,
        value,
      ]);
    }
  };

  const handleSearchButtonClick = async () => {
    dispatch(setLoading(true));
    updateData("page", 1);
    const newPayload = {
      zipCode:
        filters.zipCode !== "Unknown Zip Code"
          ? filters.zipCode
          : filters.searchedAddress,
      page: data.page,
      limit: 12,
      subCategoryIds: filters.selectedSubCategories,
    };
    await getServiceProvidersList(newPayload);
    dispatch(setLoading(false));
    updateData("isSearchClicked", true);
    setTimeout(() => {
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const getServicesList = async () => {
    try {
      const response = await getServices();
      updateData("services", response.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  useEffect(() => {
    getServicesList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data.page]);

  const getSubCategoriesList = async () => {
    const selectedCategoryData = data.category.find(
      (item: Category) => item.categoryName === filters.selectedCategory
    );

    const selectedCategoryId = selectedCategoryData?._id;

    if (typeof selectedCategoryId === "string") {
      // Update the filter with the valid category ID
      updateFilter("selectedCategoryId", selectedCategoryId);

      // Fetch subcategories based on the selected category
      const response = await getSubCategories({
        categoryIds: [selectedCategoryId],
      });

      const fetchedData = await response.data;
      updateData("subCategory", fetchedData[0]?.subcategories || []);
    } else {
      // Handle the case where no valid category ID is found
      console.warn("Invalid selected category ID.");
      updateFilter("selectedCategoryId", null);
      updateData("subCategory", []);
    }
  };

  useEffect(() => {
    getSubCategoriesList();
  }, [filters.selectedCategory]);

  const getServiceProvidersList = async (
    payload: GetServiceProvidersPayload
  ) => {
    const response = await getServiceProviders(payload);
    const data = await response.data;
    updateData("serviceProviders", data.userList);
    updateData("totalRecordsCount", response.totalCount);
    updateData("totalPages", Math.ceil(response.data.totalCount / 12));
  };
  useEffect(() => {
    if (isCategorySelected) {
      updateFilter("selectedSubCategories", []);
    }
  }, [filters.selectedCategory]);

  useEffect(() => {
    const newPayload = {
      zipCode:
        filters.zipCode !== "Unknown Zip Code"
          ? filters.zipCode
          : filters.searchedAddress,
      page: data.page,
      limit: 12,
      subCategoryIds: filters.selectedSubCategories,
    };
    if (data.isSearchClicked) {
      getServiceProvidersList(newPayload);
    }
  }, [data.isSearchClicked, data.page]);

  useEffect(() => {
    if (location && data?.serviceProviders?.length > 0) {
      updateData("serviceProviders", []);
      updateData("totalRecordsCount", 0);
    }
  }, [location]);
  const [locationDetails, setLocationDetails] = useState<string>(
    location ? location : "Select Location"
  );
  useEffect(() => {
    if (!sessionStorage.getItem("location")) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            await getAddressFromCoordinates(
              position.coords.latitude,
              position.coords.longitude,
              updateFilter
            );
          },
          (error) => {
            console.error("Error getting location", error);
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
        setLocationDetails(
          sessionStorage.getItem("location") || "Select Location"
        );
      }
    }
  }, [sessionStorage.getItem("location")]);

  return (
    <>
      <Box sx={styles.background}>
        <Container sx={styles.container} maxWidth="xl">
          <Box sx={styles.boxShadow}>
            <Box sx={styles.flexDirection}>
              <Box sx={styles.fullWidth}>
                <SelectServiceDropdown />
                <SelectCategoryDropdown
                  setIsCategorySelected={setIsCategorySelected}
                />
                <FormControl
                  sx={styles.formControlLocation}
                  fullWidth
                  variant="outlined"
                  className="selectDropdown"
                  onClick={handleClickLocationModalOpen}
                >
                  <Box sx={styles.locationBox}>
                    <span className="selectPlaceholder">
                      {filters.location ? filters.location : locationDetails}
                    </span>
                    <img
                      src={dropdownArrow}
                      alt="arrow"
                      className="downArrowIcon"
                    />
                  </Box>
                </FormControl>
              </Box>
              <Box sx={styles.searchButtonBox}>
                <Button
                  disabled={
                    !(
                      filters.selectedService &&
                      filters.selectedCategory &&
                      filters.selectedSubCategories.length > 0 &&
                      filters.zipCode &&
                      filters.location
                    )
                  }
                  sx={styles.searchButton}
                  onClick={handleSearchButtonClick}
                >
                  Search
                  <Box
                    sx={{
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={searchArrow}
                      alt="search arrow"
                      width="24"
                      height="14"
                    />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
          {filters.selectedService &&
            filters.selectedCategory &&
            filters.selectedSubCategories.length > 0 &&
            (!filters.zipCode || !filters.location) && (
              <Box
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Typography sx={styles.errorMessage}>
                  Please enter a valid zip code and select address to get the
                  desired service providers list.
                </Typography>
              </Box>
            )}
          <Grid2 sx={styles.gridContainer} container>
            <>
              {data.subCategory?.map((item: SubCategory) => (
                <Grid2
                  key={item._id}
                  sx={{
                    paddingLeft: {
                      sm: "16px !important",
                      xs: "0 !important",
                    },
                    paddingBottom: "16px",
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                >
                  <Button
                    sx={{
                      ...styles.subCategoryButton,
                      color: filters.selectedSubCategories.includes(item._id)
                        ? "#FFFFFF"
                        : "#8989A3",
                      backgroundColor: filters.selectedSubCategories.includes(
                        item._id
                      )
                        ? "#1CB469"
                        : "transparent",
                    }}
                    onClick={() => handleSubCategoryClick(item._id)}
                  >
                    {item.subCategoryName}
                  </Button>
                </Grid2>
              ))}
            </>
          </Grid2>
          <Grid2
            container
            spacing={2}
            sx={{
              transform: "translateY(-10px)",
              width: "100%",
              margin: "auto",
            }}
            ref={resultsRef}
          >
            {data.serviceProviders.length !== 0 &&
              data.serviceProviders.map((serviceProvider: ServiceProvider) => (
                <SearchResults
                  serviceProvider={serviceProvider}
                  key={serviceProvider._id}
                />
              ))}
            {data.serviceProviders.length === 0 && data.isSearchClicked && (
              <NoResultsView />
            )}
            <Grid2
              size={{ xs: 12 }}
              sx={{
                paddingLeft: {
                  md: "16px !important",
                  xs: "0 !important",
                },
              }}
            >
              {data.totalRecordsCount > 12 && (
                <Box sx={styles.paginationContainer}>
                  <Pagination
                    count={Math.ceil(data.totalRecordsCount / 12)}
                    page={data.page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        slots={paginationSlots}
                        slotProps={{
                          previous: { onClick: handlePreviousClick },
                          next: {
                            onClick: handleNextClick,
                            disabled: data.page === data.totalPages,
                          },
                        }}
                        sx={{
                          ...(item.selected && styles.paginationItem),
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      <LocationModal
        locationModal={locationModal}
        setLocationModal={setLocationModal}
      />
    </>
  );
};

export default Search;
