import React, { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import "./setting.css";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import { useNavigate } from "react-router-dom";
import DashboardFooter from "../../../components/DashboardFooter/DashboardFooter";
import styles from "./style";
import dashboardStyles from "../Dashboard/style";
import {
  deleteAccount,
  getUserProfile,
  updateUserProfile,
} from "../../../network/user";
import { uploadImage } from "../../../network/common";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/commonSlice";
import ChangePassword from "./ChangePassword";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { routePaths } from "../../../router/routePaths";
import { validateField } from "../../../utils/validationUtils";

import deleteStatusIcon from "../../../assets/Settings/deleteStatusIcon.png";
import DashboardMobileHeader from "../../../components/DashboardHeader/DashboardMobileHeader";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";
import "cropperjs/dist/cropper.css";
import {
  setEditingProfile,
  setUpdatedFields,
  setUserData,
} from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/rootReducer";
import { formatInput } from "../../../utils/formatUtils";
import SuccessModal from "../../../components/Modal/SuccessModal";
import TabsSection from "./TabsSection";
import EditProfileImage from "./EditProfileImage";
import { TabPanel } from "./TabPanel";
import EditServiceImages from "./EditServiceImages";
import EditLicense from "./EditLicense";
import EditServiceCategories from "./EditServiceCategories";
import EditAddress from "./EditAddress";
import EditTitleDescription from "./EditTitleDescription";
import EditPersonalDetails from "./EditPersonalDetails";

const Settings: React.FC = () => {
  const [value, setValue] = useState<number>(0);
  const userProfile = useSelector((state: RootState) => state.user.userData);
  const [openImageCropper, setOpenImageCropper] = useState<boolean>(false);
  const [image, setImage] = useState<string | undefined>(
    userProfile?.profilePic
  );
  const isEditingProfile = useSelector(
    (state: RootState) => state.user.isEditingProfile
  );
  const updatedFields = useSelector(
    (state: RootState) => state.user.updatedFields
  );

  const [updateDetailsSuccessModal, setUpdateDetailsSuccessModal] =
    useState<string>("");
  const [deleteAccountModal, setDeleteAccountModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backClicked = useSelector(
    (state: RootState) => state.user.userData?.backClicked
  );

  useEffect(() => {
    const isProfileCompletion = localStorage.getItem("isProfileCompletion");
    if (isProfileCompletion !== "true") {
      navigate(routePaths.signIn);
    }
  }, [navigate]);

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(setLoading(true));
    const res = await uploadImage(formData);
    dispatch(
      setUpdatedFields({
        profileImage: {
          url: res?.data?.fileUrl as string,
          key: res?.data?.key as string,
        } as Record<string, string>,
      })
    );
    dispatch(
      setUserData({
        profilePic: res?.data?.fileUrl,
      })
    );
    dispatch(setLoading(false));
    setOpenImageCropper(false);
  };

  const handleDialogClose = () => {
    setOpenImageCropper(false);
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, type } = event.target;
    let { value } = event.target;

    if (name === "fullName" || name === "phoneNumber") {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "description") {
      value = formatInput(name, value, 1000);
    }
    if (name === "businessTitle") {
      value = formatInput(name, value, 40);
    }

    if (name === "phoneNumber" && !value.startsWith("+1")) {
      value = `+1${value.replace(/^\+?/, "")}`;
    }

    const errorMessage = validateField(name, value);

    if (type === "file" && name === "profilePic") {
      setOpenImageCropper(true);
      event.preventDefault();
      const files = event.target.files;
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      if (files) {
        reader.readAsDataURL(files[0]);
      }
      event.target.value = "";
    } else {
      dispatch(setUpdatedFields({ [name]: value }));
      dispatch(
        setUserData({
          [name]: value,
          errors: { ...userProfile?.errors, [name]: errorMessage },
        })
      );
    }
  };

  const handleEditClick = (field: string) => {
    if (field === "services") {
      navigate(routePaths.editBusiness);
    } else if (field === "address") {
      navigate(routePaths.editAddress);
    } else {
      dispatch(setEditingProfile({ [field]: true }));
    }
  };

  const handleClickUpdateDetailsModalClose = () => {
    setUpdateDetailsSuccessModal("");
  };

  const handleClickUpdateDetailsModalOpen = async () => {
    setIsLoading(true);
    const response = await updateUserProfile(updatedFields);
    if (response.type === "success") {
      setIsLoading(false);
      setUpdateDetailsSuccessModal(
        "Your details has been updated successfully!"
      );
      dispatch(
        setEditingProfile({
          profilePic: false,
          fullName: false,
          phoneNumber: false,
          email: false,
          services: false,
          categories: false,
          subCategories: false,
          businessTitle: false,
          description: false,
          address: false,
        })
      );
    }
  };

  const handleClickDeleteAccountModalClose = () => {
    setDeleteAccountModal(false);
  };

  const getProfileDetails = async (isEdited: boolean) => {
    const response = await getUserProfile();
    const data = response.data;
    if (!isEdited) {
      dispatch(
        setUserData({
          profilePic: data.profileImage?.url,
          fullName: data.fullName,
          phoneNumber: data.phoneNumber,
          email: data.email,
          serviceNames: data.serviceDetails
            .map((service: { name: string }) => service.name)
            .join(", "),
          categoryDetails: data.categoryDetails,
          subCategoryDetails: data.subCategoryDetails,
          serviceImages: data.businessImages,
          businessTitle: data.businessTitle,
          description: data.description,
          businessDocuments: data.businessDocuments,
          address: data.address,
          city: data.city,
          province: data.province,
          zipCode: data.zipCode,
          zipCodes: data?.zipCodes?.join(","),
          isProfileCompleted: data.isProfileCompletion,
          errors: {
            phoneNumber: "",
            email: "",
            fullName: "",
            zipCode: "",
            message: "",
            address: "",
            city: "",
            state: "",
            businessTitle: "",
          },
        })
      );
    }

    if (isEdited || backClicked) {
      dispatch(
        setUserData({
          serviceNames: data.serviceDetails
            .map((service: { name: string }) => service.name)
            .join(", "),
          categoryDetails: data.categoryDetails,
          subCategoryDetails: data.subCategoryDetails,
          serviceImages: data.businessImages,
          businessDocuments: data.businessDocuments,
          address: data.address,
          city: data.city,
          province: data.province,
          zipCode: data.zipCode,
          zipCodes: data?.zipCodes?.join(","),
          serviceIds: data.serviceIds,
          categoryIds: data.categoryIds,
          subCategoryIds: data.subCategoryIds,
          isProfileCompleted: data.isProfileCompletion,
        })
      );
    }
  };

  useEffect(() => {
    const isEdited = Object.values(isEditingProfile).some(
      (value) => value === true
    );
    getProfileDetails(isEdited);
  }, [backClicked]);

  useEffect(() => {
    const hasErrors = Object.values(userProfile?.errors || "").some(
      (error) => error !== ""
    );
    if (
      !hasErrors &&
      userProfile?.fullName !== "" &&
      userProfile?.phoneNumber !== "" &&
      userProfile?.email !== "" &&
      userProfile?.businessTitle !== "" &&
      userProfile?.description !== ""
    ) {
      dispatch(setUserData({ disabled: false }));
    } else {
      dispatch(setUserData({ disabled: true }));
    }
  }, [
    userProfile?.errors,
    userProfile?.fullName,
    userProfile?.phoneNumber,
    userProfile?.email,
    userProfile?.businessTitle,
    userProfile?.description,
    dispatch,
  ]);

  const deleteAccountHandler = async () => {
    try {
      dispatch(setLoading(true));
      await deleteAccount();
      localStorage.clear();
      setDeleteAccountModal(false);
      navigate(routePaths.signIn);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={dashboardStyles.dashboardMobileHeader}>
        <DashboardMobileHeader />
      </Box>
      <Box sx={dashboardStyles.dashboardHeaderDiv}>
        <DashboardHeader />
      </Box>
      <Box className="settingsPage" sx={styles.settingPage}>
        <Box sx={styles.settingsBox}>
          <Typography variant="h3" sx={styles.settingsTitle}>
            Settings
          </Typography>
        </Box>
        <Box className="settingTabs" sx={styles.settingTabs}>
          <Box sx={styles.stickyBox}>
            <TabsSection
              value={value}
              setValue={setValue}
              setDeleteAccountModal={setDeleteAccountModal}
            />
          </Box>
          <TabPanel value={value} index={0}>
            <Box>
              <Box>
                <Box sx={styles.generalDetailsTitle}>General Details</Box>
                <EditProfileImage
                  handleInputChange={handleInputChange}
                  handleEditClick={handleEditClick}
                />
                <EditPersonalDetails
                  handleInputChange={handleInputChange}
                  handleEditClick={handleEditClick}
                  isEditingPhoneNumber={isEditingProfile?.phoneNumber}
                  isEditingFullName={isEditingProfile?.fullName}
                  isEditingEmail={isEditingProfile?.email}
                />
              </Box>
              <Box>
                <EditServiceCategories
                  handleInputChange={handleInputChange}
                  handleEditClick={handleEditClick}
                  isEditingProfileServices={isEditingProfile?.services}
                />
                <EditServiceImages />
                <EditTitleDescription
                  handleInputChange={handleInputChange}
                  handleEditClick={handleEditClick}
                  isEditingTitle={isEditingProfile?.businessTitle}
                  isEditingDescription={isEditingProfile?.description}
                />
                <EditLicense handleInputChange={handleInputChange} />
                <EditAddress
                  handleInputChange={handleInputChange}
                  handleEditClick={handleEditClick}
                  isEditingAddress={isEditingProfile?.address}
                />

                {isLoading ? (
                  <Box sx={styles.saveButtonContainer}>
                    <Button sx={styles.saveButton}>
                      <CircularProgress size={40} />
                    </Button>
                  </Box>
                ) : (
                  <Box sx={styles.saveButtonContainer}>
                    <Button
                      sx={styles.saveButton}
                      onClick={handleClickUpdateDetailsModalOpen}
                      disabled={userProfile?.disabled}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassword />
          </TabPanel>
        </Box>
        <Box sx={dashboardStyles.dashboardFooterPadding}>
          <DashboardFooter />
        </Box>

        {updateDetailsSuccessModal && (
          <SuccessModal
            content={updateDetailsSuccessModal}
            onClose={handleClickUpdateDetailsModalClose}
            onSuccess={handleClickUpdateDetailsModalClose}
          />
        )}
        {image && (
          <ImageCropper
            open={openImageCropper}
            image={image}
            onCrop={handleImageUpload}
            onCancel={handleDialogClose}
          />
        )}

        {deleteAccountModal && (
          <ConfirmationModal
            content={" Are you sure you want to delete this account?"}
            onReject={handleClickDeleteAccountModalClose}
            onAccept={deleteAccountHandler}
            imgSrc={deleteStatusIcon}
          />
        )}
      </Box>
    </Box>
  );
};

export default Settings;
