import dayjs, { Dayjs } from "dayjs";
import { DayjsTimeSlot, DisplaySlot, ServiceTiming } from "./interfaces";

// Get days from the previous month to display in the current view
export const getPreviousMonthDays = (month: Dayjs): Dayjs[] => {
  const firstDayOfMonth = month.startOf("month").day();
  const previousMonth = month.subtract(1, "month");
  const daysInPreviousMonth = previousMonth.daysInMonth();

  const previousMonthDays: Dayjs[] = [];
  for (let i = firstDayOfMonth - 1; i >= 0; i--) {
    previousMonthDays.push(previousMonth.date(daysInPreviousMonth - i));
  }
  return previousMonthDays;
};

// Get days from the next month to display in the current view
export const getNextMonthDays = (month: Dayjs): Dayjs[] => {
  const lastDayOfMonth = month.endOf("month").day();
  const nextMonth = month.add(1, "month");
  const nextMonthDays: Dayjs[] = [];

  for (let i = 1; i <= 6 - lastDayOfMonth; i++) {
    nextMonthDays.push(nextMonth.date(i));
  }
  return nextMonthDays;
};

// Get all days in the current month (along with previous and next month days)
export const getDaysInMonth = (month: Dayjs): Dayjs[] => {
  const daysInMonth: Dayjs[] = [];
  const startOfMonth = month.startOf("month");
  const days = month.daysInMonth();

  for (let i = 0; i < days; i++) {
    daysInMonth.push(startOfMonth.add(i, "day"));
  }

  return daysInMonth;
};

// function to handle dates scroll in the monthly calendar
export const scrollDays = (
  direction: "left" | "right",
  daysContainerRef: React.RefObject<HTMLDivElement | null>,
  daysInMonth: Dayjs[]
): void => {
  if (daysContainerRef.current) {
    const container = daysContainerRef.current;
    const scrollAmount = 300; // Adjust this based on your container width
    const currentScrollPosition = container.scrollLeft;

    if (direction === "left") {
      // Scroll to the left if possible
      if (currentScrollPosition > 0) {
        container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      }
    } else if (direction === "right") {
      // Ensure no extra space after the last day
      const lastDayRef = container.querySelector(
        `[data-day="${daysInMonth.length}"]`
      );
      if (lastDayRef) {
        const lastDayPosition = (lastDayRef as HTMLElement).offsetLeft;

        if (
          currentScrollPosition + container.clientWidth <
          lastDayPosition + lastDayRef.clientWidth
        ) {
          container.scrollBy({
            left: Math.min(
              scrollAmount,
              lastDayPosition +
                lastDayRef.clientWidth -
                (currentScrollPosition + container.clientWidth)
            ),
            behavior: "smooth",
          });
        }
      }
    }
  }
};

//  Helper function to get the weekday name from a date
export const getDayOfWeek = (dateString: string): string => {
  const date = new Date(dateString);
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  return days[date.getDay()];
};

export const dayMapping: Record<
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday",
  number
> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const keyToDayMapping = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

// Helper function to ensure day entry exists in updatedServiceTiming
export const ensureDayExists = (
  day: string,
  updatedServiceTiming: ServiceTiming
): void => {
  if (!updatedServiceTiming[day]) {
    updatedServiceTiming[day] = { isAvailable: true, slots: [] };
  }
};

// function to sort slots in the add availability modal
export const sortSlotsByStartTime = (
  slots: DayjsTimeSlot[]
): DayjsTimeSlot[] => {
  return slots.sort((a, b) => {
    if (
      a.startTime &&
      b.startTime &&
      dayjs.isDayjs(a.startTime) &&
      dayjs.isDayjs(b.startTime)
    ) {
      if (a.startTime.isBefore(b.startTime)) return -1;
      if (a.startTime.isAfter(b.startTime)) return 1;
      return 0;
    }

    if (!a.startTime) return 1;
    if (!b.startTime) return -1;

    return 0;
  });
};

// Function to sort slots based when displayed in UI
export const sortDisplaySlotsByTime = (slots: DisplaySlot[]): DisplaySlot[] => {
  return slots.sort((a, b) => {
    const timeA = dayjs(a.startTime, "h:mm A");
    const timeB = dayjs(b.startTime, "h:mm A");

    if (timeA.isBefore(timeB)) return -1;
    if (timeA.isAfter(timeB)) return 1;
    return 0;
  });
};
