interface AccordionData {
  title: string;
  color: string;
  content: string;
}

export const accordionData: AccordionData[] = [
  {
    title: "Repairs",
    color: "#0AF4B5",
    content:
      "We have a list of contractors to help you with all types of repairs - from minor adjustments to major repairs.",
  },
  {
    title: "Remodelling",
    color: "#FE9D14",
    content:
      "Whether you're considering a complete home remodel to minor upgrades to a room, connect with our list of contractors to assist.",
  },
  {
    title: "Staging",
    color: "#FA5D29",
    content:
      "If you are considering staging your home for sale or looking for an interior designer, connect with the list of staging companies under service providers.",
  },
  {
    title: "Mortgages",
    color: "#96FB7D",
    content:
      "From purchasing a new home or refinancing, review the list of lending and banking institutions to help you.",
  },
  {
    title: "Financial Advisors",
    color: "#417FFF",
    content:
      "Looking for wealth management advice and services, connect with the array of financial advisors.",
  },
  {
    title: "Appraisals",
    color: "#2823FF",
    content:
      "Connecting with an Appraiser can assist with understanding the potential value of your home for estate planning, city/town tax disputes, refinancing, divorce settlement and property sales.",
  },
];
