import { Box, InputLabel, TextField } from "@mui/material";
import styles from "./style";
import { NavLink } from "react-router-dom";
import { routePaths } from "../../../router/routePaths";
import editIcon from "../../../assets/Settings/editIcon.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";

export interface Category {
  categoryName: string;
  displayImage: { url: string };
}

export interface SubCategory {
  subCategoryName: string;
}

interface EditServiceProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleEditClick: (field: string) => void;
  isEditingProfileServices: boolean;
}

const EditServiceCategories: React.FC<EditServiceProps> = ({
  handleInputChange,
  handleEditClick,
  isEditingProfileServices,
}) => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  if (!userProfile) return null; // Ensure userProfile exists

  return (
    <>
      {/* Service Name */}
      <Box className="formControl" sx={styles.formControl}>
        <InputLabel className="inputLable">Service Name</InputLabel>
        <TextField
          name="service"
          value={userProfile?.serviceNames}
          placeholder="Service Name"
          onChange={handleInputChange}
          disabled={!isEditingProfileServices}
        />
        <Box onClick={() => handleEditClick("services")} className="editIcon">
          <img src={editIcon} alt="Edit Icon" />
        </Box>
      </Box>

      {/* Service Category */}
      <Box className="formControl" sx={styles.formControl}>
        <Box sx={styles.serviceCategoryBox}>
          <InputLabel className="inputLable">Service Category</InputLabel>
          <NavLink to={routePaths.editServiceCategory}>
            <Box
              component="img"
              src={editIcon}
              alt="editIcon"
              className="boxEditIcon"
            ></Box>
          </NavLink>
        </Box>
        <Box sx={styles.serviceCategoryList}>
          {Array.isArray(userProfile?.categoryDetails) &&
            userProfile.categoryDetails.map((category: Category) => (
              <Box sx={styles.serviceCategoryItem} key={category.categoryName}>
                <Box sx={styles.serviceCategoryIcon}>
                  <img
                    src={category.displayImage.url}
                    width="100%"
                    alt={category.categoryName}
                  />
                </Box>
                {category.categoryName}
              </Box>
            ))}
        </Box>
      </Box>

      {/* Service Sub Category */}
      <Box className="formControl" sx={styles.formControl}>
        <Box sx={styles.serviceCategoryBox}>
          <InputLabel className="inputLable">Service Sub Category</InputLabel>
          <NavLink to={routePaths.editServiceSubCategory}>
            <Box
              component="img"
              src={editIcon}
              alt="editIcon"
              className="boxEditIcon"
            ></Box>
          </NavLink>
        </Box>
        <Box sx={styles.serviceCategoryList}>
          {Array.isArray(userProfile?.subCategoryDetails) &&
            userProfile.subCategoryDetails.map((subCategory: SubCategory) => (
              <Box
                sx={styles.serviceSubCategoryItem}
                key={subCategory.subCategoryName}
              >
                {subCategory.subCategoryName}
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default EditServiceCategories;
