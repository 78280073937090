import { Box, Button, IconButton, InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/commonSlice";
import { changePassword } from "../../../network/user";
import SuccessModal from "../../../components/Modal/SuccessModal";
import styles from "./style";
import showPasswordIcon from "../../../assets/Settings/show_password.svg";
import hidePasswordIcon from "../../../assets/Settings/hide_password.svg";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [passwordSucessModal, setPasswordSucessModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleClickPasswordSucessModalClose = () => {
    setPasswordSucessModal(false);
  };

  const handleClickPasswordSucessModalOpen = () => {
    setPasswordSucessModal(true);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .required("New password is required")
        .min(8, "Password must be at least 8 characters")
        .max(16, "Password cannot exceed 16 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[^a-zA-Z0-9]/,
          "Password must contain at least one special character"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        await changePassword({
          oldPassword: values?.oldPassword,
          newPassword: values?.newPassword,
        });
        handleClickPasswordSucessModalOpen();
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box className="formControl">
          <InputLabel className="inputLable">Old Password</InputLabel>
          <TextField
            type={showPassword ? "text" : "password"}
            placeholder="Enter Old Password"
            variant="outlined"
            fullWidth
            name="oldPassword"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
          <Box
            sx={styles.changePwdFormIconPosition}
            className={`passwordIcon ${formik.touched.oldPassword && formik.errors.oldPassword ? "passwordIconError" : ""}`}
          >
            <IconButton
              sx={styles.zeroPadding}
              onClick={handleTogglePasswordVisibility}
            >
              {showPassword ? (
                <Box
                  component="img"
                  src={showPasswordIcon}
                  alt="hidePasswordImage"
                />
              ) : (
                <Box
                  component="img"
                  src={hidePasswordIcon}
                  alt="showPasswordImage"
                />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box className="formControl" sx={{ position: "relative" }}>
          <InputLabel className="inputLable">New Password</InputLabel>
          <TextField
            type={showNewPassword ? "text" : "password"}
            placeholder="Password (min. 8 characters)"
            variant="outlined"
            fullWidth
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <Box
            sx={styles.changePwdFormIconPosition}
            className={`passwordIcon ${formik.touched.newPassword && formik.errors.newPassword ? "passwordIconError" : ""}`}
          >
            <IconButton
              sx={styles.zeroPadding}
              onClick={handleToggleNewPasswordVisibility}
            >
              {showNewPassword ? (
                <Box
                  component="img"
                  src={showPasswordIcon}
                  alt="hidePasswordImage"
                />
              ) : (
                <Box
                  component="img"
                  src={hidePasswordIcon}
                  alt="showPasswordImage"
                />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box className="formControl" sx={{ position: "relative" }}>
          <InputLabel className="inputLable">Confirm Password</InputLabel>
          <TextField
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Password (min. 8 character)"
            variant="outlined"
            fullWidth
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Box
            sx={styles.changePwdFormIconPosition}
            className={`passwordIcon ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "passwordIconError" : ""}`}
          >
            <IconButton
              sx={styles.zeroPadding}
              onClick={handleToggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? (
                <Box
                  component="img"
                  src={showPasswordIcon}
                  alt="hidePasswordImage"
                />
              ) : (
                <Box
                  component="img"
                  src={hidePasswordIcon}
                  alt="showPasswordImage"
                />
              )}
            </IconButton>
          </Box>
        </Box>
        <Button type="submit" sx={styles.changePasswordSaveBtn}>
          Save
        </Button>
      </form>
      {passwordSucessModal && (
        <SuccessModal
          content={"Your password has been changed successfully!"}
          onClose={handleClickPasswordSucessModalClose}
          onSuccess={handleClickPasswordSucessModalClose}
        />
      )}
    </Box>
  );
};

export default ChangePassword;
