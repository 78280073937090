import {
  Box,
  Container,
  Typography,
  Grid2,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { styles } from "./style";
import { useState } from "react";
import { accordionData } from "./helper";
import FlexibleAndEasyIcon from "../../../../../assets/AboutUs/FlexibleAndEasyIcon.svg";
import menuOfServiceImage from "../../../../../assets/AboutUs/menuOfServiceImage.png";
import menuOfServiceVectorImage from "../../../../../assets/AboutUs/menuOfServiceVectorImage.png";
import minusAccordingIcon from "../../../../../assets/AboutUs/minusAccordingIcon.svg";
import plusAccordingIcon from "../../../../../assets/AboutUs/plusAccordingIcon.svg";
import servicesIcon from "../../../../../assets/AboutUs/servicesIcon.svg";
import profitableDeals from "../../../../../assets/AboutUs/profitableDeals.svg";

const MenuOfServices = () => {
  const [expandedPanel, setExpandedPanel] = useState<string>(
    accordionData[0].title
  );

  const handleChange = (panel: string) => () => {
    setExpandedPanel(expandedPanel === panel ? "" : panel);
  };

  const [isMenuOfServiceImageLoading, setIsMenuOfServiceImageLoading] =
    useState(true);

  return (
    <Box sx={styles.servicesBox}>
      <Container sx={styles.container} maxWidth="xl">
        <Typography variant="h3" sx={styles.servicesTitle}>
          Comprehensive Menu of{" "}
          <span style={{ color: "#06038D" }}>Services</span>
        </Typography>
        <Typography variant="body1" sx={styles.servicesSubtitle}>
          {`We go beyond just selling your home. Our extensive menu of services
          provides everything you need to prepare and optimize your home for
          sale. From repairs and remodeling to staging and appraisals, we've got
          you covered. Our service providers include:`}
        </Typography>
        <Grid2 container spacing={styles.gridContainer.spacing}>
          <Grid2 size={{ xs: 12, md: 7 }} sx={styles.gridItem}>
            <Box sx={styles.relativeBox}>
              <Box sx={styles.absoluteBox}>
                <img
                  src={menuOfServiceVectorImage}
                  alt="Menu Of Service Vector"
                />
              </Box>
              <Box sx={styles.serviceIconBox}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={servicesIcon} alt="Services Icon" />
                  <Typography sx={styles.serviceIconText}>
                    50+ Services
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.flexibleBox}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={FlexibleAndEasyIcon} alt="Services Icon" />
                  <Typography sx={styles.serviceIconText}>
                    Flexible & Easy
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.profitableBox}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={profitableDeals} alt="Services Icon" />
                  <Typography sx={styles.serviceIconText}>
                    Profitable Deals
                  </Typography>
                </Box>
              </Box>
              <Box
                component="img"
                sx={styles.serviceImage}
                src={menuOfServiceImage}
                alt="Menu Of Service Image"
                onLoad={() => setIsMenuOfServiceImageLoading(false)}
              ></Box>
              {isMenuOfServiceImageLoading && (
                <Box sx={styles.loadingBox}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 5 }} sx={styles.accordionGridItem}>
            {accordionData.map((accordion, index) => (
              <Accordion
                key={accordion.title}
                sx={{
                  ...styles.accordion,
                  borderLeft: `5px solid ${accordion.color}`,
                }}
                expanded={expandedPanel === accordion.title}
                onChange={handleChange(accordion.title)}
              >
                <AccordionSummary
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  sx={styles.accordionSummary}
                >
                  <img
                    src={
                      expandedPanel === accordion.title
                        ? minusAccordingIcon
                        : plusAccordingIcon
                    }
                    alt={
                      expandedPanel === accordion.title
                        ? "Expand Less"
                        : "Expand More"
                    }
                    style={styles.accordionIcon}
                  />
                  {accordion.title}
                </AccordionSummary>
                <AccordionDetails sx={styles.accordionDetails}>
                  {accordion.content}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default MenuOfServices;
