import WhyChoose from "./WhyChoose/WhyChoose";
import ServicesList from "./ServicesList/ServicesList";
import MenuOfServices from "./MenuOfServices/MenuOfServices";
import Expertise from "./Expertise/Expertise";

const AboutUsContent = () => {
  return (
    <>
      <WhyChoose />
      <ServicesList />
      <Expertise />
      <MenuOfServices />
    </>
  );
};

export default AboutUsContent;
