import {
  Box,
  Container,
  Typography,
  Grid2,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { styles } from "./style";
import cardOffer from "../../../../../assets/Images/cardOffer.svg";
import leaseBack from "../../../../../assets/AboutUs/leaseBack.svg";
import creditCard from "../../../../../assets/Images/creditCard.svg";
import traditionalSelling from "../../../../../assets/AboutUs/traditionalSelling.svg";
import ourMisstionImage from "../../../../../assets/AboutUs/ourMisstionImage.jpg";
import ourMisstionImageVector from "../../../../../assets/AboutUs/ourMisstionImageVector.png";
import ourServicesVectorImage from "../../../../../assets/AboutUs/ourServicesVectorImage.svg";

const ServicesList = () => {
  const [isMissionImageLoading, setIsMissionImageLoading] = useState(true);
  return (
    <Box sx={styles.servicesBox}>
      <Container maxWidth="xl" sx={styles.servicesContainer}>
        <Typography variant="h3" sx={styles.servicesTitle}>
          Our Services
        </Typography>
        <Typography variant="body1" sx={styles.servicesSubtitle}>
          Selling Options
        </Typography>
        <Box sx={styles.servicesContentBox}>
          <Box sx={styles.stepsBox}>
            <Box sx={styles.stepBox}>
              <Box sx={styles.stepCircle}>
                <Box sx={styles.stepNumber}>1</Box>
                <Box sx={styles.stepArrow}></Box>
              </Box>
            </Box>
            <Box sx={styles.stepBoxFull}>
              <Box sx={styles.stepCircle}>
                <Box sx={styles.stepNumber}>2</Box>
                <Box sx={styles.stepArrow}></Box>
              </Box>
            </Box>
            <Box sx={styles.stepBoxFull}>
              <Box sx={styles.stepCircle}>
                <Box sx={styles.stepNumber}>3</Box>
                <Box sx={styles.stepArrow}></Box>
              </Box>
            </Box>
            <Box sx={styles.stepBoxRight}>
              <Box sx={styles.stepCircle}>
                <Box sx={styles.stepNumber}>4</Box>
                <Box sx={styles.stepArrow}></Box>
              </Box>
            </Box>
          </Box>
          <Grid2 container spacing={3}>
            <Grid2 size={{ sm: 6, md: 3 }} sx={styles.cardGridItem}>
              <Box sx={styles.offersCardBox}>
                <Box sx={styles.cardImageBox}>
                  <img src={cardOffer} alt="Card Offer" />
                </Box>
                <Typography variant="h3" sx={styles.offersCardTitle}>
                  Cash Offers
                </Typography>
                <Typography variant="body1" sx={styles.offersCardDescription}>
                  Need to sell quickly? Our cash offer option provides a fast
                  and straightforward solution.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 6, md: 3 }} sx={styles.cardGridItem}>
              <Box sx={styles.offersCardBox}>
                <Box sx={styles.cardImageBox}>
                  <img src={leaseBack} alt="Card Offer" />
                </Box>
                <Typography variant="h3" sx={styles.offersCardTitle}>
                  Lease Back
                </Typography>
                <Typography variant="body1" sx={styles.offersCardDescription}>
                  Want to sell your home but stay in it a bit longer? Our lease
                  back option offers you flexibility.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 6, md: 3 }} sx={styles.cardGridItem}>
              <Box sx={styles.offersCardBox}>
                <Box sx={styles.cardImageBox}>
                  <img src={creditCard} alt="Card Offer" />
                </Box>
                <Typography variant="h3" sx={styles.offersCardTitle}>
                  Lock
                </Typography>
                <Typography variant="body1" sx={styles.offersCardDescription}>
                  Secure your sale with our lock option, giving you peace of
                  mind.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 6, md: 3 }} sx={styles.cardGridItem}>
              <Box sx={styles.offersCardBox}>
                <Box sx={styles.cardImageBox}>
                  <img src={traditionalSelling} alt="Card Offer" />
                </Box>
                <Typography variant="h3" sx={styles.offersCardTitle}>
                  Traditional Selling
                </Typography>
                <Typography variant="body1" sx={styles.offersCardDescription}>
                  Prefer the conventional route? Our traditional selling option
                  ensures a seamless process.
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
        <Box sx={styles.missionBox}>
          <Grid2 container sx={styles.missionGridContainer}>
            <Grid2
              size={{ xs: 12, md: 6, lg: 7 }}
              sx={styles.missionTextGridItem}
            >
              <Box>
                <Typography variant="h3" sx={styles.missionTitle}>
                  Our Mission
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3" sx={styles.missionDescription}>
                  At Home Seller Savings, our mission is to provide homeowners
                  with the easiest and most convenient way to sell their home.
                  We believe in offering choices, and our diverse range of
                  selling options ensures that you can find the perfect solution
                  for your circumstances.
                </Typography>
              </Box>
            </Grid2>
            <Grid2
              size={{ xs: 12, md: 6, lg: 5 }}
              sx={styles.missionImageGridItem}
            >
              <Box
                component="img"
                sx={styles.missionImage}
                src={ourMisstionImage}
                onLoad={() => setIsMissionImageLoading(false)}
              ></Box>
              {isMissionImageLoading && (
                <Box sx={styles.missionImageLoadingBox}>
                  <CircularProgress />
                </Box>
              )}
            </Grid2>
          </Grid2>
          <Box sx={styles.missionVectorImage}>
            <img src={ourMisstionImageVector} alt="Our Services Vector" />
          </Box>
        </Box>
      </Container>
      <Box
        component="img"
        src={ourServicesVectorImage}
        sx={styles.servicesVectorImage}
      ></Box>
    </Box>
  );
};

export default ServicesList;
