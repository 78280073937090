import { Box, Typography } from "@mui/material";
import styles from "./style";
import backIcon from "../../../../assets/Common/backBlueIcon.svg";
import uploadDoc from "../../../../assets/UploadDocuments/uploadDoc.svg";
import ShowSelectedFiles from "../../../../components/ShowSelectedFiles/ShowSelectedFiles";

interface DocPayload {
  fileUrl?: string;
  key?: string;
  size?: number;
  name?: string;
}
interface UploadDocumentsFormProps {
  state: {
    error: string | null;
    selectedFiles: { name: string; size: number }[];
  };
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileTrigger: () => void;
  handleFormCount: (count: number) => void;
  handleRemoveFile: (index: number) => void;
  handleFormContinue: (event: React.FormEvent<HTMLFormElement>) => void;
  documentsPayload?: DocPayload[];
}

const UploadDocumentsForm: React.FC<UploadDocumentsFormProps> = ({
  state,
  documentsPayload,
  handleFileChange,
  handleFileTrigger,
  handleFormCount,
  handleRemoveFile,
  handleFormContinue,
}) => {
  return (
    <Box style={{ width: "100%" }}>
      <Box>
        <Box sx={styles.backButton}>
          <Box onClick={() => handleFormCount(2)}>
            <Box
              component="img"
              src={backIcon}
              alt="backArrowIcon"
              sx={{
                width: {
                  xs: "30px",
                  sm: "auto",
                },
              }}
            ></Box>
          </Box>
        </Box>
        <Typography variant="h3" sx={styles.UploadformTitle}>
          Upload License/InsuranceDocument
        </Typography>
      </Box>
      <form className="about_form setuploadfile" onSubmit={handleFormContinue}>
        <div className="form_file_wrapper">
          <Box sx={styles.uploadBox} onClick={handleFileTrigger}>
            <Box sx={{ paddingBottom: "10px" }}>
              <img
                src={uploadDoc}
                width="66px"
                height="66px"
                alt="Upload Document"
              />
            </Box>
            <Typography variant="h3" sx={styles.uploadText}>
              Upload License Document
            </Typography>
            <Typography variant="h3" sx={styles.uploadSubText}>
              Lorem ipsum dolor sit amet <br /> consectetur.
            </Typography>
          </Box>
          <Typography variant="h3" sx={styles.noteText}>
            Note: Max 3 documents can be added
          </Typography>
          <input
            type="file"
            multiple
            className="mainFileSelector"
            onChange={handleFileChange}
          />
          {state.error && <p className="errorText">{state.error}</p>}
          {documentsPayload?.map((doc, index) => (
            <ShowSelectedFiles
              key={doc?.name}
              index={index}
              fileName={doc?.name}
              fileSize={doc?.size}
              handleRemoveFile={handleRemoveFile}
            />
          ))}
        </div>
        <Box sx={styles.buttonContainer} className="button">
          <button
            className={`${documentsPayload?.length === 0 ? "disableBtn" : "activeBtn"}`}
            disabled={documentsPayload?.length === 0}
          >
            Continue
          </button>
        </Box>
      </form>
    </Box>
  );
};

export default UploadDocumentsForm;
