export const styles = {
  expertiesContainer: {
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  expertiseBox: {
    background: "#F3F4FC",
    boxShadow: "0px 2px 4px 0px #00000014",
    margin: {
      xs: "0 0 40px 0",
      lg: "0 0 80px",
    },
    borderRadius: "24px",
    zIndex: "9",
    position: "relative",
    padding: {
      lg: "40px 20px 40px 60px",
      xs: "16px",
    },
    overflow: "hidden",
  },
  expertiseGridContainer: {
    flexDirection: {
      md: "row",
      xs: "column-reverse",
    },
  },
  expertiseTextGridItem: {
    display: "flex",
    textAlign: "left",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  expertiseTitle: {
    fontSize: {
      lg: "40px",
      xs: "22px",
    },
    paddingTop: "14px",
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    paddingBottom: "20px",
  },
  expertiseDescription: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#64748B",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
    width: {
      lg: "70%",
      md: "90%",
      xs: "100%",
    },
  },
  expertiseImageGridItem: {
    position: "relative",
  },
  learnMoreButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      sm: "13px 30px",
    },
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans-serif",
    boxShadow: "4px 8px 24px 0px #1CB46940",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "20px 0 0 0",
      sm: "20px 0 0 0",
      lg: "30px 0 0 0",
    },
  },
  expertiseImage: {
    borderRadius: "20px",
    width: "100%",
    position: "relative",
    zIndex: "9",
  },
  expertiseImageLoadingBox: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "99",
  },
  expertiseVectorImage: {
    position: "absolute",
    right: "0",
    top: "-20px",
  },
};
