import { SxProps, Theme } from "@mui/system";

import QuickBuyContentImage from "../../../../assets/QuickBuy/testimonialBgColor.png";

const styles: { [key: string]: SxProps<Theme> } = {
  quickBuyContentImage: {
    padding: {
      xs: "30px 0 20px",
      sm: "40px 0",
      lg: "80px 10px 100px",
    },
    backgroundImage: `url(${QuickBuyContentImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  quickBuyContentTitle: {
    fontSize: {
      lg: "40px",
      xs: "28px",
    },
    color: "#06038D",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "14px",
  },
  quickBuyContentSubTitle: {
    fontSize: {
      lg: "24px",
      xs: "20px",
    },
    color: "#8989A3",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: {
      lg:"70px",
      xs:"20px"
    },
  },
  gridContainerWrapper: {
    width: {
      xl: "80%",
      lg: "90%",
      md: "100%",
    },
    margin: "auto !important",
  },
  gridContainer: {
    marginBottom: {
      xs: "20px",
    },
  },
  quickBuyImmediateOfferSection: {
    background: "#fff",
    padding: {
      xs: "60px 0 20px",
      sm: "60px 0 40px",
      lg: "100px 0",
    },
  },
  offerImage: {
    width: {
      lg: "50%",
      xs: "40%",
    },
  },
  offerImageWrapper: {
    background: "#fff",
    height: {
      lg: "140px",
      md: "100px",
      xs: "100px",
    },
    width: {
      lg: "140px",
      md: "100px",
      xs: "100px",
    },
    borderRadius: "50%",
    display: "flex",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: {
      lg: "-60px",
      md: "-40px",
      xs: "-50px",
    },
    left: {
      lg: "-60px",
      md: "-40px",
      xs: "0",
    },
    zIndex: "9",
  },
  quickBuyImage: {
    borderRadius: "12px",
    width: "100%",
    position: "relative",
    zIndex: "9",
  },
  quickBuyImmediateOfferGridWrapper: {
    flexDirection: {
      md: "row",
      xs: "column-reverse",
    },
  },
  relative: {
    position: "relative",
  },
  card: {
    textAlign: "center",
    border: "1px solid #06038D0D",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
    borderRadius: "12px",
    background: "#fff",
    padding: {
      xs: "20px",
      md: "20px 20px 0",
    },
    marginTop: {
      lg: "60px",
    },
    height: {
      lg: "90%",
      md: "100%",
      xs: "auto",
    },
  },
  cardIcon: {
    height: "150px",
    margin: "0 auto 30px",
    width: "150px",
    borderRadius: "50%",
    background: "#fff",
    border: "1px solid #06038D0D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
  },
  cardName: {
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    color: "#0D0639",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "700",
    textAlign: "center",
    marginBottom: "20px",
  },
  cardText: {
    fontSize: "16px",
    color: "#0D063999",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    textAlign: "center",
  },
  skipTheHasslesCard: {
    textAlign: "center",
    border: "1px solid #06038D0D",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
    borderRadius: "12px",
    background: "#fff",
    padding: {
      lg: "20px 20px 30px",
      sm: "20px",
      xs: "20px 20px 20px",
    },
    height: {
      lg: "auto",
      md: "100%",
      sm: "auto",
      xs: "auto",
    },
  },
  skipTheHasslesCardIcon: {
    height: "150px",
    margin: "0 auto 30px",
    width: "150px",
    borderRadius: "50%",
    background: "#06038D0D",
    border: "1px solid #06038D0D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
  },
  quickBuyContent: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#64748B",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
  },
  wrapper: {
    display: "flex",
    textAlign: "left",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  width: {
    width: {
      lg: "90%",
    },
  },
  quickBuyLeackBackImage: {
    width: {
      lg: "50%",
      xs: "40%",
    },
  },
  flexDirection: {
    flexDirection: {
      md: "row",
      xs: "column",
    },
  },
  quickBuyLeackBackImageSection: {
    background: "#fff",
    padding: {
      xs: "60px 0 20px",
      sm: "60px 0 40px",
      lg: "100px 0",
    },
  },
  quickBuyLeackBackImageWrapper: {
    background: "#fff",
    height: {
      lg: "140px",
      md: "100px",
      xs: "100px",
    },
    width: {
      lg: "140px",
      md: "100px",
      xs: "100px",
    },
    borderRadius: "50%",
    display: "flex",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: {
      lg: "-50px",
      md: "-50px",
      xs: "-50px",
    },
    left: {
      lg: "-30px",
      md: "-30px",
      xs: "0",
    },
    zIndex: "9",
  },
  quickBuyVectorImage: {
    position: "absolute",
    top: "-30px",
    right: "-110px",
  },
  quickBuyLockGuaranteedSaleWrapper: {
    background: "#F3F4FC",
    padding: {
      xs: "20px 0 50px",
      sm: "60px 0 40px",
      lg: "60px 0 60px",
    },
    position: "relative",
    overflow: "hidden",
  },
  quickBuyLockGuaranteedSaleIconBoxWrapper: {
    textAlign: "center",
    margin: "auto",
  },
  quickBuyLockGuaranteedSaleIconImage: {
    textAlign: "center",
    margin: "auto",
  },
  quickBuyLockGuaranteedSaleHeading: {
    fontSize: {
      lg: "32px",
      xs: "24px",
    },
    paddingTop: "14px",
    color: "#0F172A",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "700",
    textAlign: "center",
    paddingBottom: "40px",
  },
  quickBuyLeasebackHeading: {
    fontSize: {
      lg: "32px",
      xs: "24px",
    },
    paddingTop: "14px",
    color: "#0F172A",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "700",
    paddingBottom: "20px",
  },
  quickBuyLeasebackSubHeading: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#0D0639",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
    padding: "12px 0 3px",
  },
  firstListItem: {
    padding: "5px 0",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#64748B",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
  },
  listItem: {
    padding: "0",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#64748B",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    lineHeight: {
      lg: "36px",
      xs: "28px",
    },
  },
  listItemText: {
    minHeight: "8px",
    minWidth: "8px",
    borderRadius: "50%",
    background: "#64748B",
    marginRight: "10px",
  },
  button: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      sm: "13px 30px",
    },
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans- serif",
    boxShadow: "4px 8px 24px 0px #1CB46940",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "20px 0 20px 0",
      sm: "20px 0 20px 0",
      lg: "30px 0 0 0",
    },
  },
  getMyOfferButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      sm: "13px 30px",
    },
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans- serif",
    boxShadow: "4px 8px 24px 0px #1CB46940",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "20px 0 20px 0",
      sm: "20px 0 20px 0",
      lg: "30px 0 0 0",
    },
  },
};

export default styles;
