import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  InputLabel,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import styles from "./style";
import astrikIcon from "../../../assets/Common/astrikIcon.svg";
import closeIcon from "../../../assets/Common/closeIcon.svg";
import successPopupImage from "../../../assets/Common/successPopupImage.png";
import { useState, useReducer, ChangeEvent } from "react";
import { formatInput } from "../../../utils/formatUtils";
import { validateField } from "../../../utils/validationUtils";
import { submitServiceInquiry } from "../../../network/services";
import { useLocation, useParams } from "react-router-dom";

interface State {
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  message: string;
  errors: {
    phoneNumber: string;
    email: string;
    fullName: string;
    zipCode: string;
    message: string;
  };
}
const initialState: State = {
  fullName: "",
  phoneNumber: "",
  email: "",
  zipCode: "",
  message: "",
  errors: {
    phoneNumber: "",
    email: "",
    fullName: "",
    zipCode: "",
    message: "",
  },
};

type Action =
  | { type: "SET_FIELD"; payload: { field: string; value: string | boolean } }
  | { type: "SET_ERROR"; payload: { field: string; message: string } }
  | { type: "CLEAR_ERRORS" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.payload.field]: action.payload.value };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {
          phoneNumber: "",
          email: "",
          fullName: "",
          zipCode: "",
          message: "",
        },
      };
    default:
      return state;
  }
};

const getSx = (val: string | null) => {
  return {
    borderColor: val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};

interface InquiryFormProps {
  inquiryFormModal: boolean;
  setInquiryFormModal: (val: boolean) => void;
  setInquirySubmitted: (val: boolean) => void;
}

const InquiryForm = ({
  inquiryFormModal,
  setInquiryFormModal,
  setInquirySubmitted,
}: InquiryFormProps) => {
  const [inquiryFormSucessModal, setInquiryFormSucessModal] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("categoryId");
  const zipCode = queryParams.get("zipCode");
  const { id } = useParams();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    const { name } = event.target;
    if (name === "fullName" || name === "phoneNumber") {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "message") {
      value = formatInput(name, value, 1000);
    }
    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
    if (name === "phoneNumber") {
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
    }

    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
    const errorMessage = validateField(name, value);
    dispatch({
      type: "SET_ERROR",
      payload: { field: name, message: errorMessage },
    });
  };

  const handleClickInquiryFormModalClose = () => {
    setInquiryFormModal(false);
  };

  const handleClickInquiryFormSucessModalOpen = async () => {
    setIsLoading(true);
    try {
      const response = await submitServiceInquiry({
        fullName: state.fullName,
        phoneNumber: state.phoneNumber,
        email: state.email,
        message: state.message,
        zipCode: zipCode ?? "",
        userId: id ?? "",
        categoryIds: categoryId ? [categoryId] : [],
      });
      if (response) {
        setIsLoading(false);
        setInquirySubmitted(true);
        setInquiryFormModal(false);
        setInquiryFormSucessModal(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleClickInquiryFormSucessModalClose = () => {
    setInquiryFormSucessModal(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClickInquiryFormModalClose}
        aria-labelledby="customized-dialog-title"
        open={inquiryFormModal}
        PaperProps={{ sx: styles.dialogPaper }}
      >
        <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
          Inquiry Form
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickInquiryFormModalClose}
          sx={styles.closeButton}
        >
          <img src={closeIcon} alt="closeIcon" />
        </IconButton>
        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.formBox}>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Full Name{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add First & Last Name"
                name="fullName"
                value={state.fullName}
                onChange={handleInputChange}
                error={!!state.errors.fullName}
                helperText={state.errors.fullName}
                sx={getSx(state.errors.fullName)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Phone Number{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Number"
                name="phoneNumber"
                value={state.phoneNumber}
                onChange={handleInputChange}
                error={!!state.errors.phoneNumber}
                helperText={state.errors.phoneNumber}
                sx={getSx(state.errors.phoneNumber)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Email Address{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Email"
                name="email"
                value={state.email}
                onChange={handleInputChange}
                error={!!state.errors.email}
                helperText={state.errors.email}
                sx={getSx(state.errors.email)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Zip Code{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Zip code"
                name="zipCode"
                value={zipCode}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Message{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Message"
                name="message"
                value={state.message}
                onChange={handleInputChange}
                minRows={1}
                maxRows={4}
                multiline
                error={!!state.errors.message}
                helperText={state.errors.message}
                sx={getSx(state.errors.message)}
              />
            </Box>
            <Box sx={styles.submitButtonContainer}>
              {isLoading ? (
                <Button sx={styles.submitButton}>
                  <CircularProgress size={40} />
                </Button>
              ) : (
                <Button
                  disabled={
                    !(
                      state.fullName &&
                      state.phoneNumber &&
                      state.email &&
                      state.message &&
                      !state.errors.fullName &&
                      !state.errors.phoneNumber &&
                      !state.errors.email &&
                      !state.errors.message
                    )
                  }
                  sx={styles.submitButton}
                  onClick={handleClickInquiryFormSucessModalOpen}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleClickInquiryFormSucessModalClose}
        aria-labelledby="customized-dialog-title"
        open={inquiryFormSucessModal}
        PaperProps={{ sx: styles.dialogPaperSuccess }}
      >
        <DialogTitle
          sx={styles.dialogTitleSuccess}
          id="customized-dialog-title"
        >
          <img src={successPopupImage} alt="Sucess" />
          <Box>Inquiry Submitted Successfully</Box>
        </DialogTitle>

        <DialogContent sx={styles.dialogContentSuccess}>
          <Box sx={styles.formBoxSuccess}>
            <Box sx={styles.submitButtonContainerSuccess}>
              <Button
                sx={styles.submitButtonSuccess}
                onClick={handleClickInquiryFormSucessModalClose}
              >
                Done
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InquiryForm;
