import { createContext, useState, useMemo } from "react";

interface SearchProviderProps {
  children: React.ReactNode;
}

export interface BusinessDocuments {
  fileUrl: string;
  key: string;
  name: string;
  size: number;
  _id?: string;
}

export interface BusinessImage {
  url: string;
  key?: string;
  _id?: string;
}
interface TimeSlot {
  startTime: string;
  endTime: string;
  _id: string;
}
interface ServiceDay {
  isAvailable: boolean;
  slots: TimeSlot[];
}
export interface ServiceTiming {
  monday: ServiceDay;
  tuesday: ServiceDay;
  wednesday: ServiceDay;
  thursday: ServiceDay;
  friday: ServiceDay;
  saturday: ServiceDay;
  sunday: ServiceDay;
}

export interface ServiceProvider {
  _id: string;
  address: string;
  businessDocuments: BusinessDocuments[];
  businessImages: BusinessImage[];
  businessTitle: string;
  categoryIds: string[];
  city: string;
  createdAt: string;
  description: string;
  email: string;
  fullName: string;
  isDeleted: boolean;
  isNotificationEnabled: boolean;
  isProfileCompletion: boolean;
  password: string;
  phoneNumber: string;
  profileImage: {
    url: string;
    key: string;
  };
  province: string;
  serviceIds: string[];
  serviceTiming: ServiceTiming;
  status: string;
  subCategoryIds: string[];
}

interface DisplayImage {
  key: string;
  url: string;
}

export interface Service {
  _id: string;
  name: string;
  displayImage?: DisplayImage;
}

export interface SubCategory {
  _id: string;
  categoryId: string;
  subCategoryName: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Category {
  categoryName: string;
  displayImage: DisplayImage;
  subcategories?: SubCategory[];
  _id: string;
}
interface SearchContextType {
  filters: Filters;
  data: Data;
  updateFilter: (
    field: keyof Filters,
    value: string | string[] | Service | null
  ) => void;
  updateData: (
    field: keyof Data,
    value:
      | Service[]
      | Category[]
      | SubCategory[]
      | ServiceProvider[]
      | number
      | boolean
  ) => void;
}

interface SearchProviderProps {
  children: React.ReactNode;
}

export interface Filters {
  selectedCategory: string;
  selectedService: Service | null;
  selectedSubCategories: string[];
  zipCode: string;
  selectedCategoryId: string;
  searchedAddress: string;
  location: string;
}

interface Data {
  services: Service[];
  category: Category[];
  subCategory: SubCategory[];
  serviceProviders: ServiceProvider[];
  totalRecordsCount: number;
  page: number;
  totalPages: number;
  isSearchClicked: boolean;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const zipCode = sessionStorage.getItem("zipCode");
  const location = sessionStorage.getItem("location");

  const [filters, setFilters] = useState<Filters>({
    selectedCategory: "",
    selectedService: null,
    selectedSubCategories: [],
    zipCode: zipCode || "",
    selectedCategoryId: "",
    searchedAddress: "",
    location: location || "",
  });
  const [data, setData] = useState<Data>({
    services: [],
    category: [],
    subCategory: [],
    serviceProviders: [],
    totalRecordsCount: 0,
    page: 1,
    totalPages: 0,
    isSearchClicked: false,
  });

  const updateFilter = (
    field: keyof Filters,
    value: string | string[] | Service | null
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const updateData = (
    field: keyof Data,
    value:
      | Service[]
      | Category[]
      | SubCategory[]
      | ServiceProvider[]
      | number
      | boolean
  ) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const value = useMemo(
    () => ({ filters, data, updateFilter, updateData }),
    [filters, data]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
