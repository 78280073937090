import { Typography, Box, InputLabel, TextField, Grid2 } from "@mui/material";
import styles from "./style";
import { getSx } from "./helper";
import backIcon from "../../../../assets/Common/backBlueIcon.svg";
import plusIcon from "../../../../assets/ServiceProvider/plusIcon.svg";

interface AddressDetailsFormProps {
  state: {
    address: string;
    city: string;
    province: string;
    zipCode: string;
    moreZipCodes: string;
    isAddMoreZipCode: boolean;
    errors: {
      address?: string;
      city?: string;
      province?: string;
      zipCode?: string;
      moreZipCodes?: string;
    };
    disabled2: boolean;
  };
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormCount: (count: number) => void;
  handleAddMoreZipCode: () => void;
}

const AddressDetailsForm: React.FC<AddressDetailsFormProps> = ({
  state,
  handleInputChange,
  handleFormCount,
  handleAddMoreZipCode,
}) => {
  return (
    <Box>
      <Box sx={styles.backButton}>
        <Box onClick={() => handleFormCount(1)}>
          <Box
            component="img"
            src={backIcon}
            alt="backArrowIcon"
            sx={{
              width: {
                xs: "30px",
                lg: "auto",
              },
            }}
          ></Box>
        </Box>
      </Box>
      <Typography variant="h3" sx={styles.formTitle}>
        Inquiry Form
      </Typography>
      <form className="inqueryFormBox" onSubmit={() => handleFormCount(3)}>
        <Box className="formControl">
          <InputLabel className="inputLable">Address</InputLabel>
          <TextField
            name="address"
            value={state.address}
            onChange={handleInputChange}
            placeholder="Add Address"
            error={!!state.errors.address}
            helperText={state.errors.address}
            sx={getSx(state.errors.address ?? null)}
          />
        </Box>
        <Box className="formControl">
          <InputLabel className="inputLable">City</InputLabel>
          <TextField
            name="city"
            value={state.city}
            onChange={handleInputChange}
            placeholder="Enter City"
            error={!!state.errors.city}
            helperText={state.errors.city}
            sx={getSx(state.errors.city ?? null)}
          />
        </Box>
        <Box>
          <Grid2 container>
            <Grid2 size={{ xs: 12, lg: 6 }}>
              <Box className="formControl" sx={styles.marginL}>
                <InputLabel className="inputLable">State</InputLabel>
                <TextField
                  name="province"
                  value={state.province}
                  onChange={handleInputChange}
                  placeholder="Enter Province"
                  error={!!state.errors.province}
                  helperText={state.errors.province}
                  sx={getSx(state.errors.province ?? null)}
                />
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 6 }}>
              <Box className="formControl" sx={styles.marginL}>
                <InputLabel className="inputLable">Zip Code</InputLabel>
                <TextField
                  name="zipCode"
                  value={state.zipCode}
                  type="text"
                  onChange={handleInputChange}
                  placeholder="Add Zip Code"
                  error={!!state.errors.zipCode}
                  helperText={state.errors.zipCode}
                  sx={getSx(state.errors.zipCode ?? null)}
                />
              </Box>
            </Grid2>
            {state.isAddMoreZipCode && (
              <Grid2 size={{ xs: 12 }}>
                <Box className="formControl" sx={styles.marginL}>
                  <InputLabel className="inputLable">
                    Service Location Zip Codes
                  </InputLabel>
                  <TextField
                    name="moreZipCodes"
                    value={state.moreZipCodes}
                    onChange={handleInputChange}
                    placeholder="Add Zip Codes"
                    error={!!state.errors.moreZipCodes}
                    helperText={state.errors.moreZipCodes}
                    sx={getSx(state.errors.moreZipCodes ?? null)}
                  />
                </Box>
              </Grid2>
            )}
          </Grid2>
        </Box>
        {!state.isAddMoreZipCode && (
          <Box sx={styles.addMoreZipCode} onClick={handleAddMoreZipCode}>
            <img src={plusIcon} alt="plus icon" />
            <Typography variant="h3" sx={styles.addMoreZipCodeText}>
              Add more Zip Code for your Service Location
            </Typography>
          </Box>
        )}
        <Box sx={styles.buttonContainer} className="button">
          <button
            className={`${state.disabled2 ? "disableBtn" : "activeBtn"}`}
            disabled={state.disabled2}
          >
            Submit Inquiry
          </button>
        </Box>
      </form>
    </Box>
  );
};

export default AddressDetailsForm;
