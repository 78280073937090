import { Box, Tab, Tabs } from "@mui/material";
import styles from "./style";
import activeChangePassword from "../../../assets/Settings/activeChangePassword.svg";
import activeDeleteAccount from "../../../assets/Settings/activeDeleteAccount.svg";
import activeEditProfile from "../../../assets/Settings/activeEditProfile.svg";
import changePassword from "../../../assets/Settings/changePassword.svg";
import deleteAccountImage from "../../../assets/Settings/deleteAccount.svg";
import editProfile from "../../../assets/Settings/editProfile.svg";
import React from "react";

interface TabsSectionProps {
  value: number;
  setValue: (newValue: number) => void;
  setDeleteAccountModal: (isOpen: boolean) => void;
}

const TabsSection: React.FC<TabsSectionProps> = ({
  value,
  setValue,
  setDeleteAccountModal,
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClickDeleteAccountModalOpen = (): void => {
    setDeleteAccountModal(true);
  };

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      sx={styles.tabs}
    >
      <Tab
        label="Edit Profile"
        icon={
          <Box className="icon">
            <img src={editProfile} alt="Edit Profile" />
            <Box
              component="img"
              src={activeEditProfile}
              alt="Edit Profile"
              className="active"
            ></Box>
          </Box>
        }
        iconPosition="start"
      />
      <Tab
        label="Change Password"
        icon={
          <Box className="icon">
            <img src={changePassword} alt="ChangePassword" />
            <Box
              component="img"
              src={activeChangePassword}
              alt="Change Password"
              className="active"
            ></Box>
          </Box>
        }
        iconPosition="start"
      />
      <Box
        sx={styles.deleteAccountBox}
        onClick={handleClickDeleteAccountModalOpen}
        className="deleteAccountBox"
      >
        <Box sx={styles.deleteAccountIconBox}>
          <Box>
            <img src={deleteAccountImage} alt="Delete Account" />
            <Box
              component="img"
              src={activeDeleteAccount}
              alt="Delete Account"
              className="active"
            ></Box>
          </Box>
        </Box>
        Delete Account
      </Box>
    </Tabs>
  );
};

export default TabsSection;
