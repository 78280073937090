export const formatInput = (
  fieldType: string,
  value: string,
  maxLength?: number
): string => {
  switch (fieldType) {
    case "phoneNumber":
      value = value.replace(/(?!^\+1\s)\D/g, "");
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
      return value.slice(0, 18);

    case "zipCode":
      return value.replace(/\D/g, "").slice(0, 9);

    case "fullName":
    case "city":
    case "state":
    case "province":
      return value
        .replace(/[^a-zA-Z\s]/g, "")
        .slice(0, fieldType === "fullName" ? 40 : 32);
    case "email":
    case "address":
    case "message":
    case "subject":
    case "businessTitle":
    case "description":
      return value.slice(0, maxLength);

    default:
      return value;
  }
};

// used in settings
export const formatFullAddress = (
  address: string,
  city: string,
  province: string,
  zipCode: string
): string => `${address}, ${city}, ${province}, ${zipCode}`;
