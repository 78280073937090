import { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/ConsumerHeader/Header";
import AboutUsBanner from "./AboutUsBanner/AboutUsBanner";
import AboutUsContent from "./AboutUsContent/AboutUsContent";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const role = "consumer";
  return (
    <>
      <Header />
      <AboutUsBanner />
      <AboutUsContent />
      <Footer role={role} />
    </>
  );
};

export default AboutUs;
