import { Box, Typography } from "@mui/material";

export const TabPanel: React.FC<{
  children?: React.ReactNode;
  index: number;
  value: number;
}> = (props) => {
  const { children, index, value, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: "75%" }}
    >
      {value === index && (
        <Box
          sx={{
            padding: {
              md: "10px 40px",
              xs: "0 0 10px 0",
              sm: "0 0 10px 15px",
            },
          }}
        >
          {children}
        </Box>
      )}
    </Typography>
  );
};
