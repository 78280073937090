import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import backIcon from "../../../assets/Common/backBlueIcon.svg";
import { getTermsAndConditions } from "../../../network/common";

const Content = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState("");
  const [title, setTitle] = useState("");

  const getcontent = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const response = await getTermsAndConditions();
      setData(response.data[0].description);
      setTitle(response.data[0].title);
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getcontent();
  }, [getcontent]);

  return (
    <Box
      sx={{
        background: "#F5F5FA",
        padding: {
          lg: "80px 0",
          xs: "20px 0 0",
        },
        position: "relative",
      }}
      className="contentPage"
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            boxShadow: "0px 2px 0px 0px #0000000F",
            border: "1px solid #0000001A",
            borderRadius: "36px",
            position: "relative",
            background: "#fff",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              textAlign: "center",
              color: "#06038D",
              fontSize: {
                xl: "38px",
                lg: "38px",
                sm: "32px",
                xs: "24px",
              },
              padding: {
                lg: "60px 0 30px 0",
                sm: "60px 0 30px 0",
                xs: "60px 0 0 0",
                "@media (orientation: landscape) and (max-width: 999px)": {
                  padding: "50px 0 20px 0",
                },
              },
            }}
          >
            Terms & Conditions
          </Typography>
          <Box
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              fontSize: "16px",
              color: "#0D0639B2",
              padding: {
                lg: "0 70px 60px 70px",
                xs: "0 20px 30px 20px",
              },
              lineHeight: {
                lg: "40px",
                xs: "35px",
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </Box>
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: {
                lg: "40px",
                xs: "20px",
              },
              left: {
                lg: "40px",
                xs: "20px",
              },
            }}
          >
            <Box onClick={() => navigate(-1)}>
              <Box
                component="img"
                src={backIcon}
                alt="backArrowIcon"
                sx={{
                  width: {
                    xs: "30px",
                    lg: "auto",
                  },
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Content;
