import { UserData } from "../redux/slices/userSlice";
import axiosInstance from "./axios";

interface LoginPayload {
  userName: string;
  password: string;
  userType: 0 | 1; // usertype can only be 0 or 1
}
export const login = async (payload: LoginPayload) => {
  const res = await axiosInstance.post(`/user/login`, payload);
  return res?.data;
};
interface ChangePwd {
  oldPassword: string;
  newPassword: string;
}
export const changePassword = async (payload: ChangePwd) => {
  const res = await axiosInstance.put(`/user/change-password`, payload);
  return res?.data;
};

export const deleteAccount = async () => {
  const res = await axiosInstance.delete(`/user/delete`);
  return res?.data;
};

export const logout = async () => {
  const res = await axiosInstance.put(`/user/logout`);
  return res?.data;
};

export const getUserProfile = async () => {
  const res = await axiosInstance.get(`/user/profile`);
  return res?.data;
};

export const updateUserProfile = async (payload: UserData) => {
  const res = await axiosInstance.put(`/user/update`, payload);
  return res?.data;
};
