import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid2,
  Typography,
  ListItem,
  CircularProgress,
  List,
  Button,
} from "@mui/material";
import styles from "../style";
import { routePaths } from "../../../../../router/routePaths";
import QuickBuyLeackBackImage from "../../../../../assets/QuickBuy/quickBuyLeackBackImage.jpg";
import QuickBuyLeackImage from "../../../../../assets/QuickBuy/quickBuyLeackBack.png";

const QuickBuyLeackBack = () => {
  const [isLoadingQuickBuyLeackBackImage, setIsLoadingQuickBuyLeackBackImage] =
    useState(true);
  return (
    <Box sx={styles.quickBuyLeackBackImageSection}>
      <Container maxWidth="xl">
        <Box>
          <Grid2 spacing={{ md: 6 }} container sx={styles.flexDirection}>
            <Grid2 size={{ xs: 12, md: 7, lg: 6 }}>
              <Box sx={styles.relative}>
                <Box
                  component="img"
                  sx={styles.quickBuyImage}
                  onLoad={() => setIsLoadingQuickBuyLeackBackImage(false)}
                  src={QuickBuyLeackBackImage}
                ></Box>
                {isLoadingQuickBuyLeackBackImage && (
                  <CircularProgress
                    size={40}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                <Box sx={styles.quickBuyLeackBackImageWrapper}>
                  <Box
                    component="img"
                    src={QuickBuyLeackImage}
                    sx={styles.quickBuyLeackBackImage}
                    alt="leackBack"
                  ></Box>
                </Box>
              </Box>
            </Grid2>
            <Grid2 size={{ md: 5, xs: 12, lg: 6 }} sx={styles.wrapper}>
              <Box>
                <Typography variant="h3" sx={styles.quickBuyLeasebackHeading}>
                  {`QuickBuy® Leaseback`}
                </Typography>
              </Box>
              <Box sx={styles.width}>
                <Typography variant="h3" sx={styles.quickBuyContent}>
                  {`Change may be inevitable, but you can control the speed at
                  which it happens. QuickBuy® Leaseback is a great solution if
                  you need to sell your home quickly but aren't quite ready to
                  move. Stay in your home while you build, improve, or look for
                  the next one. Long and short-term options are available.`}
                </Typography>
                <Typography
                  variant="h3"
                  sx={styles.quickBuyLeasebackSubHeading}
                >
                  {`Is QuickBuy® Leaseback the right fit for you?`}
                </Typography>
                <List>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    {`You control your timeline and move when you're ready.`}
                  </ListItem>
                  <ListItem sx={styles.firstListItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    Enjoy a smooth transition between your current home and your
                    new one.
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    Keep your options open while you prepare to move.
                  </ListItem>
                  <ListItem sx={styles.listItem}>
                    <Box component="span" sx={styles.listItemText}></Box>
                    Simplify the process of looking for your next home with
                    funds from your current one.
                  </ListItem>
                </List>
              </Box>
              <NavLink to={routePaths.quickBuyForm}>
                <Button sx={styles.button}>Get My Offer</Button>
              </NavLink>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
};

export default QuickBuyLeackBack;
