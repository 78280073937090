import React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";

function CircularProgressComponent(
  props: React.ComponentProps<typeof CircularProgress>
) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#0094D9",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function Loader() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <div
        style={{
          zIndex: 999999,
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "110px",
          height: "110px",
        }}
      >
        <CircularProgressComponent />
      </div>
    </Backdrop>
  );
}
