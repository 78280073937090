import {
  Typography,
  InputLabel,
  TextField,
  Box,
  Grid2,
  Button,
  CircularProgress,
} from "@mui/material";
import styles from "./style";
import astrikIcon from "../../../assets/Common/astrikIcon.svg";
import { useReducer, useEffect, ChangeEvent, useState } from "react";
import { initialState } from "./helper";
import { formatInput } from "../../../utils/formatUtils";
import { validateField } from "../../../utils/validationUtils";
import { submitTieredPlanInquiry } from "../../../network/services";

interface State {
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  message: string;
  address: string;
  city: string;
  state: string;
  disabled: boolean;
  errors: {
    phoneNumber: string;
    email: string;
    fullName: string;
    zipCode: string;
    message: string;
    address: string;
    city: string;
    state: string;
  };
}

type Action =
  | { type: "SET_FIELD"; payload: { field: string; value: string | boolean } }
  | { type: "SET_ERROR"; payload: { field: string; message: string } }
  | { type: "CLEAR_ERRORS" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.payload.field]: action.payload.value };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {
          phoneNumber: "",
          email: "",
          fullName: "",
          zipCode: "",
          message: "",
          address: "",
          city: "",
          state: "",
        },
      };
    default:
      return state;
  }
};

const getSx = (val: string | null) => {
  return {
    borderColor: val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};

interface InquiryFormProps {
  setSubmitted: (submitted: boolean) => void;
}

const QuickBuyInquiryForm = ({ setSubmitted }: InquiryFormProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const hasErrors = Object.values(state.errors).some((error) => error !== "");
    if (
      !hasErrors &&
      state.fullName !== "" &&
      state.phoneNumber !== "" &&
      state.email !== "" &&
      state.zipCode !== "" &&
      state.message !== "" &&
      state.address !== "" &&
      state.city !== "" &&
      state.state !== ""
    ) {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: false },
      });
    } else {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: true },
      });
    }
  }, [state.errors]);

  const handleSubmit = async () => {
    setIsLoading(true);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    const response = await submitTieredPlanInquiry({
      leadType: "QB",
      fullName: state.fullName,
      phoneNumber: state.phoneNumber,
      email: state.email,
      message: state.message,
      address: state.address,
      city: state.city,
      state: state.state,
      zipCode: state.zipCode,
    });
    if (response) {
      setIsLoading(false);
      setSubmitted(true);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let { value } = event.target;

    if (
      name === "zipCode" ||
      name === "fullName" ||
      name === "city" ||
      name === "state" ||
      name === "phoneNumber"
    ) {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "address") {
      value = formatInput(name, value, 100);
    }
    if (name === "message") {
      value = formatInput(name, value, 1000);
    }

    if (name === "phoneNumber") {
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
    }

    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
    const errorMessage = validateField(name, value);
    dispatch({
      type: "SET_ERROR",
      payload: { field: name, message: errorMessage },
    });
  };

  return (
    <>
      <Typography variant="h3" sx={styles.formTitle}>
        Inquiry Form
      </Typography>
      <Box className="formControl">
        <InputLabel className="inputLable">
          Full Name
          <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
        </InputLabel>
        <TextField
          placeholder="Add First & Last Name"
          name="fullName"
          value={state.fullName}
          onChange={handleInputChange}
          error={!!state.errors.fullName}
          helperText={state.errors.fullName}
          sx={getSx(state.errors.fullName)}
        />
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">
          Phone Number
          <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
        </InputLabel>
        <TextField
          placeholder="Add Number"
          name="phoneNumber"
          value={state.phoneNumber}
          onChange={handleInputChange}
          error={!!state.errors.phoneNumber}
          helperText={state.errors.phoneNumber}
          sx={getSx(state.errors.phoneNumber)}
        />
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">
          Email Address
          <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
        </InputLabel>
        <TextField
          type="email"
          placeholder="Add Email"
          name="email"
          value={state.email}
          onChange={handleInputChange}
          error={!!state.errors.email}
          helperText={state.errors.email}
          sx={getSx(state.errors.email)}
        />
      </Box>
      <Box>
        <Grid2 container>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <Box
              sx={{ marginRight: { lg: "5px", xs: "0" } }}
              className="formControl"
            >
              <InputLabel className="inputLable">
                Address
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Address"
                name="address"
                value={state.address}
                onChange={handleInputChange}
                error={!!state.errors.address}
                helperText={state.errors.address}
                sx={getSx(state.errors.address)}
              />
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <Box
              sx={{ marginLeft: { lg: "5px", xs: "0" } }}
              className="formControl"
            >
              <InputLabel className="inputLable">
                City
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Enter City"
                name="city"
                value={state.city}
                onChange={handleInputChange}
                error={!!state.errors.city}
                helperText={state.errors.city}
                sx={getSx(state.errors.city)}
              />
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <Box
              sx={{ marginRight: { lg: "5px", xs: "0" } }}
              className="formControl"
            >
              <InputLabel className="inputLable">
                State
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add State"
                name="state"
                value={state.state}
                onChange={handleInputChange}
                error={!!state.errors.state}
                helperText={state.errors.state}
                sx={getSx(state.errors.state)}
              />
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <Box
              sx={{ marginLeft: { lg: "5px", xs: "0" } }}
              className="formControl"
            >
              <InputLabel className="inputLable">
                Zip Code
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                type="text"
                name="zipCode"
                placeholder="Add Zip Code"
                value={state.zipCode}
                onChange={handleInputChange}
                error={!!state.errors.zipCode}
                helperText={state.errors.zipCode}
                sx={getSx(state.errors.zipCode)}
              />
            </Box>
          </Grid2>
        </Grid2>
      </Box>
      <Box className="formControl">
        <InputLabel className="inputLable">
          Message
          <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
        </InputLabel>
        <TextField
          placeholder="Add Message"
          minRows={1}
          maxRows={4}
          multiline
          name="message"
          value={state.message}
          onChange={handleInputChange}
          error={!!state.errors.message}
          helperText={state.errors.message}
          sx={getSx(state.errors.message)}
        />
      </Box>
      <Box sx={styles.submitButtonContainer}>
        {isLoading ? (
          <Button sx={styles.submitButton}>
            <CircularProgress size={40} />
          </Button>
        ) : (
          <Button
            disabled={state.disabled}
            sx={styles.submitButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Box>
    </>
  );
};

export default QuickBuyInquiryForm;
