import { Box, Grid2, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./style";
import dummyImage from "../../../../assets/Common/dummyImage.jpg";
import searchDivider from "../../../../assets/ExpertServiceProvider/search-divider.png";
import { useContext } from "react";
import { SearchContext } from "../../../../context/SearchContext";

interface ServiceProvider {
  _id: string;
  profileImage?: { url: string };
  fullName: string;
  businessTitle?: string;
}

const SearchResults = ({
  serviceProvider,
}: {
  serviceProvider: ServiceProvider;
}) => {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }
  const { filters } = searchContext;
  return (
    <Grid2
      size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}
      key={serviceProvider._id}
      sx={{
        paddingLeft: {
          md: "16px !important",
          xs: "0 !important",
        },
      }}
    >
      <Box sx={styles.serviceProviderBox}>
        <Box sx={styles.serviceProviderImageWrapper}>
          <Box
            sx={styles.serviceProviderImage}
            component="img"
            src={
              serviceProvider.profileImage?.url &&
              !serviceProvider.profileImage.url.endsWith(".pdf")
                ? serviceProvider.profileImage.url
                : dummyImage
            }
            alt="User Image"
          />
        </Box>
        <Typography
          variant="h4"
          title={serviceProvider.fullName}
          sx={styles.serviceProviderName}
        >
          {serviceProvider.fullName}
        </Typography>
        <Box sx={styles.serviceProviderTitleBox}>
          {serviceProvider.businessTitle && (
            <Typography
              variant="h4"
              sx={styles.serviceProviderTitle}
              title={serviceProvider.businessTitle}
            >
              {serviceProvider.businessTitle}
            </Typography>
          )}
        </Box>
        <Box
          component="img"
          src={searchDivider}
          alt="Divider Image"
          sx={styles.dividerImage}
        ></Box>
        <Link
          to={`/details-serviceproviders/${serviceProvider._id}?categoryId=${filters.selectedCategoryId}&zipCode=${filters.zipCode}`}
        >
          <Button sx={styles.viewDetailsButton}>View Details</Button>
        </Link>
      </Box>
    </Grid2>
  );
};

export default SearchResults;
