import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import { Box } from "@mui/material";
import { routePaths } from "../../../router/routePaths";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { getCategories } from "../../../network/services";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";
import useDebounce from "../../../hooks/useDebounce";
import styles from "../SelectServiceSubCategory/style";
import AddServiceComp, {
  Services,
} from "../../../components/AddServiceComp/AddServiceComp";
import { deriveCategoryIds } from "../../../utils/profileHelpers";

const EditServiceCategory = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [serviceIds, setServiceIds] = useState<string[]>([]);
  const [staticSelectedCategories, setStaticSelectedCategories] = useState<
    string[]
  >([]);
  const [services, setServices] = useState<Services[]>([]);
  const [searchCategoryText, setSearchCategoryText] = useState<string>("");
  const debouncedSearchCategoryText = useDebounce(searchCategoryText, 500);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.user.userData);
  const updatedSelectedItems = useSelector(
    (state: RootState) => state.user.userData?.categoryIds
  );

  useEffect(() => {
    setSelectedCategories(updatedSelectedItems || []);
  }, [updatedSelectedItems]);

  useEffect(() => {
    if (userData?.serviceIds) {
      setServiceIds(userData.serviceIds);
    }
  }, [userData]);

  useEffect(() => {
    setSelectedCategories(updatedSelectedItems || []);
    setStaticSelectedCategories(updatedSelectedItems || []);
  }, [userData]);

  useEffect(() => {
    if (serviceIds?.length > 0) fetchCategories(debouncedSearchCategoryText);
  }, [debouncedSearchCategoryText, serviceIds]);

  const fetchCategories = async (textArg: string) => {
    try {
      dispatch(setLoading(true));
      const payload = {
        serviceIds: serviceIds || [],
        keyword: searchCategoryText,
      };
      const res = await getCategories(payload);
      const categoryIds = deriveCategoryIds(
        res,
        serviceIds,
        staticSelectedCategories
      );
      if (!textArg) setStaticSelectedCategories(categoryIds);
      setServices(res?.data);
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const selectCategoryHandler = async () => {
    try {
      dispatch(setLoading(true));
      const payload = { categoryIds: staticSelectedCategories };
      dispatch(setUserData(payload));
      navigate(`${routePaths.editServiceSubCategory}?previous=true`);
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <Box
      className="selectServiceCategoryForm"
      sx={styles.selectSubServiceCategoryForm}
    >
      <ProfileWrapper
        title="Edit Service Category"
        btnDisabled={staticSelectedCategories.length === 0}
        onSubmit={selectCategoryHandler}
        hide={true}
        buttonText="Next"
        backHandler={() => navigate(`${routePaths.editBusiness}?previous=true`)}
      >
        <AddServiceComp
          searchCategoryText={searchCategoryText}
          selectedCategories={selectedCategories}
          staticSelectedCategories={staticSelectedCategories}
          services={services}
          setSelectedCategories={setSelectedCategories}
          setStaticSelectedCategories={setStaticSelectedCategories}
          setSearchCategoryText={setSearchCategoryText}
        />
      </ProfileWrapper>
    </Box>
  );
};

export default EditServiceCategory;
