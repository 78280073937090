import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import backIcon from "../../../assets/Common/backBlueIcon.svg";
import { getPrivacyPolicy } from "../../../network/common";
import styles from "./style";

const Content = () => {
  const navigate = useNavigate();

  const [data, setData] = useState("");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const getcontent = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const response = await getPrivacyPolicy();
      setData(response.data[0].description);
      setTitle(response.data[0].title);
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getcontent();
  }, [getcontent]);

  return (
    <Box sx={styles.mainBox} className="contentPage">
      <Container sx={styles.container}>
        <Box sx={styles.contentBox}>
          <Typography variant="h3" sx={styles.title}>
            Privacy Policy
          </Typography>
          <Box sx={styles.content}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </Box>
          <Box sx={styles.backButtonBox}>
            <Box onClick={() => navigate(-1)}>
              <Box
                component="img"
                src={backIcon}
                alt="backArrowIcon"
                sx={styles.backButtonImage}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Content;
