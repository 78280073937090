import { SxProps } from "@mui/material";

export const styles = {
  outerBox: (testimonialBgColor: string): SxProps => ({
    padding: {
      xs: "20px 0 20px",
      lg: "80px 10px 80px",
    },
    backgroundImage: `url(${testimonialBgColor})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
  }),
  container: {
    position: "relative",
    zIndex: "9",
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  titleTypography: {
    fontSize: {
      lg: "40px",
      xs: "28px",
    },
    color: "#0D0639",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "14px",
  },
  highlightedText: {
    color: "#D40500",
  },
  descriptionTypography: {
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#8989A3",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: {
      lg: "70px",
      xs: "30px",
    },
  },
  gridContainer: {
    width: {
      xl: "80%",
      lg: "90%",
      md: "100%",
    },
    margin: "auto !important",
  },
  gridItem: {
    marginBottom: {
      xs: "20px",
    },
  },
  flexibilityCardBox: {
    textAlign: "center",
    border: "1px solid #06038D0D",
    borderRadius: "12px",
    color: "#fff",
    padding: {
      lg: "20px 30px 30px 30px",
      xs: "14px",
    },
    backgroundColor: "#8D0603",
    boxShadow: "0px 7px 28px 0px #135C542E",
    marginTop: {
      lg: "60px",
    },
  },
  convenienceCardBox: {
    textAlign: "center",
    border: "1px solid #06038D0D",
    borderRadius: "12px",
    color: "#fff",
    padding: {
      lg: "20px",
      xs: "14px",
    },
    backgroundColor: "#06038D",
    boxShadow: "0px 7px 28px 0px #135C542E",
  },
  expertieseCardBox: {
    textAlign: "center",
    border: "1px solid #06038D0D",
    borderRadius: "12px",
    color: "#fff",
    padding: {
      lg: "20px",
      xs: "14px",
    },
    backgroundColor: "#1CB469",
    boxShadow: "0px 7px 28px 0px #135C542E",
    marginTop: {
      lg: "60px",
    },
  },
  cardIconContainer: (backgroundColor: string): SxProps => ({
    height: "85px",
    margin: "0 auto 20px",
    width: "85px",
    borderRadius: "50%",
    background: "#fff",
    border: "2px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor,
  }),
  cardTitle: {
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "700",
    textAlign: "center",
  },
  cardDivider: {
    height: "0.5px",
    background: "#ffffff63",
    width: "170px",
    margin: "10px auto 18px",
  },
  cardDescription: {
    fontSize: "16px",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    textAlign: "center",
  },
  floatingImage: {
    position: "absolute",
    top: "-80px",
    right: "-30px",
  },
};
