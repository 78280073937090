import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    height: "96vh",
    background: "#fff",
    width: {
      xl: "200px",
      md: "160px",
      xs: "130px",
    },
    padding: "25px 0 0",
    position: {
      sm: "relative",
      xs: "sticky",
    },
    top: {
      sm: "unset",
      xs: "0",
    },
    zIndex: "9",
  },
  logoBox: {
    textAlign: "center",
  },
  logoImage: {
    width: "80%",
  },
  menuBox: {
    padding: "20px",
    borderRadius: "70px",
    display: "flex",
    justifyContent: "center",
  },
  menuList: {
    height: {
      xs: "auto",
      sm: "400px",
    },
    borderRadius: "70px",
    padding: {
      xl: "20px 8px",
      xs: "10px 4px",
    },
    background: "#020123",
    position: {
      xl:"absolute",
    },
    // position:"absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  menuItem: {
    padding: {
      xl: "8px 16px",
      xs: "6px",
    },
  },
  menuItemBox: {
    background: "#FFFFFF30",
    height: {
      xl: "50px",
      xs: "40px",
    },
    width: {
      xl: "50px",
      xs: "40px",
    },
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuItemImage: {
    width: {
      xl: "50px",
      xs: "40px",
    },
  },
  logoutItem: {
    position: {
      sm: "absolute",
      xs: "relative",
    },
    bottom: "20px",
    margin: {
      sm: "0",
      xs: "20px 0 0 0",
    },
    padding: {
      xl: "8px 16px",
      xs: "6px",
    },
  },
  logoutBox: {
    background: "#FFFFFF30",
    height: {
      xl: "50px",
      xs: "40px",
    },
    cursor: "pointer",
    width: {
      xl: "50px",
      xs: "40px",
    },
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogPaper: {
    width: {
      xs: "90%",
      sm: "70%",
      md: "400px",
    },
    borderRadius: "40px",
    maxWidth: "90%",
  },
  dialogTitle: {
    m: 0,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "700",
  },
  dialogContent: {
    padding: {
      lg: "30px 90px 20px",
      xs: "0 0 20px 0",
    },
    "@media (orientation: landscape) and (max-width: 999px)": {
      overflowY: "unset",
    },
  },
  contentBox: {
    padding: "0 20px",
  },
  buttonContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  yesButton: {
    background: "#B3B3CF",
    padding: "13px 80px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    margin: {
      sm: "0 20px 0 0",
      xs: "0 0 20px 0",
    },
    width: {
      sm: "max-content",
      xs: "100%",
    },
  },
  noButton: {
    background: "#06038D",
    padding: "13px 80px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    width: {
      sm: "max-content",
      xs: "100%",
    },
  },
};

export default styles;
