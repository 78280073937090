import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import closeIcon from "../../../assets/Common/closeIcon.svg";

import TimeRangeSelector from "../../../components/CustomTimePicker/TimeRangeSelector";
import RepeatDays from "../../../components/CustomTimePicker/RepeatDays";
import BlueButton from "../../../components/Button/BlueButton";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { routePaths } from "../../../router/routePaths";
import { updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { showToast } from "../../../redux/slices/commonSlice";
import { daysOfWeek } from "../../../utils/common";
import { sortSlotsByStartTime } from "../../../utils/calendar";
import {
  AvailabilityFormProps,
  DayjsTimeSlot,
  TimeSlot,
} from "../../../utils/interfaces";
import styles from "./style";
import { getServiceTimingFromSlotsAndRepeatedDays } from "../../../utils/availability";

const AddAvailabilityModal: React.FC<AvailabilityFormProps> = ({
  handleClose,
  selectedDay,
  serviceTimingProp,
  getProfileDetails,
  selectedDate,
  from,
}) => {
  const [timeSlots, setTimeSlots] = useState<DayjsTimeSlot[]>([
    {
      startTime: null,
      endTime: null,
      monthDate: null,
      _id: undefined as string | undefined,
    },
  ]);
  const [removedSlots, setRemovedSlots] = useState<
    {
      startTime: Dayjs | null;
      endTime: Dayjs | null;
      monthDate?: Dayjs | null | string;
    }[]
  >([]);
  const [repeatDays, setRepeatDays] = useState<string[]>([]); // To track selected repeat days
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width:600px)"); // Adjust width for small screens
  const buttonWidth = isSmallScreen ? "80%" : "50%";
  useEffect(() => {
    setRepeatDays([selectedDay.toLowerCase()]);
  }, [selectedDay]);

  const toggleRepeatDay = (day: string) => {
    const dayInLower = day.toLowerCase();

    // Allow deselecting the day if it is already selected
    if (repeatDays.includes(dayInLower)) {
      if (day !== selectedDay)
        setRepeatDays((prevDays) => prevDays.filter((d) => d !== dayInLower));
      return;
    }

    // Check if the selected day already has slots in serviceTimingProp
    if (serviceTimingProp && serviceTimingProp[dayInLower]) {
      const existingSlots = serviceTimingProp[dayInLower].slots;

      // Convert existing slots to Dayjs format for comparison
      const existingDayjsSlots = convertTimeSlotsToDayjs(existingSlots);

      // Check if any existing slot conflicts with the current time slots
      const hasConflict = timeSlots.some((newSlot) => {
        return existingDayjsSlots.some((existingSlot) => {
          return (
            // Add a condition to prevent conflict if start and end times are exactly the same
            !(
              newSlot.startTime &&
              newSlot.startTime.isSame(existingSlot.startTime) &&
              newSlot.endTime &&
              newSlot.endTime.isSame(existingSlot.endTime)
            ) &&
            // Check for time overlap
            newSlot.startTime &&
            newSlot.endTime &&
            existingSlot.startTime.isBefore(newSlot.endTime) &&
            existingSlot.endTime.isAfter(newSlot.startTime) &&
            !existingSlot?.monthDate
          );
        });
      });

      if (hasConflict) {
        dispatch(
          showToast({
            color: "error",
            msg: `Conflicting time slots found for ${day}. Please choose different slots.`,
          })
        );
        return; // Prevent adding this day to repeatDays if there's a conflict
      }
    }

    // Otherwise, allow selecting the day
    setRepeatDays((prevDays) => [...prevDays, dayInLower]);
  };

  const convertTimeSlotsToDayjs = (slots: TimeSlot[]) => {
    return slots.map((slot) => ({
      startTime: dayjs(slot.startTime, "h:mm A"),
      endTime: dayjs(slot.endTime, "h:mm A"),
      monthDate: slot?.monthDate,
    }));
  };

  useEffect(() => {
    if (serviceTimingProp && selectedDay) {
      const dayPart = selectedDay.split(" ")[0].toLowerCase();
      if (selectedDay.length > 500) {
        throw new Error("Input is too long");
      }
      const dateMatch = selectedDay.match(/\((.*?)\)/);
      const selectedDate = dateMatch ? dateMatch[1] : null;
      const serviceDayKey = dayPart;

      if (serviceTimingProp[serviceDayKey]) {
        let daySlots = serviceTimingProp[serviceDayKey].slots;
        const hasSelectedDate: boolean = daySlots.some(
          (slot) => slot.monthDate === selectedDate
        );
        if (selectedDate && !hasSelectedDate) {
          daySlots = daySlots.map((slot) =>
            slot.monthDate ? slot : { ...slot, monthDate: selectedDate }
          );
        }

        // Check for slots that match the selectedDate
        const selectedDateSlots = daySlots.filter(
          (slot) => selectedDate && slot?.monthDate === selectedDate
        );

        const fallbackSlots = daySlots.filter((slot) => !slot?.monthDate);
        const hasNullStartTime = selectedDateSlots.some(
          (slot) => slot.startTime === null
        );
        // Use selectedDateSlots if available, otherwise use fallbackSlots
        let filteredSlots: TimeSlot[];
        if (selectedDateSlots.length > 0 && hasNullStartTime) {
          filteredSlots = [];
        } else if (selectedDateSlots.length > 0) {
          filteredSlots = selectedDateSlots;
        } else {
          filteredSlots = fallbackSlots;
        }

        // Convert filtered slots to the required format
        const convertedSlots =
          filteredSlots.length === 0
            ? [{ _id: undefined, startTime: null, endTime: null }] // Default empty slot if no slots available
            : convertTimeSlotsToDayjs(filteredSlots).map((slot, index) => ({
                _id: filteredSlots[index]._id, // Preserve the _id
                ...slot,
              }));
        const sortedTimeSlots: DayjsTimeSlot[] =
          sortSlotsByStartTime(convertedSlots);
        setTimeSlots(sortedTimeSlots); // Update the state with the converted slots
      }
    }
  }, [serviceTimingProp, selectedDay]);

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const sanitizedTimeSlots = timeSlots.map((slot) => ({
        ...slot,
        _id: slot._id || undefined,
      }));
      const payload = getServiceTimingFromSlotsAndRepeatedDays(
        dispatch,
        sanitizedTimeSlots,
        serviceTimingProp,
        repeatDays,
        removedSlots
      );
      await updateUserProfile({ serviceTiming: payload });
      dispatch(setUserData({ serviceTiming: payload }));
      handleClose();
      if (getProfileDetails) getProfileDetails();
      if (from) navigate(routePaths.operatingHours);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const disabled = timeSlots.some(
      (slot) => slot.startTime === null || slot.endTime === null
    );
    setIsDisabled(disabled);
  }, [timeSlots]);
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={styles.availabilityModalDialog}
      >
        <Box sx={styles.closeIconBox}>
          <img
            src={closeIcon}
            alt="close"
            style={{ height: "25px", width: "25px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        <DialogContent sx={styles.availabilityDialogContent}>
          <DialogTitle sx={styles.availabilityDialogTitle}>
            Add your Availability
          </DialogTitle>
          <Typography sx={styles.availabilitySelectedDay}>
            {selectedDay}
          </Typography>
          <Box sx={styles.justifyContentCenter}>
            <TimeRangeSelector
              timeSlots={timeSlots}
              setTimeSlots={setTimeSlots}
              removedSlots={removedSlots}
              setRemovedSlots={setRemovedSlots}
              selectedDate={selectedDate}
            />
          </Box>
          {daysOfWeek?.includes(selectedDay.toLowerCase()) ? (
            <>
              <Typography sx={styles.repeatsOnText}>Repeats On</Typography>
              <RepeatDays
                onDayToggle={toggleRepeatDay}
                selectedDays={repeatDays}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions sx={styles.availabilityDialogActions}>
          <BlueButton
            btnName=" Add Availability"
            onClick={handleSubmit}
            disabled={isDisabled}
            width={buttonWidth}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAvailabilityModal;
