import { ResponseServices } from "../components/AddServiceComp/AddServiceComp";
import {
  Category,
  ResponseCategories,
  SubCategory,
} from "../components/AddSubcatComp/AddSubcatComp";

//  if a service is deselected and all categories of those service remain selected
// to remove the categories of old services and match them with the currently selected selected services, we use this
export const deriveCategoryIds = (
  res: ResponseServices | undefined,
  serviceIds: string[],
  selectedCategories: string[]
): string[] => {
  const catIds: string[] = [];
  if (res?.data && Array.isArray(res.data)) {
    res.data.forEach((service) => {
      if (serviceIds.includes(service._id)) {
        service.categories.forEach((category) => {
          if (selectedCategories.includes(category._id)) {
            catIds.push(category._id);
          }
        });
      }
    });
  }
  return catIds;
};

// similar to above functionality, This is to seggregate for sub category
export const deriveSubCategoryIds = (
  res: ResponseCategories | undefined,
  categoryIds: string[],
  selectedSubCategories: string[]
): string[] => {
  const catIds: string[] = [];
  if (res?.data && Array.isArray(res.data)) {
    res.data.forEach((category: Category) => {
      if (categoryIds.includes(category?._id)) {
        category?.subcategories?.forEach((subcategory: SubCategory) => {
          if (selectedSubCategories.includes(subcategory._id)) {
            catIds.push(subcategory._id);
          }
        });
      }
    });
  }
  return catIds;
};
