import { Box, CircularProgress, Grid2, Typography } from "@mui/material";
import aboutUsBannerImage from "../../../../assets/Images/aboutUsBannerImage.jpg";
import quickBuyVectorImage from "../../../../assets/QuickBuy/quickBuyVectorImage.png";
import { useState } from "react";
import styles from "./style";

const AboutUsBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box sx={styles.mainBox}>
        <Grid2 sx={styles.gridContainer} container>
          <Grid2
            size={{ xs: 12, sm: 12, md: 6, lg: 5, xl: 4 }}
            sx={styles.textGridItem}
          >
            <Box sx={styles.textBox}>
              <Box sx={styles.titleBox}>
                <Typography variant="h3" sx={styles.title}>
                  About Us
                </Typography>
                <Box sx={styles.titleUnderline}></Box>
              </Box>
              <Typography variant="h3" sx={styles.mainHeading}>
                Welcome to{" "}
                <span style={{ color: "#06038D" }}>Home Seller Savings</span>
              </Typography>
              <Typography variant="h3" sx={styles.subHeading}>
                {`your premier destination for a hassle-free and flexible home
                selling experience. We understand that every homeowner's
                situation is unique, and that's why we offer a variety of
                options to suit your specific needs.`}
              </Typography>
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 12, md: 6, lg: 7, xl: 8 }}
            sx={styles.imageGridItem}
          >
            <Box sx={styles.imageBox}>
              <Box
                sx={styles.bannerImage}
                component="img"
                alt="Quick Banner Image"
                onLoad={() => setIsLoading(false)}
                src={aboutUsBannerImage}
              ></Box>
              {isLoading && <CircularProgress sx={styles.loadingIndicator} />}
            </Box>
            <Box
              component="img"
              src={quickBuyVectorImage}
              sx={styles.vectorImage}
            ></Box>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default AboutUsBanner;
