import { Link, Box, List, ListItem, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { routePaths } from "../../router/routePaths";

const DashboardFooter = () => {
  return (
    <>
      <Box
        sx={{
          padding: {
            xl: "10px 0",
            xs: "0 0 8px 0",
          },
          background: "#F5F5FA",
          position: {
            md:"unset",
            sm: "fixed",
            xs: "unset",
          },
          width: {
            md: "100%",
            xs: "auto",
          },
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: {
            lg: "0",
            md: "9",
          },
          "@media (orientation: landscape) and (max-width: 999px) and (max-width: 901px) ": {
            position: "fixed",
          },
          "@media (orientation: landscape) and (max-width: 900px)": {
            position: "unset",
          },
        }}
      >
        <List
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            padding: {
              xl: "8px 0",
              xs: "0",
            },
            flexDirection: {
              sm: "row",
              xs: "column",
            },
          }}
        >
          <ListItem
            sx={{
              width: "auto",
              padding: {
                sm: "10px 14px 10px 16px",
                md: "10px 14px 10px 16px",
                xs: "0 14px 0 6px",
              },
            }}
          >
            <ListItemText>
              <NavLink to={routePaths.termsAndConditions}>
                <Link
                  sx={{
                    fontSize: {
                      xl: "18px",
                      xs: "14px",
                    },
                    color: "#8989A3",
                    fontWeight: "400",
                    textDecoration: "none",
                    fontFamily: "Montserrat, sans-serif",
                    borderRight: {
                      sm: "2px solid #e2e2e6",
                      xs: "0",
                    },
                    paddingRight: "14px",
                  }}
                >
                  Terms and Conditions
                </Link>
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem
            sx={{
              width: "auto",
              padding: {
                sm: "10px 14px 10px 16px",
                md: "0 14px 0 16px",
                xs: "0 14px 0 6px",
              },
            }}
          >
            <ListItemText>
              <NavLink to={routePaths.privacyPolicy}>
                <Link
                  sx={{
                    fontSize: {
                      xl: "18px",
                      xs: "14px",
                    },
                    color: "#8989A3",
                    fontWeight: "400",
                    textDecoration: "none",
                    fontFamily: "Montserrat, sans-serif",
                    borderRight: {
                      sm: "2px solid #e2e2e6",
                      xs: "0",
                    },
                    paddingRight: "14px",
                  }}
                >
                  Privacy Policy
                </Link>
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem
            sx={{
              width: "auto",
              padding: {
                sm: "10px 14px 10px 16px",
                md: "0 14px 0 16px",
                xs: "0 14px 0 6px",
              },
            }}
          >
            <ListItemText>
              <NavLink to={routePaths.contact}>
                <Link
                  sx={{
                    fontSize: {
                      xl: "18px",
                      xs: "14px",
                    },
                    color: "#8989A3",
                    fontWeight: "400",
                    textDecoration: "none",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Contact Us
                </Link>
              </NavLink>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default DashboardFooter;
