export const routePaths = {
  signIn: "/sign-in",
  personalDetails: "/personal-details",
  uploadProfile: "/upload-profile",
  addBusiness: "/add-business",
  selectServiceCategory: "/select-service-category",
  selectServiceSubCategory: "/select-service-sub-category",
  addBusinessDetails: "/add-business-details",
  addBusinessImages: "/add-business-images",
  addOperatingHours: "/add-operating-hours",
  operatingHours: "/operating-hours",
  uploadDocuments: "/upload-documents",
  dashboard: "/dashboard",
  editBusiness: "/edit-business",
  editServiceCategory: "/edit-service-category",
  editServiceSubCategory: "/edit-service-sub-category",
  editBusinessImages: "/edit-business-images",
  settings: "/settings",
  editAddress: "/edit-address",
  editLicenseDocuments: "/edit-license-documents",
  serviceproviders: "/serviceproviders",
  aboutUs: "/about-us",
  availability: "/availability",
  detailsServiceproviders: "/details-serviceproviders/:id",
  quickBuy: "/quick-buy",
  default: "/",
  expertServiceProvider: "/expert-serviceprovider",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  contactUs: "/contact-us",
  contact: "/contact",
  tieredPlans: "/tiered-plans",
  quickBuyForm: "/quick-buy-form",
};
