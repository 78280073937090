import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import camera from "../../../assets/Icons/cameraIcon.svg";
import { routePaths } from "../../../router/routePaths";
import UploadImage from "../UploadImage/UploadImage";
import uploadBusiness from "../../../assets/Images/uploadBusiness.svg";
import { uploadImage } from "../../../network/common";
import { updateUserProfile } from "../../../network/user";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading, showToast } from "../../../redux/slices/commonSlice";
import { DayServiceTiming } from "../../../utils/interfaces";
import { RootState } from "../../../redux/rootReducer";
import { BusinessImage } from "../../../context/SearchContext";

const EditBusinessImages: React.FC = () => {
  const serviceTiming: DayServiceTiming = {};
  const [previews, setPreviews] = useState<string[]>([]);
  const [businessImagesPayload, setBusinessImagesPayload] = useState<
    BusinessImage[]
  >([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement | null>(null);
  const businessImages = useSelector(
    (state: RootState) => state.user.userData?.serviceImages
  );

  useEffect(() => {
    setBusinessImagesPayload(businessImages || []);
    setPreviews(businessImages?.map((item: BusinessImage) => item.url) || []);
  }, []);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      const acceptedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (previews.length + newFiles.length > 3) {
        dispatch(
          showToast({ color: "error", msg: "Maximum of 3 images can be added" })
        );
        return;
      }
      for (const file of newFiles) {
        if (!acceptedImageTypes.includes(file.type)) {
          dispatch(
            showToast({
              color: "error",
              msg: "Please upload images in these formats (jpeg, png, jpg)",
            })
          );
          return;
        }
      }
      const formDataList = newFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        return formData;
      });

      try {
        dispatch(setLoading(true));
        const uploadPromises = formDataList.map((formData) =>
          uploadImage(formData)
        );
        const responses = await Promise.all(uploadPromises);

        const urls: BusinessImage[] = responses.map((res) => ({
          url: res.data.fileUrl,
          key: res.data.key,
        }));

        setBusinessImagesPayload((prev) => [...prev, ...urls]);
        setPreviews((prev) => [...prev, ...urls.map((item) => item.url)]);
      } catch (error) {
        console.error("Error uploading images:", error);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleFileRef = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const addProfileHandler = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateUserProfile({
        businessImages: businessImagesPayload,
      });
      dispatch(setUserData({ businessImages: businessImagesPayload }));
      if (response) {
        navigate(routePaths.settings);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleClose = (index: number) => {
    setPreviews((prev) => prev.filter((_, i) => i !== index));
    setBusinessImagesPayload((prev) => prev.filter((_, i) => i !== index));
  };
  const skipHandler = () => {
    const hasDataInSlots = Object.values(serviceTiming).some(
      (day) => day.slots.length > 0
    );
    if (hasDataInSlots) navigate(routePaths.operatingHours);
    else navigate(routePaths.addOperatingHours);
  };

  return (
    <ProfileWrapper
      description="Note: Max 3 images can be added"
      title="Add Business Images"
      btnDisabled={previews.length === 0}
      onSubmit={addProfileHandler}
      stepperCount={6}
      businessImages={true}
      onSkip={skipHandler}
      hide={true}
    >
      <UploadImage
        handleFileChange={handleFileChange}
        fileRef={fileRef}
        previews={previews}
        camera={camera}
        handleFileRef={handleFileRef}
        uploadImage={uploadBusiness}
        multiple={true}
        handleClose={handleClose}
      />
    </ProfileWrapper>
  );
};

export default EditBusinessImages;
