import { Box, InputLabel } from "@mui/material";
import styles from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { NavLink } from "react-router-dom";
import { routePaths } from "../../../router/routePaths";
import { VisuallyHiddenInput } from "../../../components/VisuallyHiddenInput/VisuallyHiddenInput";
import editIcon from "../../../assets/Settings/editIcon.svg";
import fileIcon from "../../../assets/Settings/fileIcon.png";

interface EditLicenseProps {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
}

const EditLicense: React.FC<EditLicenseProps> = ({ handleInputChange }) => {
  const userProfile = useSelector((state: RootState) => state.user.userData);

  return (
    <Box className="formControl" sx={styles.formControl}>
      <InputLabel className="inputLable">License/Insurance</InputLabel>
      <Box sx={styles.licenseBox}>
        <Box sx={styles.licenseList}>
          {userProfile?.businessDocuments?.map(
            (document: { name: string; size: number }, index: number) => (
              <Box key={document.name} sx={styles.licenseItem}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    component="img"
                    src={fileIcon}
                    alt="fileIcon"
                    sx={styles.licenseIcon}
                  ></Box>
                  <Box sx={styles.licenseText}>
                    {document?.name}
                    <Box sx={styles.licenseSize}>
                      {`${Math.ceil(document?.size)} KB`}
                    </Box>
                  </Box>
                </Box>
                {index === 0 && (
                  <Box sx={styles.editIcon} component="label" tabIndex={-1}>
                    <NavLink to={routePaths.editLicenseDocuments}>
                      <Box src={editIcon} alt="Edit Icon" component="img" />
                    </NavLink>
                    <VisuallyHiddenInput
                      type="file"
                      name="businessDocuments"
                      onChange={(e) => handleInputChange(e)}
                    />
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditLicense;
