import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Link,
  Container,
  Grid2,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/ConsumerHeader/Header";
import GetHelp from "../../../module/LandingPage/ServiceProvider/GetHelp/GetHelp";
import SuccessForm from "../../../components/SucessForm";
import styles from "./style";
import backIcon from "../../../assets/Common/backBlueIcon.svg";
import breadCumsIcon from "../../../assets/Common/breadCumsIcon.svg";
import { routePaths } from "../../../router/routePaths";
import QuickBuyInquiryForm from "./QuickBuyInquiryForm";

const QuickBuyForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const role = "consumer";

  return (
    <>
      <Box sx={styles.background}>
        <Header />
        <Box sx={styles.relativePosition}>
          <Container maxWidth="xl">
            <Box sx={styles.flexContainer}>
              <Box
                onClick={() => navigate(-1)}
                sx={styles.cursorPointer}
                component="img"
                src={backIcon}
                alt="BackArrow"
              ></Box>
              <List sx={styles.listContainer}>
                <ListItem sx={styles.listItemPadding}>
                  <ListItemText sx={{ padding: "0" }}>
                    <NavLink to={routePaths.quickBuy}>
                      <Link sx={styles.linkStyle}>QuickBuy </Link>
                    </NavLink>
                  </ListItemText>
                </ListItem>
                <ListItem sx={styles.listItemPadding}>
                  <ListItemText sx={{ padding: "0", marginTop: "10px" }}>
                    <Box
                      className="breadCumsIcon"
                      src={breadCumsIcon}
                      alt="BreadCums"
                      component="img"
                    ></Box>
                  </ListItemText>
                </ListItem>
                <ListItem sx={styles.listItemPadding}>
                  <ListItemText sx={{ padding: "0" }}>
                    <NavLink to={routePaths.expertServiceProvider}>
                      <Link sx={styles.linkActiveStyle}>Inquiry Form</Link>
                    </NavLink>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          </Container>
          <Container maxWidth="md">
            <Box sx={styles.formContainer}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container>
                  <Grid2
                    size={{ xs: 12, lg: 8 }}
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Box>
                      {!submitted ? (
                        <QuickBuyInquiryForm setSubmitted={setSubmitted} />
                      ) : (
                        <SuccessForm />
                      )}
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Box>
          </Container>
        </Box>
        <GetHelp />
        <Footer role={role} />
      </Box>
    </>
  );
};

export default QuickBuyForm;
