import { useEffect, useState } from "react";
import ProfileWrapper from "../../../components/ProfileWrapper/ProfileWrapper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Typography } from "@mui/material";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import addIcon from "../../../assets/Icons/addIcon.svg";
import AddAvailabilityModal from "./AddAvailabilityModal";
import styles from "./style";

const AddOperatingHours = () => {
  const [openAddAvailabilityModal, setOpenAddAvailabilityModal] =
    useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>("");
  const dispatch = useDispatch();

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const initialValues = {
    fullName: "",
    phone: "",
    email: "",
  };

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .max(35, "Maximum username length should be 35.")
      .required("Username is required."),
    phone: Yup.string()
      .required("Password is required.")
      .min(10, "Phone number must be at least 10 digits."),
    email: Yup.string().required("Password is required."),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        if (formik.dirty) {
          const payload = {
            fullName: formik.values.fullName,
            phoneNumber: formik.values.phone.includes("+1")
              ? formik.values.phone
              : `+1${formik.values.phone}`,
          };
          await updateUserProfile(payload);
          dispatch(setUserData(payload));
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      formik.setValues({
        fullName: res.data.fullName || "",
        phone: res.data.phoneNumber || "",
        email: res.data.email || "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const openModalHandler = (day: string) => {
    setOpenAddAvailabilityModal(true);
    setSelectedDay(day);
  };
  const closeModalHandler = () => {
    setOpenAddAvailabilityModal(false);
    setSelectedDay("");
  };

  return (
    <Box className="addOperatingHours">
      <ProfileWrapper
        title="Add Operating Hours"
        btnDisabled={true}
        onSubmit={formik.handleSubmit}
        stepperCount={7}
      >
        <Box sx={styles.addOpExternalBox}>
          <Box sx={styles.addOpDaysBox}>
            {days.map((day) => (
              <Box key={day}>
                <Box>
                  <Typography sx={styles.addOpDayFont}>{day}</Typography>
                </Box>
                <Box sx={styles.addAvailabilityBox}>
                  <Typography sx={styles.addAvailabilityTextFont}>
                    Add Availability
                  </Typography>
                  <img
                    src={addIcon}
                    alt="add"
                    style={{ cursor: "pointer" }}
                    onClick={() => openModalHandler(day)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {openAddAvailabilityModal && (
          <AddAvailabilityModal
            handleClose={closeModalHandler}
            selectedDay={selectedDay}
            from="new"
          />
        )}
      </ProfileWrapper>
    </Box>
  );
};

export default AddOperatingHours;
