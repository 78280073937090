import { isToday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export function formatTimestamp(utcTimestamp: string):string {
  const nyTimeZone = "America/New_York";

  const formattedDate = formatInTimeZone(
    new Date(utcTimestamp),
    nyTimeZone,
    "MMM d h:mmaaa"
  );
  if (isToday(new Date(utcTimestamp))) {
    return `Today ${formatInTimeZone(new Date(utcTimestamp), nyTimeZone, "h:mmaaa")}`;
  }

  return formattedDate;
}
