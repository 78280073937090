import { SxProps, Theme } from "@mui/system";
import bannerBgImage from "../../assets/Images/bannerBgImage.png";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    background: "#06038D",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    padding: {
      xl: "90px 0 0 66px",
      md: "80px 0 0 30px",
      xs: "60px 16px 0 16px",
    },
    backgroundImage: `url(${bannerBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    position: "relative",
    marginTop: "-30px",
    backgroundSize: "contain",
  },
  gridContainer: {
    "@media (min-width: 1536px)": {
      maxWidth: "1536px",
      margin: "auto",
    },
    padding: {
      xl: "0 24px",
      lg: "0 40px",
    },
  },
  title: {
    fontSize: {
      lg: "46px",
      xs: "28px",
    },
    color: "#1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
  },
  borderImageBox: {
    padding: "14px 0",
  },
  forAsLowAs: {
    fontSize: {
      lg: "30px",
      xs: "22px",
    },
    color: "#1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    paddingBottom: "14px",
  },
  percentage: {
    fontSize: {
      lg: "120px",
      xs: "80px",
    },
    color: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "800",
  },
  description: {
    fontSize: {
      xl: "24px",
      lg: "20px",
      xs: "18px",
    },
    lineHeight: {
      xs: "30px",
      lg: "40px",
    },
    color: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    paddingBottom: "14px",
  },
  contactButton: {
    background: "#1CB469",
    color: "#fff",
    padding: {
      xs: "8px 20px",
      sm: "13px 30px",
    },
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    borderRadius: "12px",
    margin: {
      xs: "10px 0 30px 0",
      sm: "10px 0 0 0",
      lg: "40px 0 70px 0",
    },
  },
  imageGridItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  imageBox: {
    display: "flex",
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
  },
};

export default styles;
